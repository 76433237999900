import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatDialogRef } from '@angular/material/dialog';
import { YdocService } from '@app/editor/services/ydoc.service';
import { ArticleSection, basicArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { FlatNode, SectionNode } from './taxon-import-section-choose.model';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import * as XLSX from 'xlsx';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ParseTaxonsFromExcelService } from '@app/editor/services/taxon-treatments/parse-taxons-from-excel/parse-taxons-from-excel.service';
import { TaxonImportService } from '@app/editor/services/taxon-treatments/taxon-import/taxon-import.service';
import { TaxonImportStateService } from '@app/editor/services/taxon-treatments/taxon-import-state/taxon-import-state.service';
import { TaxonTreatment } from '@app/editor/services/taxon-treatments/taxon-treatment.models';

@Component({
  selector: 'app-taxon-import-section-choose',
  templateUrl: './taxon-import-section-choose.component.html',
  styleUrls: ['./taxon-import-section-choose.component.scss'],
})
export class TaxonImportSectionChooseComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') private fileInput!: ElementRef<HTMLInputElement>;
  selectedSectionId: string | null = null;
  selectedFileName: string | null = null;

  readonly isProcessing$ = this.stateService.isProcessing$;
  readonly totalTaxons$ = this.stateService.totalTaxons$;
  readonly processedTaxons$ = this.stateService.processedTaxons$;
  readonly progress$ = this.stateService.progress$;
  readonly isRendering$ = this.stateService.isRendering$;

  readonly treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  private taxonSectionId: number | null = null;
  private readonly destroy$ = new Subject<void>();
  private selectedFile: File | null = null;

  constructor(
    private readonly dialogRef: MatDialogRef<TaxonImportSectionChooseComponent>,
    private readonly taxonImportService: TaxonImportService,
    private readonly ydocService: YdocService,
    private readonly snackBar: SnackbarService,
    private readonly parseTaxonsFromExcelService: ParseTaxonsFromExcelService,
    private readonly stateService: TaxonImportStateService
  ) {}

  readonly _transformer = (node: SectionNode, level: number): FlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.id,
      level: level,
      canAddTaxon: node.canAddTaxon,
    };
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngOnInit(): void {
    this.getTaxonSectionFromTemplate();
    this.initSectionsTree();
    this.setupSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stateService.finishImport();
  }

  hasChild = (_: number, node: FlatNode): boolean => node.expandable;

  async selectSection(id: string): Promise<void> {
    this.selectedSectionId = this.selectedSectionId === id ? null : id;
  }

  onFileSelected(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (!files?.length) {
      return;
    }

    const file = files[0];
    if (!this.isValidExcelFile(file)) {
      this.invalidFileType();
      return;
    }

    this.selectedFileName = file.name;
    this.selectedFile = file;
  }

  removeSelectedFile(): void {
    this.selectedFileName = null;
    this.selectedFile = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  triggerImport(): void {
    if (!this.selectedFile) {
      this.noFileSelected();
      return;
    }

    if (!this.selectedSectionId) {
      this.noSectionSelected();
      return;
    }

    this.stateService.setProcessingState(true);
    this.readAndProcessFile(this.selectedFile);
  }

  noFileSelected(): void {
    this.snackBar.error('No file selected! Please select a valid file before importing.');
  }

  noSectionSelected(): void {
    this.snackBar.error('No section selected! Please select a section before importing.');
  }

  invalidFile(): void {
    this.snackBar.error('Invalid file! Please import file with correct data.');
    this.stateService.setProcessingState(false);
  }

  invalidFileType(): void {
    this.snackBar.error('Invalid file type! Please import a .xsl or .xslx file');
    this.stateService.setProcessingState(false);
  }

  isTaxonDataValid(data: Record<string, TaxonTreatment>): boolean {
    return Object.values(data).every(this.taxonImportService.isValidTaxonTreatment);
  }

  cancelImport(): void {
    this.dialogRef.close();
  }

  private setupSubscriptions(): void {
    this.stateService.importFinished$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
      this.snackBar.success('File imported successfully!');
    });
  }

  private initSectionsTree(): void {
    const articleSectionsStructure: basicArticleSection[] =
      this.ydocService.articleStructureMap.get('articleSectionsStructure');
    if (articleSectionsStructure) {
      const sectionNodes = articleSectionsStructure.map((section) =>
        this.buildSectionTree(section)
      );
      this.dataSource.data = sectionNodes;
    }
  }

  private readAndProcessFile(file: File): void {
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const fileData = e.target?.result as ArrayBuffer;
      const binaryString = this.parseTaxonsFromExcelService.parseExcel(fileData);
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const taxonTreatmentsData = this.parseTaxonsFromExcelService.convertWorkbookToJson(workbook);

      if (taxonTreatmentsData && this.isTaxonDataValid(taxonTreatmentsData)) {
        this.processTaxonData(taxonTreatmentsData);
      } else {
        this.invalidFile();
      }
    };

    fileReader.readAsArrayBuffer(file);
  }

  private processTaxonData(taxonTreatmentsData: Record<string, TaxonTreatment>): void {
    this.taxonImportService
      .retrieveSectionTemplate(this.selectedSectionId!)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (sectionTemplate) => {
        if (!sectionTemplate) {
          this.snackBar.error('Failed to retrieve section template');
          this.stateService.setProcessingState(false);
          return;
        }

        await this.taxonImportService.addTaxonTreatments(
          taxonTreatmentsData,
          this.selectedSectionId!,
          sectionTemplate
        );
      });
  }

  private isValidExcelFile(file: File): boolean {
    return (
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  }

  private buildSectionTree(section: basicArticleSection): SectionNode {
    const articleSection = this.ydocService.getSectionByID(section.sectionID);
    const node: SectionNode = {
      name: articleSection.title?.label || articleSection.title?.name || 'Untitled',
      id: section.sectionID,
      canAddTaxon: this.canAddTaxonToSection(articleSection),
      children: [],
    };

    if (section.children && section.children.length > 0) {
      node.children = section.children.map((child) => this.buildSectionTree(child));
    }

    return node;
  }

  private getTaxonSectionFromTemplate(): void {
    const articleSections = this.ydocService.articleData.layout.template.sections;
    const taxonTreatmentsSection = articleSections.find(
      (articleSection) => articleSection.name === '[MM] Taxon treatments'
    );
    const taxonSection = taxonTreatmentsSection.sections.find(
      (section) => section.name === 'Taxon'
    );
    this.taxonSectionId = taxonSection.id;
  }

  private canAddTaxonToSection(section: ArticleSection): boolean {
    const canAddTaxonToSection = section?.compatibility?.allow?.values.includes(
      this.taxonSectionId
    );
    return canAddTaxonToSection;
  }
}
