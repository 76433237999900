import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateInfoComponent } from './state-info/state-info.component';
import { StateInfoService } from './state-info-service/state-info.service';
import { ArticleRoleComponent } from './article-role/article-role.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ArticleModeService } from './article-mode-service/article-mode-service';

@NgModule({
  declarations: [StateInfoComponent, ArticleRoleComponent],
  imports: [CommonModule, MatTooltipModule, MatIconModule, MatButtonModule],
  providers: [StateInfoService, ArticleModeService],
  exports: [StateInfoComponent, ArticleRoleComponent],
})
export class StateInfoModule {}
