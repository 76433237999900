import { Injectable } from '@angular/core';
import { ValidationResult } from '../validation-section.models';
import { TrackChangesService } from '@app/editor/services/track-changes/track-changes.service';
import { CommentsService } from '@app/editor/utils/commentsService/comments.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentStateValidationService {
  constructor(
    private commentsService: CommentsService,
    private trackChangesService: TrackChangesService
  ) {}

  validateResolvedChanges(): ValidationResult[] {
    const results: ValidationResult[] = [];

    // Trigger changes calculation in all editors
    this.trackChangesService.getChangesInAllEditors();
    const unresolvedChanges = Object.values(this.trackChangesService.changesObj).filter(
      (change) =>
        (change.type === 'insertion' || change.type === 'deletion') && !change.changeAttrs.resolved
    );

    if (unresolvedChanges.length > 0) {
      results.push({
        fulfilled: false,
        errorMessage: `There ${
          unresolvedChanges.length === 1 ? 'is' : 'are'
        } ${unresolvedChanges.length} unresolved change${
          unresolvedChanges.length === 1 ? '' : 's'
        } that need${unresolvedChanges.length === 1 ? 's' : ''} to be addressed.`,
      });
    }

    return results;
  }

  validateResolvedComments(): ValidationResult[] {
    const results: ValidationResult[] = [];

    // Force comments recalculation
    this.commentsService.buildGlobalCommentMap();

    // Get all comments
    const { commentsObj } = this.commentsService;

    // Find unresolved comments (comments that are not resolved themselves or have unresolved thread comments)
    const unresolvedComments = Object.values(commentsObj).filter(
      (comment) => !this.commentsService.areAllResolved(comment)
    );

    if (unresolvedComments.length > 0) {
      results.push({
        fulfilled: false,
        errorMessage: `There ${unresolvedComments.length === 1 ? 'is' : 'are'} ${
          unresolvedComments.length
        } unresolved comment${unresolvedComments.length === 1 ? '' : 's'} that need${
          unresolvedComments.length === 1 ? 's' : ''
        } to be addressed.`,
      });
    }

    return results;
  }
}
