import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

import { TrackChangesService } from '../services/track-changes/track-changes.service';
import { ChangeData } from './change.models';
import { ChangesRenderingService } from './changes-rendering/changes-rendering.service';
import { ChangesNavigationService } from './changes-navigation/changes-navigation.service';
import { SharedChangesService } from './shared-changes/shared-changes.service';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-changes-section',
  templateUrl: './changes-section.component.html',
  styleUrls: ['./changes-section.component.scss'],
  providers: [SharedChangesService, ChangesRenderingService, ChangesNavigationService],
})
export class ChangesSectionComponent implements AfterViewInit, OnDestroy {
  private readonly spaceElementMinHeight = 500;
  private readonly calculationPadding = 150;

  constructor(
    private changesService: TrackChangesService,
    private ref: ChangeDetectorRef,
    private sharedChangesService: SharedChangesService
  ) {
    this.changesRenderingService.subscribeForChanges(this.ref);
  }

  get changesRenderingService(): ChangesRenderingService {
    return this.sharedChangesService.changesRenderingService;
  }

  get changesNavigationService(): ChangesNavigationService {
    return this.sharedChangesService.changesNavigationService;
  }

  get searchIndex(): number {
    return this.changesNavigationService.searchIndex;
  }

  get searching(): boolean {
    return this.changesNavigationService.searching;
  }

  get selectedNumber(): number {
    const selectedNumber = this.changesNavigationService.isSelected
      ? this.changesNavigationService.searchIndex + 1
      : this.changesNavigationService.searchIndex;

    return this.changesNavigationService.searchResults.length
      ? selectedNumber
      : this.changesNavigationService.searchResults.length;
  }

  get canShowArrowDown(): boolean {
    return (
      this.changesNavigationService.searchResults &&
      this.changesNavigationService.searchResults.length &&
      this.changesNavigationService.searchResults.length - 1 == this.searchIndex
    );
  }

  get allChanges(): ChangeData[] {
    return this.sharedChangesService.allChanges;
  }

  get searchResults(): ChangeData[] {
    return this.changesNavigationService.searchResults;
  }

  get doneRenderingChangesSubject$(): Subject<string> {
    return this.changesRenderingService.doneRenderingChangesSubject$;
  }

  get searchForm(): UntypedFormControl {
    return this.changesNavigationService.searchForm;
  }

  ngAfterViewInit(): void {
    this.changesRenderingService.initialRender = true;
    this.changesRenderingService.setContainerHeight();
    this.setScrollListener();
    this.changesNavigationService.setFormControlChangeListener(this.ref);
    this.changesRenderingService.initialRendering();
    this.changesService.getChangesInAllEditors();
  }

  endSearch(): void {
    this.changesNavigationService.endSearch();
  }
  selectPreviousChangeFromSearch(): void {
    this.changesNavigationService.selectPreviousChangeFromSearch();
  }
  selectNextChangeFromSearch(): void {
    this.changesNavigationService.selectNextChangeFromSearch();
  }
  selectChange(change: ChangeData): void {
    this.changesNavigationService.selectChange(change);
  }

  setScrollListener(): void {
    const container = document.getElementsByClassName('changes-wrapper')[0] as HTMLDivElement;
    const articleElement = document.getElementsByClassName('editor-container')[0] as HTMLDivElement;
    const editorsElement = document.getElementById('app-article-element') as HTMLDivElement;
    const changesContainer = document.getElementsByClassName(
      'all-changes-container'
    )[0] as HTMLElement;
    const spaceElement = document.getElementsByClassName('end-article-spase')[0] as HTMLDivElement;
    (document.getElementsByClassName('end-article-spase')[0] as HTMLDivElement).style.minHeight =
      `${this.spaceElementMinHeight}px`;
    articleElement.addEventListener('scroll', () => {
      if (
        this.changesRenderingService.lastSorted &&
        this.changesRenderingService.lastSorted.length > 0
      ) {
        const lastElement =
          this.changesRenderingService.lastSorted[
            this.changesRenderingService.lastSorted.length - 1
          ];
        const displayedPos =
          this.changesRenderingService.displayedChangesPositions[lastElement.changeAttrs.id];
        const elBottom = displayedPos?.displayedTop + displayedPos?.height;
        const containerH = changesContainer?.getBoundingClientRect().height;
        if (containerH < elBottom) {
          changesContainer.style.height = elBottom + this.calculationPadding + 'px';
        }
        const editorH = editorsElement.getBoundingClientRect().height;
        const spaceElementH = spaceElement.getBoundingClientRect().height;
        const actualEditorH = editorH - spaceElementH;
        if (editorH < elBottom) {
          spaceElement.style.height = elBottom + this.calculationPadding - actualEditorH + 'px';
        } else if (editorH > elBottom + 100 && spaceElementH > 0) {
          const space =
            elBottom + this.calculationPadding - actualEditorH < 0
              ? 0
              : elBottom + this.calculationPadding - actualEditorH;
          spaceElement.style.height = space + 'px';
        }
      }
      container.scrollTop = articleElement.scrollTop;
    });
    container.scrollTop = articleElement.scrollTop;
  }

  changeParentContainer(event: boolean, commentContainer: HTMLDivElement): void {
    if (event && !commentContainer.classList.contains('selected-change')) {
      commentContainer.classList.add('selected-change');
      this.ref.detectChanges();
    } else if (!event && commentContainer.classList.contains('selected-change')) {
      commentContainer.classList.remove('selected-change');
      this.ref.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.sharedChangesService.destroy();
    (document.getElementsByClassName('editor-container')[0] as HTMLDivElement).removeAllListeners(
      'scroll'
    );
    (document.getElementsByClassName('changes-wrapper')[0] as HTMLDivElement).removeAllListeners(
      'wheel'
    );
  }
}
