import { Injectable } from '@angular/core';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { Plugin, PluginKey, EditorState, EditorStateConfig } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DetectFocusService {
  public focusedEditor: Observable<string>;
  public detectFocusPlugin: Plugin;
  public detectFocusPluginKey: PluginKey;
  public sectionName: string | undefined;
  public sectionType?: string;

  private focusedEditorSubject = new Subject<string>();
  private shouldCreateSelectionDecoration = false;

  constructor(private serviceShare: ServiceShare) {
    this.initializeService();
  }

  public getPlugin(): Plugin {
    return this.detectFocusPlugin;
  }

  public getSubject(): Observable<string> {
    return this.focusedEditor;
  }

  public resetDetectFocusService(): void {
    this.sectionName = undefined;
    this.sectionType = undefined;
  }

  public setSelectionDecorationOnLastSelectedEditor(): void {
    if (!this.sectionName) return;
    this.shouldCreateSelectionDecoration = true;
    setTimeout(() => {
      this.serviceShare.ProsemirrorEditorsService.dispatchEmptyTransaction();
    }, 10);
  }

  public removeSelectionDecorationOnLastSelectedEditor(): void {
    this.shouldCreateSelectionDecoration = false;
  }

  private initializeService(): void {
    this.serviceShare.shareSelf('DetectFocusService', this);
    this.focusedEditor = this.focusedEditorSubject.asObservable();
    this.detectFocusPluginKey = new PluginKey('detectFocusPluginKey');
    this.initializePlugin();
  }

  private getLastFocus(): string | undefined {
    return this.sectionName;
  }

  private setLastEditorFocus(id: string): void {
    this.sectionName = id;
  }

  private initializePlugin(): void {
    this.detectFocusPlugin = new Plugin({
      key: this.detectFocusPluginKey,
      state: {
        init: (config: { sectionName: string } & EditorStateConfig) => {
          return { sectionName: config.sectionName, hasFocus: false };
        },
        apply: (tr, prev, oldState) => {
          const pluginMeta = this.detectFocusPluginKey.getState(oldState);
          if (pluginMeta) {
            const focusRN = pluginMeta.focusRN;
            prev.hasFocus = focusRN;
          }
          return prev;
        },
      },
      props: {
        handleDOMEvents: {
          focus: (view: EditorView) => {
            const { sectionName } = this.detectFocusPluginKey.getState(view.state);
            this.setLastEditorFocus(sectionName);
            return true;
          },
        },
        decorations: (state: EditorState) => {
          if (!this.shouldCreateSelectionDecoration) {
            return DecorationSet.empty;
          }

          const { sectionName } = this.detectFocusPluginKey.getState(state);
          if (sectionName === this.sectionName) {
            const { from, to } = state.selection;
            const decoration = Decoration.inline(from, to, {
              decoration: 'selection-decoration-placeholder',
            });
            return DecorationSet.create(state.doc, [decoration]);
          }

          return DecorationSet.empty;
        },
      },
      view: () => ({
        update: (view: EditorView) => {
          const { sectionName } = this.detectFocusPluginKey.getState(view.state);
          const focusRN = view.hasFocus();

          if (focusRN) {
            this.removeSelectionDecorationOnLastSelectedEditor();
          }

          if (focusRN && this.getLastFocus() !== sectionName) {
            setTimeout(() => {
              this.focusedEditorSubject.next(sectionName);
            }, 10);
            this.setLastEditorFocus(sectionName);
          }
        },
      }),
    });
  }
}
