import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Map } from 'yjs';

import { ArticleSection, basicArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { YdocService } from '@app/editor/services/ydoc.service';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { DetectFocusService } from '@app/editor/utils/detectFocusPlugin/detect-focus.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { ImportWizardDialogData, SectionContent } from '../../jats.models';

@Component({
  selector: 'app-preview-editor',
  templateUrl: './preview-editor.component.html',
  styleUrls: ['./preview-editor.component.scss'],
})
export class PreviewEditorComponent implements OnInit {
  @Input() data: ImportWizardDialogData;
  @Input() shouldPreserve: boolean;

  @ViewChild('headEditor', { read: ElementRef }) headEditor?: ElementRef;

  moveHeadEditor = false;
  articleSectionsStructureFlat: SectionContent[];
  articleSectionsStructure: SectionContent[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  constructor(
    private ydocService: YdocService,
    public prosemirrorEditorsService: ProsemirrorEditorsService,
    public detectFocusService: DetectFocusService,
    public serviceShare: ServiceShare
  ) {}

  ngOnInit(): void {
    debugger;
    this.articleSectionsStructure = this.data.sectionsContent;
    this.makeFlat();
  }

  hasArticleTitle(sectionID: string): boolean {
    return this.getSection(sectionID)?.jats_tag == 'article-title' && this.moveHeadEditor;
  }

  getSection = (id: string): ArticleSection => this.ydocService.getSectionByID(id);

  makeFlat = (): SectionContent[] => {
    const articleSectionsStructureFlat: SectionContent[] = [];

    const makeFlat = (structure: SectionContent[]): void => {
      if (structure) {
        structure.forEach((section) => {
          articleSectionsStructureFlat.push(section);
          if (section.subsections?.length > 0) {
            makeFlat(section.subsections);
          }
        });
      }
    };
    makeFlat(this.articleSectionsStructure);

    this.articleSectionsStructureFlat = [];
    setTimeout(() => {
      if (this.headEditor) {
        this.moveHeadEditor = articleSectionsStructureFlat.some((sec: SectionContent) => {
          const section = this.getSection(sec.sectionID);
          return section.jats_tag && section.jats_tag == 'article-title';
        });
      }
      this.articleSectionsStructureFlat = articleSectionsStructureFlat;
    }, 10);
    return articleSectionsStructureFlat;
  };

  trackSectionById(index: number, section: SectionContent): string {
    return section.sectionID;
  }
}
