import {
  CharacterCountRule,
  ParsedExpressions,
  SectionMinMaxRule,
  SectionPositionRule,
  ValidationRule,
} from '../validation-section.models';

export class ValidationRuleUtils {
  static parseExpressions(expressionsString: string): ParsedExpressions {
    try {
      return JSON.parse(expressionsString) as ParsedExpressions;
    } catch (e) {
      console.error('Error parsing expressions:', e);
      return [];
    }
  }

  static isCharacterCountRule(rule: ValidationRule): rule is CharacterCountRule {
    return rule.rule === 'ToBeBetweenMinMax';
  }

  static isSectionMinMaxRule(rule: ValidationRule): rule is SectionMinMaxRule {
    return rule.rule === 'ToHaveMinMaxEqualSections';
  }

  static isSectionPositionRule(rule: ValidationRule): rule is SectionPositionRule {
    return rule.rule === 'EqualSectionPositions';
  }

  static getSectionNames(rule: SectionMinMaxRule | SectionPositionRule): string[] {
    return rule.config.names
      .split('|')
      .map((name) => name.trim())
      .filter(Boolean);
  }

  static validateMinMax(value: number, min: number, max: number): boolean {
    return value >= min && value <= max;
  }
}
