import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, withLatestFrom, tap } from 'rxjs/operators';
import * as EditModeActions from './edit-mode.actions';
import * as EditModeSelectors from './edit-mode.selectors';
import { EditMode } from '@app/editor/services/edit-mode/models';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { DetectFocusService } from '@app/editor/utils/detectFocusPlugin/detect-focus.service';
import { TrackChangesService } from '@app/editor/services/track-changes/track-changes.service';

@Injectable()
export class EditModeEffects {
  initializeEditMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditModeActions.initializeEditMode),
      withLatestFrom(this.store.select(EditModeSelectors.selectEditModePermissions)),
      mergeMap(([{ metadata, userSettings }, permissions]) => {
        const actions = [];

        if (metadata?.permissions) {
          actions.push(EditModeActions.updatePermissions({ permissions: metadata.permissions }));
        }

        // If we have version data, always go to preview mode
        if (metadata?.hasVersionData) {
          actions.push(EditModeActions.setEditMode({ mode: EditMode.preview }));
        } else if (!permissions.canEdit && !permissions.canSuggest) {
          actions.push(EditModeActions.setEditMode({ mode: EditMode.preview }));
        } else if (
          metadata?.trackTransactions &&
          metadata.lastUpdateFromUser !== metadata.currentUserGuid &&
          permissions.canSuggest
        ) {
          actions.push(EditModeActions.setEditMode({ mode: EditMode.suggest }));
        } else if (userSettings?.editMode === 'suggest' && permissions.canSuggest) {
          actions.push(EditModeActions.setEditMode({ mode: EditMode.suggest }));
        } else if (permissions.canEdit) {
          actions.push(EditModeActions.setEditMode({ mode: EditMode.edit }));
        } else if (permissions.canSuggest) {
          actions.push(EditModeActions.setEditMode({ mode: EditMode.suggest }));
        } else {
          actions.push(EditModeActions.setEditMode({ mode: EditMode.preview }));
        }

        return actions;
      })
    )
  );

  validateCurrentMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditModeActions.updatePermissions),
      withLatestFrom(
        this.store.select(EditModeSelectors.selectCurrentMode),
        this.store.select(EditModeSelectors.selectEditModePermissions)
      ),
      map(([{ permissions }, currentMode]) => {
        if (!permissions.canEdit && !permissions.canSuggest) {
          return EditModeActions.setEditMode({ mode: EditMode.preview });
        }

        if (currentMode === EditMode.edit && !permissions.canEdit) {
          return EditModeActions.setEditMode({
            mode: permissions.canSuggest ? EditMode.suggest : EditMode.preview,
          });
        }

        if (currentMode === EditMode.suggest && !permissions.canSuggest) {
          return EditModeActions.setEditMode({
            mode: permissions.canEdit ? EditMode.edit : EditMode.preview,
          });
        }

        return { type: '[Edit Mode] No Mode Change Required' };
      })
    )
  );

  // Added: Handle mode changes and side effects
  handleModeChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditModeActions.setEditMode),
        tap(({ mode }) => {
          // Update preview mode
          this.prosemirrorEditorsService.previewArticleMode.mode = mode === EditMode.preview;

          // Update track changes state
          if (mode === EditMode.suggest) {
            this.trackChangesService.changeInEditors();
          }

          // Update editor focus
          this.detectFocusService.setSelectionDecorationOnLastSelectedEditor();

          // Update metadata
          const metadata = this.ydocService.trackChangesMetadata?.get('trackChangesMetadata');
          if (metadata) {
            const newMetadata = {
              ...metadata,
              trackTransactions: mode === EditMode.suggest,
              lastUpdateFromUser: this.ydocService.ydoc?.guid,
            };
            this.ydocService.trackChangesMetadata?.set('trackChangesMetadata', newMetadata);
            this.prosemirrorEditorsService.OnOffTrackingChangesShowTrackingSubject.next(
              newMetadata
            );
          }
        })
      ),
    { dispatch: false }
  );

  // Added: Handle metadata observation
  handleMetadataObservation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditModeActions.initializeEditMode),
        tap(() => {
          if (this.ydocService.trackChangesMetadata) {
            this.ydocService.trackChangesMetadata.observe(() => {
              const metadata = this.ydocService.trackChangesMetadata?.get('trackChangesMetadata');
              if (
                metadata?.trackTransactions &&
                metadata.lastUpdateFromUser !== this.ydocService.ydoc?.guid
              ) {
                this.store.dispatch(EditModeActions.setEditMode({ mode: EditMode.suggest }));
              }
            });
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly prosemirrorEditorsService: ProsemirrorEditorsService,
    private readonly ydocService: YdocService,
    private readonly detectFocusService: DetectFocusService,
    private readonly trackChangesService: TrackChangesService
  ) {}
}
