import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { TextSelection } from 'prosemirror-state';
import cloneDeep from 'lodash/cloneDeep';

import { TrackChangesService } from '@app/editor/services/track-changes/track-changes.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { AuthService } from '@core/services/auth.service';
import { acceptChange, rejectChange } from '../../utils/trackChanges/acceptReject';
import { DateService } from '@app/core/services/date-service/date.service';
import { Colors, ChangeData, ChangeAttrs, TrackChangesMarkNames } from '../change.models';
import { roleMapping } from '@app/core/services/all-users.service';
import { endNote } from '@app/editor/utils/interfaces/endNotes';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss'],
})
export class ChangeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() change: ChangeData;
  @Input() doneRenderingChangesSubject?: Subject<string>;

  @Output() doneRenderingChangesSubjectChange = new EventEmitter<Subject<string>>();
  @Output() selected = new EventEmitter<boolean>();

  initialShowMore = false;
  previewMode: boolean;
  isCurrentUserChange: boolean;
  currentUserColor = '#00B1B2';
  deletionColor = '#ffd0d0';

  private readonly renderDelay = 10;

  private subscription$ = new Subscription();

  constructor(
    public changesService: TrackChangesService,
    public serviceShare: ServiceShare,
    public ydocService: YdocService,
    private readonly authService: AuthService,
    private dateService: DateService
  ) {
    this.previewMode = serviceShare.ProsemirrorEditorsService!.previewArticleMode.mode;
  }

  get changeType(): string {
    const insertedChangeType =
      this.change.type == TrackChangesMarkNames.formatChange ? 'Formated:' : 'Inserted:';

    return this.change.type == TrackChangesMarkNames.deletion ? 'Deleted:' : insertedChangeType;
  }

  get textColor(): string {
    const userColor = this.isCurrentUserChange
      ? 'white'
      : this.change.changeAttrs.userContrastColor;
    return this.change.type == TrackChangesMarkNames.deletion ? 'black' : userColor;
  }

  get backgroundTextColor(): string {
    if (this.change.type == TrackChangesMarkNames.deletion) {
      return this.deletionColor;
    } else {
      return this.isCurrentUserChange ? this.currentUserColor : this.change.changeAttrs.userColor;
    }
  }

  get canAcceptAndReject(): boolean {
    return (
      this.ydocService.curUserAccess &&
      this.ydocService.curUserAccess != roleMapping.reader &&
      this.ydocService.curUserAccess != roleMapping.author_commenter &&
      this.serviceShare.canUseTrackChanges &&
      !this.previewMode
    );
  }

  get userColor(): string {
    return this.isCurrentUserChange ? 'white' : this.change.changeAttrs.userContrastColor;
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.doneRenderingChangesSubject.next('rendered');
      if (this.change.changeMarkId == this.changesService.lastChangeSelected.changeMarkId) {
        this.selected.emit(true);
      }
    }, this.renderDelay);
  }

  getUserColor(type: keyof Colors): string {
    if (type == 'background') {
      return this.isCurrentUserChange ? this.currentUserColor : this.change.changeAttrs.userColor;
    } else {
      return this.isCurrentUserChange ? 'white' : this.change.changeAttrs.userContrastColor;
    }
  }

  getDate(timeStamp: number): string {
    return this.dateService.getDate(timeStamp);
  }

  selectChange(): void {
    const view =
      this.serviceShare.ProsemirrorEditorsService.editorContainers[this.change.section].editorView;

    const actualChange = Object.values(this.serviceShare.TrackChangesService.changesObj).find(
      (change) => change && change.changeMarkId === this.change.changeMarkId
    );

    if (actualChange) {
      view.focus();
      view.dispatch(
        view.state.tr
          .setSelection(
            new TextSelection(
              view.state.doc.resolve(actualChange.pmDocStartPos),
              view.state.doc.resolve(actualChange.pmDocStartPos)
            )
          )
          .setMeta('selected-comment', true)
          .scrollIntoView()
      );
      this.serviceShare.ProsemirrorEditorsService.dispatchEmptyTransaction();
    }
  }

  acceptChange(type: string, attrs: ChangeAttrs): void {
    const view =
      this.serviceShare.ProsemirrorEditorsService.editorContainers[this.change.section].editorView;

    const { endNote, endNotes } = this.findCitableElement(attrs);

    if (endNote) {
      delete endNotes[endNote.end_note_ID].trackChangeId;
      this.ydocService.endNotesMap.set('endNotes', endNotes);
    }

    acceptChange(view, type, attrs);

    this.updateAndSyncChanges();
  }

  declineChange(event: Event, type: string, attrs: ChangeAttrs): void {
    const view =
      this.serviceShare.ProsemirrorEditorsService.editorContainers[this.change.section].editorView;

    const { endNote, endNotes } = this.findCitableElement(attrs);

    if (endNote) {
      event.stopPropagation();

      const endNotesNumbers = cloneDeep(
        this.ydocService.endNotesMap.get('endNotesNumbers')
      ) as string[];
      delete endNotes[endNote.end_note_ID];

      this.serviceShare.CitableElementsService.writeElementDataGlobal(
        endNotes,
        endNotesNumbers.filter((id) => id != endNote.end_note_ID),
        'end_note_citation'
      );
    } else {
      rejectChange(view, type, attrs);
    }

    this.updateAndSyncChanges();
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  private initializeSubscriptions(): void {
    this.subscription$.add(
      this.authService.currentUser$.pipe().subscribe((userInfo) => {
        this.isCurrentUserChange = this.change.changeAttrs.user == userInfo.id;
      })
    );

    this.subscription$.add(
      this.changesService.lastSelectedChange$.subscribe((change) => {
        if (
          this.ydocService.curUserAccess &&
          this.ydocService.curUserAccess == roleMapping.reader
        ) {
          return;
        }
        if (this.change.changeMarkId == change.changeMarkId) {
          this.selected.emit(true);
        } else {
          this.selected.emit(false);
        }
      })
    );
  }

  private findCitableElement(attrs: ChangeAttrs): {
    endNote: endNote;
    endNotes: { [key: string]: endNote };
  } {
    const changeId = attrs.id;
    const endNotes = cloneDeep(this.ydocService.endNotesMap.get('endNotes'));
    const endNote = Object.values(endNotes).find(
      (note: endNote) => note.trackChangeId == changeId
    ) as endNote;

    return { endNote, endNotes };
  }

  private updateAndSyncChanges(): void {
    this.serviceShare.YdocService.ydoc.getMap('change').set('change', 'change');
    this.changesService.getChangesInAllEditors();
  }
}
