import { Injectable } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValidationConditions, ValidationRule } from '../validation-section.models';
import { YdocService } from '@app/editor/services/ydoc.service';
import { StateInfoService } from '@app/editor/state-info/state-info-service/state-info.service';
import { PJSData } from '@app/core/models/pjs.models';

@Injectable()
export class ValidationRulesService {
  constructor(
    private ydocService: YdocService,
    private stateInfoService: StateInfoService
  ) {}

  /**
   * Retrieves all validation rules by combining:
   * 1. Default rules
   * 2. Template rules from articleData
   * 3. Dynamic rules based on (PJS) conditions
   */
  getRules(): Observable<ValidationRule[]> {
    return combineLatest([
      this.getDefaultRules(),
      this.getTemplateRules(),
      this.getConditionalRules(),
    ]).pipe(
      map(([defaultRules, templateRules, conditionalRules]) => [
        ...defaultRules,
        ...templateRules,
        ...conditionalRules,
      ])
    );
  }

  private getDefaultRules(): Observable<ValidationRule[]> {
    const defaultRules: ValidationRule[] = [
      { rule: 'FormControls' },
      { rule: 'ValidateCitedItems' },
      { rule: 'ValidateComplexSections' },
    ];

    return of(defaultRules);
  }

  private getTemplateRules(): Observable<ValidationRule[]> {
    try {
      const templateRules = JSON.parse(
        JSON.stringify(this.ydocService.articleData.layout.template.rules)
      );
      return of(templateRules);
    } catch (error) {
      console.error('Error parsing template rules:', error);
      return of([]);
    }
  }

  private getConditionalRules(): Observable<ValidationRule[]> {
    return this.getValidationConditions().pipe(
      map((conditions) => {
        const conditionalRules: ValidationRule[] = [];

        if (conditions.validateResolvedChanges) {
          conditionalRules.push({ rule: 'ValidateResolvedChanges' });
        }

        if (conditions.validateResolvedComments) {
          conditionalRules.push({ rule: 'ValidateResolvedComments' });
        }

        return conditionalRules;
      })
    );
  }

  /**
   * Gets validation conditions based on the article state
   * Returns true for resolved changes and comments validation
   * when state is Waiting author version after review round (9)
   * or Waiting author to proceed to copy editing (14)
   * later this method should retrieve the rules from PJS
   */
  private getValidationConditions(): Observable<ValidationConditions> {
    return this.stateInfoService.getDocumentState().pipe(
      map((state: PJSData | null) => {
        if (!state) {
          return {
            validateResolvedChanges: false,
            validateResolvedComments: false,
          };
        }
        //  9 - Waiting author version after review round
        // 14 - Waiting author to proceed to copy editing
        const statesToValidate = [9, 14];

        const requiresValidation = statesToValidate.includes(state.state_id);

        return {
          validateResolvedChanges: requiresValidation,
          validateResolvedComments: requiresValidation,
        };
      })
    );
  }
}
