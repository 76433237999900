import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ArticleSectionsService } from '@app/core/services/article-sections.service';
import { ArticlesService } from '@app/core/services/articles.service';
import { CslService } from '@app/layout/pages/library/lib-service/csl.service';
import { EditorsRefsManagerService } from '@app/layout/pages/library/lib-service/editors-refs-manager.service';
import { uuidv4 } from 'lib0/random';
import { ChooseManuscriptDialogComponent } from '../dialogs/choose-manuscript-dialog/choose-manuscript-dialog.component';
import { TreeService } from '../meta-data-tree/tree-service/tree.service';
import { renderSectionFunc } from '../utils/articleBasicStructure';
import { CommentsService } from '../utils/commentsService/comments.service';
import { DetectFocusService } from '../utils/detectFocusPlugin/detect-focus.service';
import { ArticleSection, basicArticleSection } from '../utils/interfaces/articleSection';
import { TrackChangesService } from './track-changes/track-changes.service';
import { YjsHistoryService } from '../utils/yjs-history.service';
import { ProsemirrorEditorsService } from './prosemirror-editor/prosemirror-editors.service';
import { YdocService } from './ydoc.service';
import { EnforcerService } from '@app/casbin/services/enforcer.service';
import { CitableElementsService, IOlderVersionCitableElements } from './citable-elements.service';
import { JatsErrorsDialogComponent } from '../dialogs/jats-errors-dialog/jats-errors-dialog.component';
import { catchError, mergeMap } from 'rxjs/operators';
import { EMPTY, Observable, of, Subject, Subscription } from 'rxjs';
import { createDemoTemplate } from '../utils/serverErrorWorkAround';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { pageDimensionsInPT } from '../dialogs/edit-before-export/edit-before-export.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import Packages from '../../../../package.json';
import { DOMParser, DOMSerializer } from 'prosemirror-model';
import { schema } from '../utils/Schema';
import { AuthService } from '@app/core/services/auth.service';
import {
  ImportJatsService,
  JatsModule,
} from '../dialogs/export-options/jatsXML/importAsJatsXML.service';
import { ImportWizardDialogData } from '../dialogs/export-options/jatsXML/jats.models';
import { ImportWizardDialogComponent } from '../dialogs/export-options/jatsXML/JATS-wizard-modal/import-wizard-modal.component';
import { updateYFragment } from '../../y-prosemirror-src/plugins/sync-plugin';
import { FormBuilderService } from './form-builder.service';
import { HelperService } from '../section/helpers/helper.service';
import { Article, ArticleResponse, Layout, User } from '@app/core/models/article.models';

@Injectable({
  providedIn: 'root',
})
export class ServiceShare {
  articleLayouts: { data: Layout[] };

  CitableElementsService?: CitableElementsService;
  CslService?: CslService;
  ProsemirrorEditorsService?: ProsemirrorEditorsService;
  YdocService?: YdocService;
  TreeService?: TreeService;
  CommentsService?: CommentsService;
  DetectFocusService?: DetectFocusService;
  TrackChangesService?: TrackChangesService;
  ArticleSectionsService?: ArticleSectionsService;
  ArticlesService?: ArticlesService;
  YjsHistoryService?: YjsHistoryService;
  EditorsRefsManagerService?: EditorsRefsManagerService;
  EnforcerService?: EnforcerService;

  private authService: AuthService;
  private formBuilderService: FormBuilderService;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public httpClient: HttpClient,
    private injector: Injector,
    private helperService: HelperService,
    @Inject(APP_CONFIG) public config: AppConfig
  ) {
    this.authService = this.injector.get(AuthService);
    this.formBuilderService = this.injector.get(FormBuilderService);
  }

  ydocVersion = Packages.ydocVersion;
  userRole: string;

  onlyOldVersions = false;

  canUseTrackChanges = true;
  canEditArticle = true;
  canPreviewArticle = true;
  canAddComments = true;
  canAddTaxons = true;
  canUseVersions = true;
  hasOwnerCommentsPolicy = false;

  maintenancePageMode = false;

  oldVersion = false;
  shouldSeeOnlyVersionPreview = false;

  titleControl = new UntypedFormControl();
  subscription = new Subscription();

  globalObj: { [key: string]: unknown } = {};
  dictionaries: { [key: string]: { [key: string]: string } };
  dictionarySubject = new Subject();

  escapeHtmlTags = /<\/?[^>]+(>|$)/g;

  private validationSubject = new Subject<void>();
  validationObservable$ = this.validationSubject.asObservable();

  private validationResultsSubject = new Subject<number>();
  validationResults$ = this.validationResultsSubject.asObservable();

  DOMPMSerializer = DOMSerializer.fromSchema(schema);
  DOMPMParser = DOMParser.fromSchema(schema);

  updateValidationResults(results: number): void {
    this.validationResultsSubject.next(results);
  }

  triggerValidation(): void {
    this.validationSubject.next();
  }

  addDataToGlobalObj(dataKey: string, data: unknown): void {
    this.globalObj[dataKey] = data;
  }

  compareObjects(obj1: unknown, obj2: unknown): boolean {
    return JSON.stringify(obj1) !== JSON.stringify(obj2);
  }

  resolversData: { [key: string]: Observable<boolean> } = {};

  addResolverData(resolveKey: string, data: any): void {
    this.resolversData[resolveKey] = data;
  }

  logData(): void {
    this.ProsemirrorEditorsService!.transactionCount = 0;
  }

  makeFlat?: () => void;

  resetServicesData(shouldResetProsemirrorEditorsAndDoc: boolean): void {
    if (shouldResetProsemirrorEditorsAndDoc) {
      this.ProsemirrorEditorsService?.resetProsemirrorEditors();
    }
    this.YdocService?.resetYdoc();
    this.TreeService?.resetTreeData();
    this.CommentsService?.resetCommentsService();
    this.DetectFocusService?.resetDetectFocusService();
    this.TrackChangesService?.resetTrackChangesService();
    this.YjsHistoryService?.resetHistoryData();
    this.resolversData = {};
  }

  updateCitableElementsViews(): void {
    if (!this.YdocService.doNotRenderEndEditor) {
      this.ProsemirrorEditorsService.editMode = true;
      this.YjsHistoryService.captureBigOperation();
      this.CitableElementsService.updateOnlyElementsViews();
    }
  }

  updateCitableElementsViewsAndCites(olderVersionElements?: IOlderVersionCitableElements): void {
    this.ProsemirrorEditorsService.editMode = true;
    this.YjsHistoryService.captureBigOperation();
    this.CitableElementsService.updateElementsAndElementsCitations(olderVersionElements);
  }

  shouldOpenNewArticleDialog = false;

  createNewArticle(doc?: Document, importJatsService?: ImportJatsService): void {
    this.ProsemirrorEditorsService.spinSpinner();
    this.ArticleSectionsService!.getAllLayouts().subscribe((articleLayouts: { data: Layout[] }) => {
      this.articleLayouts = articleLayouts;
      const dialogRef = this.dialog.open(ChooseManuscriptDialogComponent, {
        width: '563px',
        // height: '657px',
        panelClass: 'choose-namuscript-dialog',
        data: { layouts: articleLayouts },
      });
      this.ProsemirrorEditorsService.stopSpinner();
      dialogRef.afterClosed().subscribe((result) => {
        if (!result) return;
        this.ProsemirrorEditorsService.spinSpinner();
        let userData: { data: User };
        this.authService.currentUser$.subscribe((data: User) => {
          userData = { data };
          this.ArticlesService!.createArticle('Untitled', +result)
            .pipe(catchError(() => this.articleCreateFail()))
            .subscribe((createArticleRes: ArticleResponse) => {
              this.fillArticleData(createArticleRes.data, userData, doc, importJatsService);
            });
        });
      });
    });
  }

  createNewArticleWithDefaultLayout(doc?: Document, importJatsService?: ImportJatsService): void {
    this.ProsemirrorEditorsService.spinSpinner();
    let userData: { data: User };
    this.authService.currentUser$.subscribe((data: User) => {
      userData = { data };
      this.ArticlesService.getDefaultLayoutArticle()
        .pipe(
          mergeMap((res: { data: Layout }) => {
            if (res && res.data) {
              return this.ArticlesService.createArticle('Untitled', res.data.id);
            } else {
              return EMPTY;
            }
          }),
          catchError(() => this.articleCreateFail())
        )
        .subscribe({
          next: (res: ArticleResponse) => {
            if (res && res.data) {
              this.fillArticleData(res.data, userData, doc, importJatsService);
            } else {
              this.createNewArticle(doc, importJatsService);
            }
          },
          error: (err: any) => {
            this.ProsemirrorEditorsService.stopSpinner();
            console.error(err);
          },
        });
    });
  }

  fillArticleData(
    articleData: Article,
    userData: { data: User },
    doc?: Document,
    importJatsService?: ImportJatsService
  ): void {
    this.getDomainPolicies(articleData.uuid);
    const selectedLayout = articleData.layout.template;
    const articleStructure: ArticleSection[] = [];
    this.ArticlesService.replayObservable = of({ data: articleData });
    this.resetServicesData(true);
    this.dictionarySubject.next(articleData.layout.settings.dictionary);
    this.YdocService!.setArticleData(articleData, true);
    this.YdocService.newArticleIsCreated(userData, articleData.uuid);
    selectedLayout.sections = selectedLayout.sections.filter(
      (x: any) =>
        x.name != 'Figures' &&
        x.name != 'References' &&
        x.name != 'Tables' &&
        x.name != 'SupplementaryMaterials' &&
        x.name != 'Footnotes'
    );
    selectedLayout.sections.forEach((section: any) => {
      if (section.settings && section.settings.main_section == true) {
        renderSectionFunc(section, articleStructure, this.YdocService.ydoc, this, 'end');
      }
    });
    this.YdocService.articleStructureFromBackend = articleStructure;

    if (doc) {
      this.YdocService.userInfo = userData;
      this.ProsemirrorEditorsService.userInfo = userData;

      this.YdocService.prepareDocumentForImport();

      this.TreeService.buildNewFormGroups(articleStructure);

      const flatStructure = this.YdocService.articleStructureMap.get(
        'articleSectionsStructureFlat'
      ) as string[];

      const articleBasicStructure = this.YdocService.articleStructureMap.get(
        'articleSectionsStructure'
      ) as basicArticleSection[];

      this.TreeService.initTreeList(articleBasicStructure);

      this.CslService = this.injector.get(CslService);

      const promiseArr = flatStructure.map(async (sectionID) => {
        const section = this.YdocService.getSectionByID(sectionID);
        section.initialRender = undefined;

        const divElement = document.createElement('div');

        const sectionContent = this.formBuilderService.populateDefaultValues(
          this.TreeService.sectionFormGroups[section.sectionID].getRawValue(),
          section.formIOSchema,
          section.sectionID,
          section,
          this.TreeService.sectionFormGroups[section.sectionID]
        );

        let sectionForm: UntypedFormGroup;
        if (this.TreeService.sectionFormGroups[sectionID]) {
          sectionForm = this.TreeService.sectionFormGroups[sectionID];
        } else {
          this.TreeService.sectionFormGroups[sectionID] = new UntypedFormGroup({});
          sectionForm = this.TreeService.sectionFormGroups[sectionID];
          this.formBuilderService.buildFormGroupFromSchema(
            sectionForm,
            section.formIOSchema,
            section
          );
          this.TreeService.setTitleListener(section);
        }
        if (section.formIOSchema.optional) {
          this.ProsemirrorEditorsService.renderEditorInWithId(
            divElement,
            section.sectionID,
            section,
            sectionContent?.components
          );
          return;
        }
        let interpolated: any;
        let prosemirrorNewNodeContent: any;

        prosemirrorNewNodeContent = section.prosemirrorHTMLNodesTempl;
        const root = this.helperService.filter(articleStructure, section.sectionID);
        let { nodeLevel, hTag } = this.TreeService.getNodeLevel(section);
        if (
          root &&
          this.YdocService.getSectionByID(root.sectionID).prosemirrorHTMLNodesTempl.indexOf(
            `<ng-template #${section.title.name.replace(/[\W_]+/g, '')}`
          ) > -1
        ) {
          prosemirrorNewNodeContent = root.prosemirrorHTMLNodesTempl;
          interpolated = await this.ProsemirrorEditorsService.interpolateTemplate(
            prosemirrorNewNodeContent!,
            section.defaultFormIOValues,
            sectionForm,
            section.title.name.replace(/[\W_]+/g, ''),
            { hTag }
          );
        } else {
          interpolated = await this.ProsemirrorEditorsService.interpolateTemplate(
            prosemirrorNewNodeContent!,
            {},
            sectionForm,
            null,
            { hTag }
          );
        }

        let xmlFragment = this.YdocService.ydoc.getXmlFragment(sectionID);
        let templDiv = document.createElement('div');
        templDiv.innerHTML = interpolated;
        let editorSchema = schema;
        let node1 = DOMParser.fromSchema(editorSchema).parse(templDiv.firstChild!);

        console.log(node1);

        updateYFragment(xmlFragment.doc, xmlFragment, node1, new Map());
        this.ProsemirrorEditorsService.renderBasicEditorFromImport(divElement, sectionID, section);
      });

      Promise.all(promiseArr)
        .then(() => {
          return importJatsService.parseXML(doc);
        })
        .then((data: ImportWizardDialogData) => {
          const dialog = this.dialog.open(ImportWizardDialogComponent, {
            width: '90%',
            height: '100px !important',
            panelClass: 'wizard-dialog',
            data: { from: JatsModule.dashboard, data },
            disableClose: true,
          });

          dialog.afterClosed().subscribe((result) => {
            if (result) {
              this.router.navigate([articleData.uuid]);
              this.YdocService.importing = true;
              dialog.close();
            } else {
              this.resetServicesData(true);
            }
          });
        });
    } else {
      this.router.navigate([articleData.uuid]);
    }
  }

  getDomainPolicies(articleUuid: string): void {
    this.ArticlesService.getArticleDomainPolicies(articleUuid).subscribe({
      next: (res) => {
        this.EnforcerService.policiesChangeSubject.next(res);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  articleCreateFail(): Observable<unknown> {
    createDemoTemplate.data.uuid = uuidv4();
    return of(createDemoTemplate);
  }

  openNotifyUserAccessChangeDialog: (oldAccess: string, newAccess: string, title: string) => void;
  openNotAddedToEditorDialog: () => void;
  shareSelf(serviceName: string, serviceInstance: any): void {
    //@ts-ignore
    this[serviceName] = serviceInstance;
  }

  openJatsErrorsDialog(errors: any[]): void {
    this.dialog.open(JatsErrorsDialogComponent, {
      data: { errors },
    });
  }

  updatePreview =
    (selfRef: any) =>
    (checkDiff: boolean): void => {
      let hasEmptyFields = false;
      let differrance = false;
      selfRef
        .getMappedComponentsForPreviw(selfRef)()
        .forEach((comp: any, i: number) => {
          let { componentType, url, description } = comp.container;
          if (componentType == '' || url == '') {
            hasEmptyFields = true;
          }
          if (!selfRef.figureComponentsInPrevew) {
            differrance = true;
          } else if (selfRef.figureComponentsInPrevew[i]) {
            let { componentTypePrev, urlPrev, descriptionPrev } =
              selfRef.figureComponentsInPrevew[i].container;
            if (
              componentTypePrev !== componentType ||
              urlPrev !== url ||
              descriptionPrev !== description
            ) {
              differrance = true;
            }
          } else {
            differrance = true;
          }
        });
      let key = 'A4';
      let a4Pixels = [
        pageDimensionsInPT[key][0],
        pageDimensionsInPT[key][1] - pageDimensionsInPT[key][1] * selfRef.bottomOffset,
      ];
      if (differrance || !checkDiff) {
        if (!hasEmptyFields) {
          selfRef.figureComponentsInPrevew = selfRef.getMappedComponentsForPreviw(selfRef)();

          selfRef.rowOrder = [];
          selfRef.figureComponentsInPrevew.forEach((figure: any, index: number) => {
            if (index < 4) {
              selfRef.rowOrder.push(index + 1);
            }
          });
          let rows = selfRef.figureComponentsInPrevew.length / selfRef.columnsFormControl.value;
          if (rows % 1) {
            rows = Math.floor(
              selfRef.figureComponentsInPrevew.length / selfRef.columnsFormControl.value + 1
            );
          }
          let r = 0;
          let i = 0;
          let iInR = 0;
          selfRef.figureRows = [];
          while (i < selfRef.figureComponentsInPrevew.length && r < rows) {
            if (!selfRef.figureRows[r]) {
              selfRef.figureRows[r] = [];
            }
            selfRef.figureRows[r].push(selfRef.figureComponentsInPrevew[i]);
            i++;
            iInR++;
            if (iInR == selfRef.columnsFormControl.value) {
              iInR = 0;
              r++;
            }
          }
          selfRef.rowTemplate = [];
          for (let i = 0; i < selfRef.columnsFormControl.value; i++) {
            selfRef.rowTemplate.push(i);
          }
          let rowsN = rows;
          let collsN = selfRef.columnsFormControl.value;

          let maxImgHeight = a4Pixels[1] / rowsN;
          let maxImgWidth = a4Pixels[0] / collsN;
          selfRef.maxImgHeightPers = (maxImgHeight * 100) / a4Pixels[1];
          selfRef.maxImgWidthPers = (maxImgWidth * 100) / a4Pixels[0];

          let calcImgPersentageFromFullA4 = (
            img: HTMLImageElement,
            maxImgHeight: number,
            maxImgWidth: number,
            a4PixelRec: number[],
            figComp: any
          ) => {
            if (img.naturalHeight < maxImgHeight && img.naturalWidth < maxImgWidth) {
              let heightPersent = img.naturalHeight / a4PixelRec[1];
              let widthPersent = img.naturalWidth / a4PixelRec[0];
              figComp.container.hpers = heightPersent;
              figComp.container.wpers = widthPersent;

              figComp.container.h = img.naturalHeight;
              figComp.container.w = img.naturalWidth;
            } else if (img.naturalHeight / maxImgHeight > img.naturalWidth / maxImgWidth) {
              figComp.container.height = maxImgHeight / a4PixelRec[1];

              let scalePers = maxImgHeight / img.naturalHeight;
              figComp.container.h = maxImgHeight;
              figComp.container.w = scalePers * img.naturalWidth;
            } else if (img.naturalHeight / maxImgHeight < img.naturalWidth / maxImgWidth) {
              figComp.container.width = maxImgWidth / a4PixelRec[0];

              let scalePers = maxImgWidth / img.naturalWidth;
              figComp.container.h = scalePers * img.naturalHeight;
              figComp.container.w = maxImgWidth;
            } else if (img.naturalHeight / maxImgHeight == img.naturalWidth / maxImgWidth) {
              figComp.container.height = maxImgHeight / a4PixelRec[1];
              figComp.container.width = maxImgWidth / a4PixelRec[0];

              figComp.container.h = maxImgHeight;
              figComp.container.w = maxImgWidth;
            }
            if (
              figComp.container.h &&
              figComp.container.w &&
              figComp.container.pdfImgOrigin.includes(selfRef.config.CDNService)
            ) {
              figComp.container.pdfImgResized =
                figComp.container.pdfImgOrigin +
                `/resize/${figComp.container.w}x${figComp.container.h}/`;
              selfRef.urlMapping[figComp.container.pdfImgOrigin] = figComp.container.pdfImgResized;
            } else {
              figComp.container.pdfImgResized = figComp.container.pdfImgOrigin;
              selfRef.urlMapping[figComp.container.pdfImgOrigin] = figComp.container.pdfImgResized;
            }
          };

          for (let i = 0; i < selfRef.figureRows.length; i++) {
            for (let j = 0; j < selfRef.figureRows[i].length; j++) {
              let image = selfRef.figureRows[i][j];
              let newImg = new Image();

              // newImg.addEventListener('load',()=>{
              //   calcImgPersentageFromFullA4(newImg,maxImgHeight,maxImgWidth,a4Pixels,image);
              // })

              calcImgPersentageFromFullA4(newImg, maxImgHeight, maxImgWidth, a4Pixels, image);

              // newImg.src = image.container.pdfImgOrigin;
            }
          }
          selfRef.figureCanvasData = {
            a4Pixels,
            figRows: selfRef.figureRows,
            nOfRows: rowsN,
            nOfColumns: collsN,
            maxImgHeightPers: selfRef.maxImgHeightPers,
            maxImgWidthPers: selfRef.maxImgWidthPers,
          };
          selfRef.displayPreviewComponents = true;
        }
      }
    };

  updateStylesheetForHiddenComments(userIds: string[]): void {
    let styleElement = document.getElementById('hidden-comments-style');
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.id = 'hidden-comments-style';
      document.head.appendChild(styleElement);
    }

    let cssRules = '';
    userIds.forEach((userId) => {
      cssRules += `.comment[data-userid='${userId}'] { background-color: unset; }\n .user-tooltip[user-id='${userId}'] { display: none !important; } \n .comment[data-userid='${userId}'] .active-comment { background-color: unset !important; }`;
    });
    styleElement.innerHTML = cssRules;
  }

  removeStylesheetForHiddenComments(): void {
    const styleElement = document.getElementById('hidden-comments-style');
    if (styleElement) {
      document.head.removeChild(styleElement);
    }
  }
}
