import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { TaxonImportState } from './taxon-import-state.model';

const initialState: TaxonImportState = {
  totalTaxons: 0,
  processedTaxons: 0,
  progress: 0,
  isRendering: false,
  isProcessing: false,
};

@Injectable({
  providedIn: 'root',
})
export class TaxonImportStateService {
  private state = new BehaviorSubject<TaxonImportState>(initialState);
  private importFinishedSubject = new Subject<void>();

  get totalTaxons$(): Observable<number> {
    return this.state.pipe(
      map((state) => state.totalTaxons),
      distinctUntilChanged()
    );
  }

  get processedTaxons$(): Observable<number> {
    return this.state.pipe(
      map((state) => state.processedTaxons),
      distinctUntilChanged()
    );
  }

  get progress$(): Observable<number> {
    return this.state.pipe(
      map((state) => state.progress),
      distinctUntilChanged()
    );
  }

  get isRendering$(): Observable<boolean> {
    return this.state.pipe(
      map((state) => state.isRendering),
      distinctUntilChanged()
    );
  }

  get isProcessing$(): Observable<boolean> {
    return this.state.pipe(
      map((state) => state.isProcessing),
      distinctUntilChanged()
    );
  }

  get importFinished$(): Observable<void> {
    return this.importFinishedSubject.asObservable();
  }

  setTotalTaxons(count: number): void {
    this.updateState({ totalTaxons: count });
  }

  setProcessedTaxons(count: number): void {
    this.updateState({
      processedTaxons: count,
    });
  }

  setRenderingState(isRendering: boolean): void {
    this.updateState({ isRendering });
  }

  setProcessingState(state: boolean): void {
    this.updateState({ isProcessing: state });
  }

  updateProgress(processed: number, total: number): void {
    this.updateState({
      processedTaxons: processed,
      totalTaxons: total,
    });
  }

  triggerRendering(): void {
    this.updateState({
      isProcessing: false,
      isRendering: true,
    });
  }

  finishImport(): void {
    this.importFinishedSubject.next();
    this.importFinishedSubject.complete();
    this.state.next(initialState);
  }

  private updateState(partialState: Partial<TaxonImportState>): void {
    this.state.next({
      ...this.state.value,
      ...partialState,
      progress:
        partialState.processedTaxons !== undefined && partialState.totalTaxons !== undefined
          ? (partialState.processedTaxons / partialState.totalTaxons) * 100
          : this.state.value.progress,
    });
  }
}
