export enum EditMode {
  edit = 'edit',
  suggest = 'suggest',
  preview = 'preview',
}

export interface EditModePermissions {
  canEdit: boolean;
  canSuggest: boolean;
  canPreview: boolean;
}

interface ArticlePermissions {
  canEdit: boolean;
  canSuggest: boolean;
}

export interface TrackChangesMetadata {
  trackTransactions: boolean;
  lastUpdateFromUser?: string;
}

export interface Permissions {
  enforcer: EditModePermissions;
  article: ArticlePermissions;
}

export interface EditModeMetadata {
  trackTransactions: boolean;
  lastUpdateFromUser: string;
  currentUserGuid: string;
  permissions?: EditModePermissions;
  hasVersionData?: boolean;
}
