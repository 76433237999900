import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ArticleSectionsService } from '@app/core/services/article-sections.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { TreeService } from '../../../meta-data-tree/tree-service/tree.service';
import { ProsemirrorEditorsService } from '../../../services/prosemirror-editor/prosemirror-editors.service';
import { YdocService } from '../../../services/ydoc.service';
import { ArticleSection, basicArticleSection } from '../../../utils/interfaces/articleSection';
import { ServiceShare } from '../../../services/service-share.service';
import {
  ValidationExecutionResult,
  HtmlConvertibleObject,
  PositionFunctions,
  SectionData,
  ValidationExpression,
  ValidationResult,
  ValidationRule,
  ValidationConfigItem,
  CharacterCountRule,
  SectionMinMaxRule,
  SectionPositionRule,
  SectionResponse,
} from '../../validation-section.models';
import { ValidationRuleUtils } from '../../utils/validation-rules.utils';
import { ValidationRulesService } from '../../services/validation-rules.service';
import { DocumentStateValidationService } from '../../services/document-state-validation.service';
import { ValidationMetadataService } from '../../services/validation-metadata.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import { CitedItemsValidationService } from '../../services/cited-items-validation.service';

@Component({
  selector: 'app-validation-section',
  templateUrl: './validation-section.component.html',
  styleUrls: ['./validation-section.component.scss'],
  providers: [ValidationRulesService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationSectionComponent implements OnDestroy, AfterViewInit {
  @ViewChild('spinner', { read: ElementRef }) spinnerEl?: ElementRef;

  spinnerComponent!: boolean;
  displayErrors = false;
  progress = 0;
  invalidItems = 0;
  articleLength = 0;
  articleValidations: ValidationResult[] = [];
  articleFormFieldsValidation: ValidationResult[] = [];
  nonCitedFiguresValidation: ValidationResult[] = [];
  nonCitedTablesValidation: ValidationResult[] = [];
  nonCitedSupplementaryFilesValidation: ValidationResult[] = [];
  nonCitedEndNotesValidation: ValidationResult[] = [];
  articleValidationsErrors: ValidationResult[] = [];
  complexSectionsMinMaxErrors: ValidationResult[] = [];
  nonCitedReferences: ValidationResult[] = [];
  resolvedChangesValidation: ValidationResult[] = [];
  resolvedCommentsValidation: ValidationResult[] = [];

  private rotationDegrees = 0;
  private intervalID: NodeJS.Timeout;
  private subscription: Subscription;
  private doneValidationSubject?: Subject<ValidationExecutionResult>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private treeService: TreeService,
    private articleSectionsService: ArticleSectionsService,
    private changeDetectorRef: ChangeDetectorRef,
    private ydocService: YdocService,
    private serviceShare: ServiceShare,
    private validationRulesService: ValidationRulesService,
    private documentStateValidationService: DocumentStateValidationService,
    private snackBar: SnackbarService,
    private validationMetadataService: ValidationMetadataService,
    private citedItemsValidationService: CitedItemsValidationService
  ) {}

  get validationConfig(): ValidationConfigItem[] {
    return [
      { label: 'Section Field(s)', data: this.articleFormFieldsValidation },
      { label: 'Figures', data: this.nonCitedFiguresValidation },
      { label: 'Tables', data: this.nonCitedTablesValidation },
      { label: 'Supplementary Files', data: this.nonCitedSupplementaryFilesValidation },
      { label: 'EndNotes', data: this.nonCitedEndNotesValidation },
      { label: 'Other', data: this.articleValidations },
      { label: 'Not Processed', data: this.articleValidationsErrors },
      {
        label: 'Complex Sections minimum/maximum subsections',
        data: this.complexSectionsMinMaxErrors,
      },
      { label: 'References', data: this.nonCitedReferences },
      { label: 'Unresolved Changes', data: this.resolvedChangesValidation },
      { label: 'Unresolved Comments', data: this.resolvedCommentsValidation },
    ];
  }

  ngAfterViewInit(): void {
    this.subscription = this.serviceShare.validationObservable$.subscribe(() => {
      this.validate();
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }

  validate(): void {
    this.initialize();
    this.executeValidation()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((validationResult) => {
        if (validationResult === ValidationExecutionResult.done) {
          this.finalizeValidation();
        }
      });
  }

  cancelValidation(): void {
    this.spinnerComponent = false;
    this.displayErrors = false;
    this.doneValidationSubject!.next(ValidationExecutionResult.cancel);
    this.progress = 0;
    clearInterval(this.intervalID);
  }

  scrollToSection(sectionId: string): void {
    this.serviceShare.ProsemirrorEditorsService.scrollTo(sectionId);
  }

  private initialize(): void {
    this.spinnerComponent = true;
    this.displayErrors = false;
    this.resetValidationArrays();
    this.invalidItems = 0;
    this.doneValidationSubject = new Subject();
  }

  private executeValidation(): Observable<ValidationExecutionResult> {
    this.startSpinner();
    return this.validationRulesService
      .getRules()
      .pipe(switchMap((rules) => this.processValidationRules(rules)));
  }

  private resetValidationArrays(): void {
    this.articleValidations = [];
    this.articleFormFieldsValidation = [];
    this.nonCitedFiguresValidation = [];
    this.nonCitedTablesValidation = [];
    this.nonCitedSupplementaryFilesValidation = [];
    this.nonCitedEndNotesValidation = [];
    this.nonCitedReferences = [];
    this.articleValidationsErrors = [];
    this.complexSectionsMinMaxErrors = [];
  }

  private loopFormGroupChildren(
    form: UntypedFormGroup | UntypedFormArray,
    callback: (child: UntypedFormControl, key: string) => void
  ): void {
    if (form instanceof UntypedFormGroup) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.controls[key];
        if (control instanceof UntypedFormControl) {
          callback(control, key);
        } else {
          this.loopFormGroupChildren(control as UntypedFormGroup | UntypedFormArray, callback);
        }
      });
    } else if (form instanceof UntypedFormArray) {
      form.controls.forEach((control, index: number) => {
        if (control instanceof UntypedFormControl) {
          callback(control, `${index}`);
        } else {
          this.loopFormGroupChildren(control as UntypedFormGroup | UntypedFormArray, callback);
        }
      });
    }
  }

  private startSpinner(): void {
    this.intervalID = setInterval(() => {
      this.rotationDegrees = this.rotationDegrees + 30;
      if (this.rotationDegrees == -360) {
        this.rotationDegrees = 0;
      }
      this.updateSpinnerTransform();
    }, 100);
  }

  private updateSpinnerTransform(): void {
    const transform = `rotate(${this.rotationDegrees}deg)`;
    const spinnerElement = this.spinnerEl!.nativeElement as HTMLImageElement;
    // eslint-disable-next-line spellcheck/spell-checker
    spinnerElement.style.webkitTransform = transform;
    spinnerElement.style.transform = transform;
    //@ts-expect-error vendor-prefixed CSS properties are no longer officially supported
    // eslint-disable-next-line spellcheck/spell-checker
    spinnerElement.style.mozTransform = transform;
    //@ts-expect-error vendor-prefixed CSS properties are no longer officially supported
    spinnerElement.style.msTransform = transform;
    //@ts-expect-error vendor-prefixed CSS properties are no longer officially supported
    spinnerElement.style.oTransform = transform;
  }

  private calculateArticleLength(): void {
    let symbolCount = 0;
    const countSymbols = (sections: basicArticleSection[]): void => {
      sections.forEach((sec) => {
        const articleSection = this.ydocService.articleSectionsMap.get(sec.sectionID);

        if (articleSection.type == 'complex' && sec.children.length > 0) {
          countSymbols(sec.children);
        }
        if (sec.active && articleSection.mode != 'noSchemaSectionMode') {
          const editorView =
            this.prosemirrorEditorsService.editorContainers[sec.sectionID]?.editorView;
          if (editorView) {
            symbolCount += editorView.state.doc.textContent.length;
          }
        }
      });
    };
    countSymbols(this.treeService.articleSectionsStructure);
    this.articleLength = symbolCount;
  }

  private processValidationRules(rules: ValidationRule[]): Observable<ValidationExecutionResult> {
    const validationsLength = rules.length;
    let validatedCount = 0;

    return new Observable<ValidationExecutionResult>((observer) => {
      const subscription = this.doneValidationSubject!.subscribe((data) => {
        if (data === ValidationExecutionResult.cancel) {
          observer.next(ValidationExecutionResult.cancel);
          observer.complete();
        } else {
          validatedCount++;
          this.progress = (validatedCount / validationsLength) * 100;
          this.changeDetectorRef.detectChanges();

          if (validatedCount === validationsLength) {
            observer.next(ValidationExecutionResult.done);
            observer.complete();
          }
        }
      });

      this.calculateArticleLength();
      this.processAllRules(rules);

      return () => subscription.unsubscribe();
    });
  }

  private processAllRules(rules: ValidationRule[]): void {
    this.articleSectionsService
      .getAllSections({ page: 1, pageSize: 999 })
      .subscribe((allSectionDataFromBackend) => {
        rules.forEach((rule) => this.processRule(rule, allSectionDataFromBackend));
      });
  }

  private processRule(rule: ValidationRule, allSectionDataFromBackend: SectionResponse): void {
    try {
      switch (rule.rule) {
        case 'ToBeBetweenMinMax':
          this.validateToBeBetweenMinMax(rule);
          break;
        case 'ToHaveMinMaxEqualSections':
          this.validateToHaveMinMaxEqualSections(rule, allSectionDataFromBackend);
          break;
        case 'EqualSectionPositions':
          this.validateToHaveEqualSectionPositions(rule, allSectionDataFromBackend);
          break;
        case 'FormControls':
          this.validateFormControls();
          break;
        case 'ValidateCitedItems':
          this.validateCitedItems();
          break;
        case 'ValidateComplexSections':
          this.validateComplexSections();
          break;
        case 'ValidateResolvedChanges':
          this.validateResolvedChanges();
          break;
        case 'ValidateResolvedComments':
          this.validateResolvedComments();
          break;
        default:
          console.warn(`Unhandled rule type: ${(rule as ValidationRule).rule}`);
          this.doneValidationSubject!.next(ValidationExecutionResult.inProgress);
      }
    } catch (e) {
      this.handleValidationError(e, rule);
    }
  }

  private validateResolvedChanges(): void {
    this.resolvedChangesValidation = this.documentStateValidationService.validateResolvedChanges();
    this.doneValidationSubject!.next(ValidationExecutionResult.inProgress);
  }

  private validateResolvedComments(): void {
    this.resolvedCommentsValidation =
      this.documentStateValidationService.validateResolvedComments();
    this.doneValidationSubject!.next(ValidationExecutionResult.inProgress);
  }

  private finalizeValidation(): void {
    this.displayErrors = true;
    this.spinnerComponent = false;
    clearInterval(this.intervalID);
    this.progress = 0;
    this.calculateTotalResults();
    this.serviceShare.updateValidationResults(this.invalidItems);
    this.displayValidationResultMessage();

    // Update validation metadata
    this.validationMetadataService
      .updateMetadata(this.articleLength)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();

    this.changeDetectorRef.detectChanges();
  }

  private calculateTotalResults(): void {
    this.invalidItems += this.articleValidations.length;
    this.invalidItems += this.articleFormFieldsValidation.length;
    this.invalidItems += this.nonCitedFiguresValidation.length;
    this.invalidItems += this.nonCitedTablesValidation.length;
    this.invalidItems += this.nonCitedSupplementaryFilesValidation.length;
    this.invalidItems += this.nonCitedEndNotesValidation.length;
    this.invalidItems += this.nonCitedReferences.length;
    this.invalidItems += this.articleValidationsErrors.length;
    this.invalidItems += this.complexSectionsMinMaxErrors.length;
    this.invalidItems += this.resolvedChangesValidation.length;
    this.invalidItems += this.resolvedCommentsValidation.length;
  }

  private handleValidationError(error: Error, rule: ValidationRule): void {
    this.articleValidationsErrors.push({
      fulfilled: false,
      errorMessage: `There was problem processing the validation : \n${JSON.stringify(
        rule,
        undefined,
        '\t'
      )}`,
    });
    this.doneValidationSubject!.next(ValidationExecutionResult.inProgress);
    console.error(error);
  }

  private validateToBeBetweenMinMax(rule: CharacterCountRule): void {
    const min = +rule.config.min;
    const max = +rule.config.max;

    if (min > this.articleLength || max < this.articleLength) {
      this.articleValidations.push({
        fulfilled: false,
        errorMessage: `Number of characters in the article is not in the required range: ( minimum: ${min}, maximum: ${max})`,
      });
    }
    this.doneValidationSubject!.next(ValidationExecutionResult.inProgress);
  }

  private validateToHaveMinMaxEqualSections(
    rule: SectionMinMaxRule,
    allSectionDataFromBackend: SectionResponse
  ): void {
    const sectionNames = ValidationRuleUtils.getSectionNames(rule);
    const min = rule.config.min;
    const max = rule.config.max;

    const allSectionNamesFromBackend = allSectionDataFromBackend.data.map(
      (section: SectionData) => section.name
    );
    const container = document.createElement('div');

    sectionNames.forEach((secName) => {
      if (allSectionNamesFromBackend.includes(secName)) {
        let sectionCount = 0;
        const count = 0;
        const expressionsObj = ValidationRuleUtils.parseExpressions(rule.config.expressions) || [];
        const expressErrorMessages: string[] = [];

        this.countSectionsWithExpression(
          this.treeService.articleSectionsStructure,
          secName,
          (section: ArticleSection) => {
            sectionCount++;
            const formGroup = this.treeService.sectionFormGroups[section.sectionID];
            const value = JSON.parse(JSON.stringify(formGroup.value));
            this.convertHtmlToTextContent(value, container);

            if (!Array.isArray(expressionsObj) || expressionsObj.length === 0) {
              return true;
            }

            let returnVal = true;
            for (const expr of expressionsObj) {
              const expFunc = Function('value', 'return ' + expr.rule);
              const result = expFunc(value);
              if (!result && !expressErrorMessages.includes(expr.errorMessage)) {
                expressErrorMessages.push(expr.errorMessage);
              }
              returnVal = returnVal && result;
            }
            return returnVal;
          },
          count
        );

        this.validateSectionCounts(
          secName,
          sectionCount,
          count,
          min,
          max,
          expressionsObj,
          expressErrorMessages
        );
      }
    });

    this.doneValidationSubject!.next(ValidationExecutionResult.inProgress);
  }

  private validateToHaveEqualSectionPositions(
    rule: SectionPositionRule,
    allSectionDataFromBackend: SectionResponse
  ): void {
    const sectionNames = ValidationRuleUtils.getSectionNames(rule);
    const allSectionNamesFromBackend = allSectionDataFromBackend.data.map(
      (section: SectionData) => section.name
    );

    sectionNames.forEach((secName) => {
      if (allSectionNamesFromBackend.includes(secName)) {
        let sectionsWithWrongPositions = 0;
        const expressionsObj = ValidationRuleUtils.parseExpressions(rule.config.expressions);
        const expressErrorMessages: string[] = [];

        this.countSectionsWithPosition(
          this.treeService.articleSectionsStructure,
          secName,
          (section: ArticleSection, secContainer: ArticleSection[]) => {
            let returnVal = true;
            expressionsObj.forEach((expr: { rule: string; errorMessage: string }) => {
              const expFunc = Function('f', 'return ' + expr.rule);
              const result = expFunc(this.getPositionFunctions(section, secContainer));
              if (!result && !expressErrorMessages.includes(expr.errorMessage)) {
                expressErrorMessages.push(expr.errorMessage);
              }
              returnVal = returnVal && result;
            });
            if (!returnVal) sectionsWithWrongPositions++;
            return returnVal;
          }
        );

        if (sectionsWithWrongPositions !== 0) {
          this.articleValidations.push({
            fulfilled: false,
            errorMessage:
              sectionsWithWrongPositions === 1
                ? `There is ${sectionsWithWrongPositions} section with name "${secName}" that is not ordered properly. Order rules for this type of sections: (${expressionsObj.map((el) => el.errorMessage).join(' ')}).`
                : `There are ${sectionsWithWrongPositions} sections with name "${secName}" that are not ordered properly. Order rules for this type of sections: (${expressionsObj.map((el) => el.errorMessage).join(' ')}).`,
          });
        }
      }
    });

    this.doneValidationSubject!.next(ValidationExecutionResult.inProgress);
  }

  private validateFormControls(): void {
    const formGroups = this.treeService.sectionFormGroups;

    const validateSection = (sections: basicArticleSection[]): void => {
      sections.forEach((sec) => {
        const articleSection = this.ydocService.articleSectionsMap.get(sec.sectionID);

        if (articleSection.type == 'complex' && sec.children.length > 0) {
          validateSection(sec.children);
        }

        if (sec.active) {
          const formGroup = formGroups[sec.sectionID];
          this.loopFormGroupChildren(formGroup, (child: UntypedFormControl) => {
            if (child.status == 'INVALID') {
              const errorMessage = child.errors
                ? Object.keys(child.errors)
                    .map((error) => child.errors![error].message)
                    .join(' ')
                : 'This field is required!';

              const label = articleSection.title.label.replace(
                /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
                ''
              );

              this.articleFormFieldsValidation.push({
                fulfilled: false,
                errorMessage: `Content in "${label}". ${errorMessage}`,
                sectionId: articleSection.sectionID,
              });
            }
          });
        }
      });
    };

    validateSection(this.treeService.articleSectionsStructure!);
    this.doneValidationSubject!.next(ValidationExecutionResult.inProgress);
  }

  private validateCitedItems(): void {
    const validationResults = this.citedItemsValidationService.validateAllCitations();

    this.nonCitedFiguresValidation = validationResults.figures;
    this.nonCitedTablesValidation = validationResults.tables;
    this.nonCitedSupplementaryFilesValidation = validationResults.supplementaryFiles;
    this.nonCitedEndNotesValidation = validationResults.endNotes;
    this.nonCitedReferences = validationResults.references;

    this.doneValidationSubject!.next(ValidationExecutionResult.inProgress);
  }

  private validateComplexSections(): void {
    const validateComplexSecMinMax = (
      complexSection: ArticleSection,
      sectionsFromBackend: SectionData[]
    ): void => {
      const errors: string[] = [];
      const children = complexSection.children;

      Object.keys(complexSection.subsectionValidations!).forEach((pivotId) => {
        const subSecMinMax = complexSection.subsectionValidations![pivotId];
        let countOfType = 0;

        children.forEach((child) => {
          if (child.pivotId === Number(pivotId)) {
            countOfType++;
          }
        });

        const sectionFromBackend = sectionsFromBackend.find((el) => el.pivot_id == pivotId);

        if (sectionFromBackend) {
          const secName = sectionFromBackend.name;
          if (countOfType < subSecMinMax.min) {
            errors.push(
              `Number of "${secName}" sections should be more than ${subSecMinMax.min - 1}`
            );
          }
          if (countOfType > subSecMinMax.max) {
            errors.push(
              `Number of "${secName}" sections should be less than ${subSecMinMax.max + 1}`
            );
          }
        }
      });

      if (errors.length > 0) {
        this.complexSectionsMinMaxErrors.push({
          fulfilled: false,
          errorMessage: `Complex section "${complexSection.title.label}" should match the required minimum and maximum validations. ${errors.join('. ')}`,
        });
      }
    };

    this.articleSectionsService
      .getAllSections({ page: 1, pageSize: 999 })
      .subscribe((resData: SectionResponse) => {
        const loopTree = (section: basicArticleSection): void => {
          const articleSection = this.ydocService.articleSectionsMap.get(
            section.sectionID
          ) as ArticleSection;

          if (
            articleSection.type == 'complex' &&
            articleSection.subsectionValidations &&
            Object.keys(articleSection.subsectionValidations).length > 0
          ) {
            validateComplexSecMinMax(articleSection, resData.data);
          }

          if (articleSection.type == 'complex') {
            section.children.forEach((sec) => loopTree(sec));
          }
        };

        this.treeService.articleSectionsStructure.forEach((section) => loopTree(section));
        this.doneValidationSubject!.next(ValidationExecutionResult.inProgress);
      });
  }

  private convertHtmlToTextContent(obj: HtmlConvertibleObject, container: HTMLElement): void {
    if (obj) {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (typeof value === 'string' || typeof value === 'number') {
          container.innerHTML = String(value);
          obj[key] = container.textContent || '';
        } else {
          try {
            this.convertHtmlToTextContent(value, container);
          } catch (e) {
            console.error(e);
          }
        }
      });
    }
  }

  private countSectionsWithExpression(
    sections: basicArticleSection[] | ArticleSection[],
    name: string,
    callback: (section: ArticleSection) => boolean,
    count: number
  ): void {
    sections.forEach((s) => {
      const sec = this.ydocService.articleSectionsMap.get(s.sectionID);
      if (sec) {
        if (sec.type == 'complex' && sec.children.length > 0) {
          this.countSectionsWithExpression(sec.children, name, callback, count);
        }
        if (sec.title.name == name) {
          count += callback(sec) ? 1 : 0;
        }
      }
    });
  }

  private countSectionsWithPosition(
    sections: basicArticleSection[] | ArticleSection[],
    name: string,
    callback: (
      section: ArticleSection,
      secContainer: ArticleSection[] | basicArticleSection[]
    ) => boolean
  ): void {
    sections.forEach((s) => {
      const sec = this.ydocService.articleStructureMap.get(s.sectionID);
      if (sec) {
        if (sec.type == 'complex' && sec.children.length > 0) {
          this.countSectionsWithPosition(sec.children, name, callback);
        }
        if (sec.title.name == name) {
          callback(sec, sections);
        }
      }
    });
  }

  private validateSectionCounts(
    secName: string,
    sectionCount: number,
    count: number,
    min: number,
    max: number,
    expressionsObj: ValidationExpression[],
    expressErrorMessages: string[]
  ): void {
    let conditionsMessage = '';
    if (expressionsObj.length) {
      conditionsMessage = ` and should meet the following conditions: (${expressionsObj
        .map((el) => el.errorMessage)
        .join(' ')})`;
    }

    if (sectionCount === 0) {
      if (min && max) {
        if (min > count || max < count) {
          this.articleValidations.push({
            fulfilled: false,
            errorMessage: `There are no active sections with name "${secName}" in the article. They should be no less than ${min} and no more than ${max}${conditionsMessage}.`,
          });
        }
      } else if (max) {
        if (max < count) {
          this.articleValidations.push({
            fulfilled: false,
            errorMessage: `There are no active sections with name "${secName}" in the article. They should be no more than ${max}${conditionsMessage}.`,
          });
        }
      } else if (min) {
        if (min > count) {
          this.articleValidations.push({
            fulfilled: false,
            errorMessage: `There are no active sections with name "${secName}" in the article. They should be no less than ${min}${conditionsMessage}.`,
          });
        }
      }
    } else {
      if (min && max) {
        if (min > count || max < count) {
          this.articleValidations.push({
            fulfilled: false,
            errorMessage: `Sections with name "${secName}" do not fulfill the conditions: (${expressErrorMessages.join(' ')}). Current count of sections that meet the conditions is ${count}, they should be no less than ${min} and no more than ${max}.`,
          });
        }
      } else if (max) {
        if (max < count) {
          this.articleValidations.push({
            fulfilled: false,
            errorMessage: `Sections with name "${secName}" do not fulfill the conditions: (${expressErrorMessages.join(' ')}). Current count of sections that meet the conditions is ${count}, they should be no more than ${max}.`,
          });
        }
      } else if (min) {
        if (min > count) {
          this.articleValidations.push({
            fulfilled: false,
            errorMessage: `Sections with name "${secName}" do not fulfill the conditions: (${expressErrorMessages.join(' ')}). Current count of sections that meet the conditions is ${count}, they should be no less than ${min}.`,
          });
        }
      }
    }
  }

  private getPositionFunctions(
    section: ArticleSection,
    sectionContainer: ArticleSection[]
  ): PositionFunctions {
    const indexOfSec = sectionContainer.indexOf(section);

    return {
      isFirst: (): boolean => indexOfSec === 0,
      isLast: (): boolean => indexOfSec === sectionContainer.length - 1,
      isAfter: (secToBeAfterName: string): boolean => {
        const indexOfGiven = sectionContainer.findIndex(
          (sec) => sec.title.name === secToBeAfterName
        );
        return indexOfSec > indexOfGiven;
      },
      sectionCount: sectionContainer.filter((sec) => sec.title.name === section.title.name).length,
    };
  }

  private displayValidationResultMessage(): void {
    if (this.invalidItems === 0) {
      this.snackBar.success('Validation completed successfully');
    } else if (this.invalidItems === 1) {
      this.snackBar.error('There is 1 validation error that needs to be fixed!');
    } else {
      this.snackBar.error(
        `There are ${this.invalidItems} validation errors that need to be fixed!`
      );
    }
  }
}
