import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditMode } from '@app/editor/services/edit-mode/models';
import { EditModeState, EditModeViewModel } from './models';

export const selectEditModeState = createFeatureSelector<EditModeState>('editMode');

export const selectCurrentMode = createSelector(selectEditModeState, (state) => state.currentMode);

export const selectIsEditMode = createSelector(selectCurrentMode, (mode) => mode === EditMode.edit);

export const selectIsSuggestMode = createSelector(
  selectCurrentMode,
  (mode) => mode === EditMode.suggest
);

export const selectIsPreviewMode = createSelector(
  selectCurrentMode,
  (mode) => mode === EditMode.preview
);

// Permission selectors
export const selectEditModePermissions = createSelector(
  selectEditModeState,
  (state) => state.permissions
);

export const selectCanEdit = createSelector(
  selectEditModePermissions,
  (permissions) => permissions.canEdit
);

export const selectCanSuggest = createSelector(
  selectEditModePermissions,
  (permissions) => permissions.canSuggest
);

export const selectCanPreview = createSelector(
  selectEditModePermissions,
  (permissions) => permissions.canPreview
);

// Combined selectors for common use cases
export const selectCanEditOrSuggest = createSelector(
  selectCanEdit,
  selectCanSuggest,
  (canEdit, canSuggest) => canEdit || canSuggest
);

export const selectEditModeViewModel = createSelector(
  selectCurrentMode,
  (mode): EditModeViewModel => ({
    currentMode: mode,
    isEdit: mode === EditMode.edit,
    isSuggest: mode === EditMode.suggest,
    isPreview: mode === EditMode.preview,
  })
);
