export type Colors = { background: string; foreground: string };

export interface ChangeData {
  changeMarkId: string;
  pmDocStartPos: number;
  pmDocEndPos: number;
  section: string;
  domTop: number;
  changeTxt: string;
  type: string;
  changeAttrs: ChangeAttrs;
  selected: boolean;
}

export interface ChangeAttrs {
  class: string;
  id: string;
  user: string;
  username: string;
  userColor: string;
  userContrastColor: string;
  date: number;
  group: string;
  viewid: string;
  style: string;
  connectedTo: string;
  resolved?: boolean;
}

export interface SelectedChange {
  pmDocStartPos?: number;
  section?: string;
  changeMarkId?: string;
}

export enum TrackChangesMarkNames {
  insertion = 'insertion',
  deletion = 'deletion',
  formatChange = 'format-change',
}
