import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ValidationSectionComponent } from './components/validation-section/validation-section.component';
import { ValidationSpinnerComponent } from './components/validation-spinner/validation-spinner.component';

import { ValidationRulesService } from './services/validation-rules.service';
import { DocumentStateValidationService } from './services/document-state-validation.service';
import { SharedModule } from '@app/shared/shared.module';
import { ValidationMetadataService } from './services/validation-metadata.service';

@NgModule({
  declarations: [ValidationSectionComponent, ValidationSpinnerComponent],
  imports: [CommonModule, SharedModule, MatCardModule, MatIconModule, MatProgressBarModule],
  exports: [ValidationSectionComponent, ValidationSpinnerComponent],
  providers: [ValidationRulesService, DocumentStateValidationService, ValidationMetadataService],
})
export class ValidationModule {}
