import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Compiler,
} from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SafeHtmlPipe, SafePipe } from '@app/formio-angular-material/components/MaterialComponent';
import { MaterialModule } from '@app/shared/material.module';
import { Subject } from 'rxjs';

import { FormControlNameDirective } from '../directives/form-control-name.directive';
import { ProsemirrorEditorsService } from '../services/prosemirror-editor/prosemirror-editors.service';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

@Component({
  selector: 'app-editor-menu',
  templateUrl: './editor-menu.component.html',
  styleUrls: ['./editor-menu.component.scss'],
})
export class EditorMenuComponent implements AfterViewInit {
  @ViewChild('container', { read: ViewContainerRef }) container?: ViewContainerRef;

  constructor(private rposemirrorEditorsService: ProsemirrorEditorsService) {}
  ngAfterViewInit(): void {
    this.rposemirrorEditorsService.containerElement = this.container;
  }
}
