import { createReducer, on } from '@ngrx/store';
import * as EditModeActions from './edit-mode.actions';
import { EditMode } from '@app/editor/services/edit-mode/models';
import { EditModeState } from './models';

export const initialState: EditModeState = {
  currentMode: EditMode.edit,
  permissions: {
    canEdit: false,
    canSuggest: false,
    canPreview: true,
  },
};

export const editModeReducer = createReducer(
  initialState,
  on(EditModeActions.setEditMode, (state, { mode }) => ({
    ...state,
    currentMode: mode,
  })),
  on(EditModeActions.toggleEditMode, (state) => ({
    ...state,
    currentMode: state.currentMode === EditMode.suggest ? EditMode.edit : EditMode.suggest,
  })),
  on(EditModeActions.updatePermissions, (state, { permissions }) => ({
    ...state,
    permissions: {
      ...state.permissions,
      ...permissions,
    },
  }))
);
