import { HttpClient } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { getYdocData } from './getYdocData';
import { YdocService } from '@app/editor/services/ydoc.service';
import { Subject } from 'rxjs';
import { EditorState } from 'prosemirror-state';
import { EditorView as EditorViewCM, EditorState as EditorStateCM } from '@codemirror/basic-setup';
import { basicSetup } from '@codemirror/basic-setup';
import { javascript } from '@codemirror/lang-javascript';
import { EditorView } from 'prosemirror-view';

import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { data } from './exampleData';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { AppConfig } from '@app/core/services/app-config';
import { APP_CONFIG } from '@core/services/app-config';

export var pageDimensionsInPT = {
  '4A0': [4767.87, 6740.79],
  '2A0': [3370.39, 4767.87],
  A0: [2383.94, 3370.39],
  A1: [1683.78, 2383.94],
  A2: [1190.55, 1683.78],
  A3: [841.89, 1190.55],
  A4: [595.28, 841.89],
  A5: [419.53, 595.28],
  A6: [297.64, 419.53],
  A7: [209.76, 297.64],
  A8: [147.4, 209.76],
  A9: [104.88, 147.4],
  A10: [73.7, 104.88],
  B0: [2834.65, 4008.19],
  B1: [2004.09, 2834.65],
  B2: [1417.32, 2004.09],
  B3: [1000.63, 1417.32],
  B4: [708.66, 1000.63],
  B5: [498.9, 708.66],
  B6: [354.33, 498.9],
  B7: [249.45, 354.33],
  B8: [175.75, 249.45],
  B9: [124.72, 175.75],
  B10: [87.87, 124.72],
  C0: [2599.37, 3676.54],
  C1: [1836.85, 2599.37],
  C2: [1298.27, 1836.85],
  C3: [918.43, 1298.27],
  C4: [649.13, 918.43],
  C5: [459.21, 649.13],
  C6: [323.15, 459.21],
  C7: [229.61, 323.15],
  C8: [161.57, 229.61],
  C9: [113.39, 161.57],
  C10: [79.37, 113.39],
  RA0: [2437.8, 3458.27],
  RA1: [1729.13, 2437.8],
  RA2: [1218.9, 1729.13],
  RA3: [864.57, 1218.9],
  RA4: [609.45, 864.57],
  SRA0: [2551.18, 3628.35],
  SRA1: [1814.17, 2551.18],
  SRA2: [1275.59, 1814.17],
  SRA3: [907.09, 1275.59],
  SRA4: [637.8, 907.09],
  EXECUTIVE: [521.86, 756.0],
  FOLIO: [612.0, 936.0],
  LEGAL: [612.0, 1008.0],
  LETTER: [612.0, 792.0],
  TABLOID: [792.0, 1224.0],
};

@Component({
  selector: 'app-edit-before-export',
  templateUrl: './edit-before-export.component.html',
  styleUrls: ['./edit-before-export.component.scss'],
})
export class EditBeforeExportComponent implements AfterViewInit {
  elementOuterHtml?: string[];
  elements: Element[] = [];
  sectionsContainers: string[][] = [];
  importantLeafNodes: string[] = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'table',
    'br',
    'img',
    'block-figure',
    'ol',
    'ul',
    'math-display',
    'page-break',
    'form-field-inline-view',
    'form-field-inline',
  ];

  @ViewChild('elementsContainer', { read: ElementRef }) elementsContainer?: ElementRef;
  @ViewChild('spinnerEl', { read: ElementRef }) spinnerEl?: ElementRef;
  @ViewChild('headerPMEditor', { read: ElementRef }) headerPMEditor?: ElementRef;
  @ViewChild('footerPMEditor', { read: ElementRef }) footerPMEditor?: ElementRef;

  pageSize: 'A0' | 'A1' | 'A2' | 'A3' | 'A4' | 'A5' = 'A4';
  data: any;
  readyRendering = new Subject<any>();
  pageMarg = [72, 72, 72, 72];

  margTopControl = new UntypedFormControl(this.pageMarg[0]);
  margRightControl = new UntypedFormControl(this.pageMarg[1]);
  margBottomControl = new UntypedFormControl(this.pageMarg[2]);
  margLeftControl = new UntypedFormControl(this.pageMarg[3]);

  headerPmContainer?: {
    editorID: string;
    containerDiv: HTMLDivElement;
    editorState: EditorState;
    editorView: EditorView;
    dispatchTransaction: any;
  };

  footerPmContainer?: {
    editorID: string;
    containerDiv: HTMLDivElement;
    editorState: EditorState;
    editorView: EditorView;
    dispatchTransaction: any;
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { selected: 'pdf' | 'rtf' | 'msWord' | 'jatsXml' },
    private changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<EditBeforeExportComponent>,
    private http: HttpClient,
    private serviceShare: ServiceShare,
    private ydocService: YdocService,
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.data = data;
  }
  codemirrorJsonEditor?: EditorViewCM;
  @ViewChild('codemirrorJson', { read: ElementRef }) codemirrorJson?: ElementRef;
  fillElementsArray() {
    this.elements = [];
    let loopChildren = (element: HTMLElement) => {
      if (element instanceof HTMLElement && element.tagName) {
        let elTag = element.tagName.toLocaleLowerCase();
        if (this.importantLeafNodes.includes(elTag)) {
          this.elements.push(element);
        } else if (element.childNodes.length > 0) {
          element.childNodes.forEach((child) => {
            loopChildren(child as HTMLElement);
          });
        }
      }
    };
    loopChildren(this.elementsContainer?.nativeElement);
  }

  renderCodeMirrorEditor() {
    let settings = this.ydocService.printMap!.get('pdfPrintSettings');

    if (Object.keys(settings).length == 0) {
      this.ydocService.printMap!.set('pdfPrintSettings', this.pdfSettingsSave);
    } else {
      this.pdfSettingsSave = settings;
    }
    let pdfPrintSettings =
      Object.keys(settings).length > 0
        ? settings
        : this.ydocService.articleData &&
            this.ydocService.articleData.layout &&
            this.ydocService.articleData.layout.settings &&
            this.ydocService.articleData.layout.settings.print_settings
          ? this.ydocService.articleData.layout.settings.print_settings
          : this.pdfSettingsSave;
    if (!settings) {
      this.ydocService.printMap!.set('pdfPrintSettings', pdfPrintSettings);
    }
    this.codemirrorJsonEditor = new EditorViewCM({
      state: EditorStateCM.create({
        doc: `${JSON.stringify(pdfPrintSettings, null, '\t')}`,
        extensions: [basicSetup, javascript()],
      }),

      parent: this.codemirrorJson?.nativeElement,
      /* dispatch:()=>{

      }, */
    });
  }
  renderProsemirrorEditors() {
    let header = this.headerPMEditor?.nativeElement;
    this.headerPmContainer = this.prosemirrorEditorsService.renderSeparatedEditorWithNoSync(
      header,
      'pm-pdf-menu-container',
      'Header should be displayed here.'
    );
    let footer = this.footerPMEditor?.nativeElement;
    this.footerPmContainer = this.prosemirrorEditorsService.renderSeparatedEditorWithNoSync(
      footer,
      'pm-pdf-menu-container',
      'Footer should be displayed here.'
    );
  }

  async ngAfterViewInit() {
    this.renderCodeMirrorEditor();
    this.elementOuterHtml = [];
    this.renderProsemirrorEditors();
  }

  tablepadding = 6;

  // [left, top, right, bottom]

  basicFont = 9;

  pdfSettingsSave: any = {
    nodes: {
      h1: {
        marginTop: 10,
        marginBottom: 10,
        fontSize: 20,
        lineHeight: 1.3,
      },
      h2: {
        marginTop: 20,
        marginBottom: 10,
        fontSize: 15,
      },
      h3: {
        marginTop: 15,
        marginBottom: 10,
        fontSize: 12,
      },
      h4: {
        marginTop: 12,
        marginBottom: 8,
        fontSize: 11,
      },
      h5: {
        marginTop: 9,
        marginBottom: 6,
        fontSize: 10,
      },
      h6: {
        marginTop: 6,
        marginBottom: 4,
        fontSize: 9,
      },
      p: {
        marginTop: 2,
        marginBottom: 5,
        lineHeight: 1.2,
        fontSize: 9,
      },
      table: {
        marginTop: 5,
        marginBottom: 10,
      },
      tableLabel: {
        fontSize: 8,
        marginTop: 3.5,
        marginbottom: 3.5,
      },
      tableHeader: {
        fontSize: 7,
        marginTop: 3.5,
        marginbottom: 3.5,
      },
      tableContent: {
        fontSize: 7,
        marginTop: 3,
        marginbottom: 3,
        marginRight: 2,
        marginLeft: 2,
      },
      tableFooter: {
        fontSize: 7,
        marginTop: 3.5,
        marginbottom: 3.5,
      },
      figureHeader: {
        fontSize: 8,
        marginTop: 2,
      },
      figureContent: {
        fontSize: 7,
      },
      'block-figure': {
        marginTop: 10,
        marginBottom: 10,
      },
      ol: {
        marginTop: 5,
        marginBottom: 10,
        fontSize: 9,
      },
      ul: {
        marginTop: 5,
        marginBottom: 10,
        fontSize: 9,
      },
      'math-display': {
        marginTop: 10,
        marginBottom: 10,
      },
      'form-field': {
        marginTop: 5,
        marginBottom: 5,
        fontSize: 9,
      },
      br: {
        marginTop: 2,
        marginBottom: 2,
      },
      'form-field-inline': {
        marginTop: 2,
        marginBottom: 2,
        fontSize: 11,
      },
      'block-table': {
        marginTop: 5,
        marginBottom: 5,
      },
      'reference-citation-end': {
        marginLeft: 10,
      },
      'end-note': {
        fontSize: 8,
      },
    },
    authorsSection: {
      fontSize: 7,
    },
    corespondentAuthors: {
      fontSize: 6,
    },
    maxFiguresImagesDownscale: '80%',
    maxMathDownscale: '80%',
    pageMargins: {
      marginTop: 72,
      marginRight: 72,
      marginBottom: 72,
      marginLeft: 72,
    },
    pageFormat: {
      A2: false,
      A3: false,
      A4: true,
      A5: false,
    },
    minParagraphLinesAtEndOfPage: 1,
    header: {
      marginTop: 20,
      marginBottom: 15,
      fontSize: 7,
    },
    footer: {
      marginTop: 15,
      marginBottom: 15,
      fontSize: 7,
    },
  };

  closePdfPrintDialog() {
    this.dialogRef.close();
  }
  intervalID: any;
  deg = 0;
  resumeSpinner() {
    (this.spinnerEl!.nativeElement as HTMLImageElement).style.display = 'flex';
    this.intervalID = setInterval(() => {
      this.deg = this.deg + 30;
      if (this.deg == -360) {
        this.deg = 0;
      }
      (this.spinnerEl!.nativeElement.firstChild as HTMLImageElement).style.webkitTransform =
        'rotate(' + this.deg + 'deg)';
      //@ts-ignore
      (this.spinnerEl!.nativeElement.firstChild as HTMLImageElement).style.mozTransform =
        'rotate(' + this.deg + 'deg)';
      //@ts-ignore
      (this.spinnerEl!.nativeElement.firstChild as HTMLImageElement).style.msTransform =
        'rotate(' + this.deg + 'deg)';
      //@ts-ignore
      (this.spinnerEl!.nativeElement.firstChild as HTMLImageElement).style.oTransform =
        'rotate(' + this.deg + 'deg)';
      //@ts-ignore
      (this.spinnerEl!.nativeElement.firstChild as HTMLImageElement).style.transform =
        'rotate(' + this.deg + 'deg)';
    }, 100);
  }

  stopSpinner = () => {
    if (this.intervalID) {
      (this.spinnerEl!.nativeElement as HTMLImageElement).style.display = 'none';

      clearInterval(this.intervalID);
      this.intervalID = undefined;
    }
  };

  mathObj: any = {};

  fillSettings() {
    let oldSettings = JSON.parse(JSON.stringify(this.pdfSettingsSave));
    let settings: any;
    let buildNodeSettings = (settingsFromUser: any) => {
      let nodeSettings: any;
      nodeSettings = JSON.parse(JSON.stringify(settingsFromUser.nodes));
      return nodeSettings;
    };
    let buildPdfSettings = (settingsFromUser: any) => {
      let pdfSettings: any = {};
      pdfSettings.maxFiguresImagesDownscale = settingsFromUser.maxFiguresImagesDownscale;
      pdfSettings.maxMathDownscale = settingsFromUser.maxMathDownscale;
      pdfSettings.minParagraphLinesAtEndOfPage = settingsFromUser.minParagraphLinesAtEndOfPage;
      pdfSettings.header = settingsFromUser.header;
      pdfSettings.footer = settingsFromUser.footer;
      pdfSettings.pageMargins = settingsFromUser.pageMargins;
      pdfSettings.nodes = settingsFromUser.nodes;
      Object.keys(settingsFromUser.pageFormat).forEach((format) => {
        if (settingsFromUser.pageFormat[format]) {
          pdfSettings.pageFormat = format;
        }
      });
      return pdfSettings;
    };
    let buildSettings = (settingsFromUser: any) => {
      let settings: any = {};
      //nodes settings
      let nodesSettings = buildNodeSettings(settingsFromUser);
      //other pdf settings
      let pdfSettings = buildPdfSettings(settingsFromUser);
      settings.nodes = nodesSettings;
      settings.pdf = pdfSettings;
      return settings;
    };
    try {
      let data = JSON.parse(
        this.codemirrorJsonEditor!.state.doc.sliceString(
          0,
          this.codemirrorJsonEditor!.state.doc.length
        )
      );
      //this.pdfSettingsSave = data

      settings = buildSettings(data);
      this.ydocService.printMap!.set('pdfPrintSettings', data);
    } catch (e) {
      console.error(e);
      //this.pdfSettingsSave = oldSettings
      settings = buildSettings(oldSettings);
    }
    return settings;
  }

  renderPdf() {
    let headerPmNodesJson = this.headerPmContainer.editorView.state.doc.toJSON();
    let footerPmNodesJson = this.footerPmContainer.editorView.state.doc.toJSON();
    let pdfSettings: any = this.fillSettings();
    let articleId = this.ydocService.articleData.uuid;
    //https://ps-article-storage.dev.scalewest.com/api/article/dfc43b3b-4700-4234-b398-bd9bec17db0d
    let articleData = getYdocData(this.ydocService.ydoc);

    // let articleData:any = {};
    articleData.pdfSettings = pdfSettings;
    articleData.headerPmNodesJson = headerPmNodesJson;
    articleData.footerPmNodesJson = footerPmNodesJson;
    /* this.http.post('/proxy123/asd',articleData).subscribe((data)=>{
      this.serviceShare.NotificationsService.newNotificationEvent({
        event:'A new pdf has been rendered. Click for view.',date:Date.now(),eventId:uuidv4(),
      })
    }) */
    /* this.http.post(environment.print_pdf+'/'+articleId+'/pdf/export',articleData).subscribe((data)=>{
      console.log('response for pdf render ',data);
    }) */
    this.http
      .post(`${this.config.apiUrl}/articles/items/` + articleId + '/pdf/export', articleData)
      .subscribe((data) => {
        console.log('pdf', data);
      });
    /* http://127.0.0.1:3003 */
    //  console.log(Object.keys(articleData));
    // this.http.post('http://127.0.0.1:3003/article/pdf',articleData).subscribe((data)=>{
    //   console.log('pdf',data);
    // })
    /* this.http.post('http://127.0.0.1:3003/article/create',articleData).subscribe((data)=>{
      this.serviceShare.NotificationsService.newNotificationEvent({
        event:'A new pdf has been rendered. Click for view.',date:Date.now(),eventId:uuidv4(),
      })
    }) */
    /* 'proxy/api/article/' */
    /* this.http.post('http://localhost:8080/api/article/'+articleId,{headerPmNodesJson,footerPmNodesJson,pdfSettings}).subscribe((articleData:any)=>{
    }) */

    /* this.http.post('http://localhost:3030/print/pdf/'+articleId,{headerPmNodesJson,footerPmNodesJson,pdfSettings}).subscribe((data:any)=>{
      let downloadlonk = data.downloadlink;
      this.serviceShare.NotificationsService.newNotificationEvent({
        event:'A new pdf has been rendered. Click for view.',date:Date.now(),eventId:uuidv4(),
        downloadlonk
      })
    }) */
    this.dialogRef.close();
  }
}
