import { Injectable } from '@angular/core';
import { TreeService } from '@app/editor/meta-data-tree/tree-service/tree.service';
import { TaxonImportStateService } from '../taxon-import-state/taxon-import-state.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleSectionsService } from '@app/core/services/article-sections.service';
import { YdocService } from '../../ydoc.service';
import { FormGroup } from '@angular/forms';
import { uuidv4 } from 'lib0/random';
import { material } from '@app/core/services/custom_sections/material';
import { ArticleSection } from '@app/editor/utils/interfaces/articleSection';
import {
  getFilteredSectionChooseData,
  getSectionBasicStructure,
} from '@app/editor/utils/articleBasicStructure';
import { TaxonTreatment } from '../taxon-treatment.models';
import { Section } from '@app/core/models/article.models';

@Injectable({
  providedIn: 'root',
})
export class TaxonImportService {
  taxonTreatmentsData: Record<string, TaxonTreatment> = {};

  constructor(
    private treeService: TreeService,
    private ydocService: YdocService,
    private articleSectionsService: ArticleSectionsService,
    private stateService: TaxonImportStateService
  ) {}

  async delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  isValidTaxonTreatment(entry: TaxonTreatment): boolean {
    const hasValidTaxa = Object.keys(entry.taxa).length > 0;
    const hasValidMaterials = entry.materials.length > 0;
    const hasValidExternalLinks = entry.externalLinks.length > 0;

    return hasValidTaxa || hasValidMaterials || hasValidExternalLinks;
  }

  async executeRenderFunctions(functions: (() => void)[]): Promise<void> {
    for (const fn of functions) {
      fn();
    }
  }

  retrieveSectionTemplate(sectionID: string): Observable<Section | null> {
    const sectionNode = this.treeService.findNodeById(sectionID);
    const filteredSections = getFilteredSectionChooseData(sectionNode, this.treeService);

    if (!filteredSections || filteredSections.length < 1) {
      return of(null);
    }

    const taxonSections = filteredSections.filter((sec) => sec.secname === 'Taxon');

    if (!taxonSections || taxonSections.length < 1) {
      return of(null);
    }

    const taxonSectionFromBackend = taxonSections.find((taxonSec) => taxonSec.source === 'backend');
    const taxonSectionFromTemplate = taxonSections.find(
      (taxonSec) => taxonSec.source === 'template'
    );

    if (taxonSectionFromTemplate) {
      return of(taxonSectionFromTemplate.template);
    }

    if (taxonSectionFromBackend) {
      return this.articleSectionsService.getSectionById(taxonSectionFromBackend.id).pipe(
        map((res: { data: Section }) => {
          return res.data;
        })
      );
    }

    return of(null);
  }

  async addTaxonTreatments(
    taxonTreatmentsData: Record<string, TaxonTreatment>,
    sectionID: string,
    sectionTemplate: Section
  ): Promise<void> {
    const taxonTreatments = Object.values(taxonTreatmentsData);
    this.stateService.setTotalTaxons(taxonTreatments.length);

    const taxonSections: ArticleSection[] = [];
    const afterRenderFunctions: (() => void)[] = [];
    const customPropsObj = this.ydocService.customSectionProps.get('customPropsObj');

    for (const taxon of taxonTreatments) {
      try {
        const taxonSection = this.treeService.addNodeAtPlace(
          sectionID,
          JSON.parse(JSON.stringify(sectionTemplate)),
          'end'
        );

        customPropsObj[taxonSection.sectionID] = taxon.taxa;
        taxonSection.defaultFormIOValues = taxon.taxa;
        this.ydocService.articleSectionsMap.set(taxonSection.sectionID, taxonSection);

        const materialsSection = taxonSection.children.find(
          (section) => section.title.name === '[MM] Materials'
        );
        const externalLinksSection = taxonSection.children.find(
          (section) => section.title.name === '[MM] External Links'
        );

        afterRenderFunctions.push(() => this.treeService.renderTaxons(taxonSection, taxon.taxa));

        if (taxon.materials.length > 0 && materialsSection) {
          afterRenderFunctions.push(this.addMaterialsData(taxon.materials, materialsSection));
        }

        if (taxon.externalLinks.length > 0 && externalLinksSection) {
          customPropsObj[externalLinksSection.sectionID] = { externalLinks: taxon.externalLinks };
          externalLinksSection.defaultFormIOValues = { externalLinks: taxon.externalLinks };
          this.ydocService.articleSectionsMap.set(
            externalLinksSection.sectionID,
            externalLinksSection
          );
          afterRenderFunctions.push(() =>
            this.treeService.renderExternalLinks(externalLinksSection, taxon.externalLinks)
          );
        }
        this.ydocService.customSectionProps.set('customPropsObj', customPropsObj);
        taxonSections.push(taxonSection);
        this.stateService.updateProgress(taxonSections.length, taxonTreatments.length);
        await this.delay(10); // prevent UI freezing
      } catch (error) {
        console.error('Error processing taxon:', taxon, error);
      }
    }

    afterRenderFunctions.push(() => this.stateService.finishImport());
    this.treeService.setArticleSectionStructureFlat();
    this.stateService.triggerRendering();

    this.treeService.treeVisibilityChange.next({
      action: 'importTaxonsChange',
      parentContainerID: sectionID,
      taxonSections,
    });

    await this.delay(10); // prevent UI freezing

    this.executeRenderFunctions(afterRenderFunctions);
  }

  addMaterialsData(materialsData: Record<string, unknown>[], materialSection: ArticleSection) {
    const materialSectionsData = [];

    for (const row of materialsData) {
      const materialData = JSON.parse(JSON.stringify(material));
      materialData.mode = '';
      materialData.active = false;
      materialData.defaultFormIOValues = row;
      materialData.sectionID = uuidv4();
      materialData.label = row.typeStatus;
      materialData.parentId = materialSection.sectionID;
      materialSectionsData.push(materialData);
    }

    const { data, nodeForm } = this.treeService.orderMaterialSections(
      materialSectionsData,
      new FormGroup({})
    );
    materialSection.defaultFormIOValues = data;

    const newNodes = [];
    materialSectionsData.forEach((sec: unknown) => {
      const newNode = this.treeService.addNodeAtPlace(
        materialSection.sectionID,
        sec,
        'end'
      ) as ArticleSection;
      this.ydocService.articleSectionsMap.set(newNode.sectionID, newNode);
      materialSection.children.push(newNode);
      this.ydocService.saveSectionMenusAndSchemasDefs([
        getSectionBasicStructure(this.ydocService)(newNode),
      ]);
      newNodes.push(newNode);
    });

    this.ydocService.articleSectionsMap.set(materialSection.sectionID, materialSection);
    return () => this.treeService.renderMaterials(materialSection, nodeForm);
  }
}
