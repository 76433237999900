import { Inject, Injectable } from '@angular/core';
import { merge, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ArticlesService } from '@app/core/services/articles.service';
import { ArticlePJSInfo, isPJSData, PJSData } from '@app/core/models/pjs.models';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import { NotificationsService } from '@app/layout/widgets/arpha-navigation/notifications/notifications.service';
import { StateName } from '../state-info.models';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { YdocService } from '@app/editor/services/ydoc.service';

@Injectable()
export class StateInfoService {
  private readonly statusRetrievalError =
    'An error occurred, while retrieving document status details!';

  private readonly errorDebounceTime = 3000;
  private lastErrorTime = 0;

  private documentState$: Observable<PJSData | null>;

  constructor(
    private articlesService: ArticlesService,
    private snackBar: SnackbarService,
    private notificationService: NotificationsService,
    private ydocService: YdocService,
    @Inject(APP_CONFIG) readonly appConfig: AppConfig
  ) {
    this.documentState$ = this.watchDocumentState();
  }

  getDocumentState(): Observable<PJSData | null> {
    const isStandalone = this.appConfig.standalone;
    return isStandalone ? of(null) : this.documentState$;
  }

  getDocumentStateName(): Observable<StateName | null> {
    const isStandalone = this.appConfig.standalone;
    return isStandalone ? of(null) : this.documentState$.pipe(map((state) => state?.state_name));
  }

  private getLatestArticleStream(): Observable<ArticlePJSInfo> {
    return this.articlesService.latestRetrievedArticle$;
  }

  private getNotificationStream(): Observable<ArticlePJSInfo> {
    return this.notificationService.articleUpdated$.pipe(
      filter((article) => article.uuid === this.ydocService.articleData.uuid)
    );
  }

  private handleError(error: Error): void {
    const currentTime = Date.now();
    const timeSinceLastError = currentTime - this.lastErrorTime;

    if (timeSinceLastError >= this.errorDebounceTime) {
      console.error('Error fetching document state:', error);
      this.snackBar.error(this.statusRetrievalError);
      this.lastErrorTime = currentTime;
    } else {
      console.log('Error suppressed due to debounce.', error);
    }
  }

  private watchDocumentState(): Observable<PJSData | null> {
    return merge(this.getLatestArticleStream(), this.getNotificationStream()).pipe(
      distinctUntilChanged((previous, current) => {
        return (
          isPJSData(previous?.pjs_data) &&
          isPJSData(current?.pjs_data) &&
          previous?.pjs_data?.state_name === current?.pjs_data?.state_name
        );
      }),
      switchMap((response) => {
        const data = response.pjs_data;
        return isPJSData(data) ? of(data) : throwError('No pjs info');
      }),
      catchError((error) => {
        this.handleError(error);
        return of(null);
      }),
      filter((data) => !!data)
    );
  }
}
