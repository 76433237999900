import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArphaButtonComponent } from '@app/layout/widgets/arpha-button/arpha-button.component';

@NgModule({
  declarations: [ArphaButtonComponent],
  imports: [CommonModule],
  exports: [ArphaButtonComponent],
})
export class SharedModule {}
