import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CasbinGlobalObjectsService } from './casbin/services/casbin-global-objects.service';
import { ProsemirrorEditorsService } from './editor/services/prosemirror-editor/prosemirror-editors.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('globalSpinner', { read: ElementRef }) globalSpinner?: ElementRef;

  constructor(
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private casbinGlobalObjectsService: CasbinGlobalObjectsService // Do not remove as it won't trigger DI! Serious refactoring is needed for different approach
  ) {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready
        .then(function (registration) {
          return registration.sync.register('sendFormData');
        })
        .catch(function () {
          // system was unable to register for a sync,
          // this could be an OS-level restriction
          console.error('sync registration failed');
        });
    }
  }

  ngAfterViewInit(): void {
    this.prosemirrorEditorsService.setSpinner(this.globalSpinner.nativeElement);
  }
}
