import { Injectable } from '@angular/core';
import { EditorView } from 'prosemirror-view';
import { Node, Mark, ResolvedPos } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import { CellSelection } from 'prosemirror-tables';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { YjsHistoryService } from '@app/editor/utils/yjs-history.service';
import { ArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { CITATION_ELEMENTS } from '@app/editor/utils/menu/menu-dialogs';
import { YdocService } from '../../ydoc.service';
import { Store } from '@ngrx/store';
import { selectIsPreviewMode } from '@app/store/edit-mode/edit-mode.selectors';
import { tap } from 'rxjs/operators';

interface Options {
  path?: string;
  allowedTags?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class HandleKeyDownService {
  private isPreviewMode: boolean;

  constructor(
    private serviceShare: ServiceShare,
    private yjsHistoryService: YjsHistoryService,
    private store: Store,
    private ydocService: YdocService
  ) {
    this.store
      .select(selectIsPreviewMode)
      .pipe(tap((previewMode) => (this.isPreviewMode = previewMode)))
      .subscribe();
  }

  public handleKeyDown(options?: Options, section?: ArticleSection) {
    return (view: EditorView, event: KeyboardEvent) => {
      try {
        event.stopPropagation();

        if (
          view.state.selection.from == 2 &&
          view.state.doc.content.size - 2 == view.state.selection.to &&
          section &&
          section.title.name != '[AM] Title'
        ) {
          return true;
        }

        const nodeAtSelection =
          view.state.selection.$head.parent || view.state.selection.$anchor.parent;

        if (
          nodeAtSelection &&
          (nodeAtSelection.content.size == 0 || view.state.selection.from == 2) &&
          event.key == 'Backspace' &&
          view.state.selection.from == view.state.selection.to
        ) {
          //@ts-expect-error parent doesn't exist in the type definitions for the current type
          const tagName = nodeAtSelection.parent?.attrs.tagName;
          if (
            tagName == 'H1' ||
            tagName == 'H2' ||
            tagName == 'H3' ||
            tagName == 'H4' ||
            tagName == 'H5' ||
            tagName == 'H6'
          ) {
            return true;
          }
        }

        if (
          view.state.selection.$from.parent.attrs.contenteditableNode == 'false' ||
          view.state.selection.$from.parent.attrs.contenteditableNode === false ||
          (nodeAtSelection.type.name == 'form_field' && event.key == 'Backspace') ||
          nodeAtSelection?.firstChild?.attrs?.contenteditableNode == 'false' ||
          nodeAtSelection?.firstChild?.attrs?.contenteditableNode === false
        ) {
          return true;
        }

        if (
          view.state.selection.$from.parent.firstChild?.firstChild?.attrs.contenteditableNode ==
            'false' ||
          (view.state.selection.$from.parent.firstChild?.firstChild?.attrs.contenteditableNode ===
            false &&
            view.state.selection.from !== view.state.doc.firstChild?.nodeSize + 1)
        ) {
          return true;
        }

        if (
          event.key == 'ArrowLeft' &&
          view.state.selection.from == view.state.doc.firstChild?.nodeSize + 2
        ) {
          view.focus();
          view.dispatch(
            view.state.tr.setSelection(
              TextSelection.create(view.state.doc, view.state.doc.firstChild?.nodeSize + 1)
            )
          );
          return true;
        }

        if (
          view.state.selection.$from.nodeAfter?.marks?.find((mark: Mark) =>
            CITATION_ELEMENTS.includes(mark.type.name)
          ) &&
          view.state.selection.$from.nodeBefore?.marks?.find((mark: Mark) =>
            CITATION_ELEMENTS.includes(mark.type.name)
          ) &&
          view.state.selection.from == view.state.selection.to
        ) {
          return false;
        }

        if (options?.path == 'tableContent') {
          const pos = view.state.selection.$anchor.pos;
          const { parent } = view.state.doc.resolve(pos);
          if (
            parent.attrs.allowedTags &&
            !event.ctrlKey &&
            !event.metaKey &&
            !event.altKey &&
            !event.shiftKey
          ) {
            event.preventDefault();
            return true;
          }
        }

        const selection = view.state.selection;
        const { $from, $to, from, to } = selection;
        const key = event.key;
        let canEdit = false;
        if (key == 'Tab') {
          if (
            this.yjsHistoryService.undoStack.length > 0 &&
            (this.yjsHistoryService.undoStack[this.yjsHistoryService.undoStack.length - 1].editors
              .length > 0 ||
              this.yjsHistoryService.undoStack[this.yjsHistoryService.undoStack.length - 1]
                .undoItemMeta)
          ) {
            this.yjsHistoryService.startCapturingNewUndoItem();
          }
        }
        if ($from.depth == $to.depth) {
          //@ts-expect-error path property doesn't exist in the type definitions for the current type
          let pathAtFrom: Array<Node | number> = $from.path;
          //@ts-expect-error path property doesn't exist in the type definitions for the current type
          let pathAtTo: Array<Node | number> = $to.path;

          if (selection instanceof CellSelection) {
            //@ts-expect-error path property doesn't exist in the type definitions for the current type
            pathAtFrom = selection.$anchorCell.path;
            //@ts-expect-error path property doesn't exist in the type definitions for the current type
            pathAtTo = selection.$headCell.path;
          }

          let parentRef: Node | undefined;
          //search parents
          for (let i = pathAtTo.length; i > -1; i--) {
            if (i % 3 == 0) {
              const parentFrom = pathAtFrom[i] as Node;
              const parentTo = pathAtTo[i] as Node;
              if (parentFrom == parentTo) {
                if (!parentRef) {
                  parentRef = parentFrom;
                } else if (
                  (!parentRef ||
                    !(
                      parentRef.attrs.contenteditableNode == 'false' ||
                      parentRef.attrs.contenteditableNode === false
                    )) &&
                  parentFrom.type.name == 'form_field' &&
                  parentRef.type.name !== 'form_field' &&
                  (parentRef?.attrs.contenteditableNode != 'false' ||
                    parentRef?.attrs.contenteditableNode !== false)
                ) {
                  parentRef = parentFrom;
                }
              }
            }
          }
          if (
            parentRef?.attrs.contenteditableNode != 'false' &&
            parentRef?.attrs.contenteditableNode !== false
          ) {
            canEdit = true;
          }
        }
        const nodeBeforeHasNoneditableMark =
          selection.$anchor.nodeBefore?.marks.some(
            (mark) =>
              mark.attrs.contenteditableNode === 'false' || mark.attrs.contenteditableNode === false
          ) || false;

        const nodeAfterHasNoneditableMark =
          selection.$anchor.nodeAfter?.marks.some(
            (mark) =>
              mark.attrs.contenteditableNode === 'false' || mark.attrs.contenteditableNode === false
          ) || false;

        let onNoneditableMarkBorder: undefined | 'left' | 'right' = undefined;
        if (nodeBeforeHasNoneditableMark && !nodeAfterHasNoneditableMark && selection.empty) {
          onNoneditableMarkBorder = 'right';
        } else if (
          !nodeBeforeHasNoneditableMark &&
          nodeAfterHasNoneditableMark &&
          selection.empty
        ) {
          onNoneditableMarkBorder = 'left';
        } else if (nodeBeforeHasNoneditableMark && nodeAfterHasNoneditableMark) {
          canEdit = false;
        }
        let firstNodeToTheRight: Node;
        firstNodeToTheRight = selection.$to.nodeAfter;
        let posToTheRight = selection.to;
        while (!firstNodeToTheRight && posToTheRight + 1 < view.state.doc.content.size) {
          posToTheRight = posToTheRight + 1;
          const resolvedNext = view.state.doc.resolve(posToTheRight);
          firstNodeToTheRight = resolvedNext.nodeAfter;
        }
        let figNodeContToTheRight = false;
        if (firstNodeToTheRight && firstNodeToTheRight.type.name == 'figures_nodes_container') {
          figNodeContToTheRight = true;
        }

        if (onNoneditableMarkBorder) {
          if (onNoneditableMarkBorder == 'left') {
            if (key == 'Delete') {
              canEdit = false;
            }
          } else {
            if (key == 'Backspace') {
              canEdit = false;
            }
          }
        }
        if (from !== to) {
          if (
            view.state.selection.$from.nodeAfter?.marks.find((mark: Mark) =>
              CITATION_ELEMENTS.includes(mark.type.name)
            ) &&
            key != 'ArrowLeft' &&
            key != 'ArrowRight' &&
            key != 'ArrowDown' &&
            key != 'ArrowUp'
          ) {
            return true;
          }
        }

        if (key == 'Delete' && figNodeContToTheRight) {
          canEdit = false;
        }
        // check both sides for noneditable marks
        const check = (node: Node): boolean => {
          let returnValue = false;
          if (node) {
            const noneditableMarks = node.marks.filter((mark) => {
              return (
                mark.attrs.contenteditableNode == 'false' ||
                mark.attrs.contenteditableNode === false
              );
            });
            returnValue = noneditableMarks.length > 0;
          }
          return returnValue;
        };
        const noneditableMarkAfterFrom = check($from.nodeAfter!);
        const noneditableMarkBeforeFrom = check($from.nodeBefore!);
        const noneditableMarkAfterTo = check($to.nodeAfter!);
        const noneditableMarkBeforeTo = check($to.nodeBefore!);

        if (
          noneditableMarkAfterFrom &&
          noneditableMarkBeforeFrom &&
          noneditableMarkAfterTo &&
          noneditableMarkBeforeTo
        ) {
          canEdit = false;
        } else if (noneditableMarkAfterFrom && noneditableMarkBeforeFrom) {
          canEdit = false;
        } else if (noneditableMarkAfterTo && noneditableMarkBeforeTo) {
          canEdit = false;
        }

        if (to == from) {
          if (
            noneditableMarkAfterFrom &&
            noneditableMarkAfterTo &&
            !noneditableMarkBeforeFrom &&
            !noneditableMarkBeforeTo
          ) {
            if (key == 'Delete') {
              view.dispatch(
                view.state.tr.setSelection(
                  TextSelection.create(view.state.doc, from, from + $from.nodeAfter.nodeSize)
                )
              );
              canEdit = true;
            } else if (key == 'Backspace') {
              view.dispatch(view.state.tr.setSelection(TextSelection.create(view.state.doc, from)));
              canEdit = true;
            }
          } else if (
            !noneditableMarkAfterFrom &&
            !noneditableMarkAfterTo &&
            noneditableMarkBeforeFrom &&
            noneditableMarkBeforeTo
          ) {
            if (key == 'Delete') {
              view.dispatch(view.state.tr.setSelection(TextSelection.create(view.state.doc, from)));
              canEdit = true;
            } else if (key == 'Backspace') {
              view.dispatch(
                view.state.tr.setSelection(
                  TextSelection.create(view.state.doc, from, from - $from.nodeBefore.nodeSize)
                )
              );
              canEdit = true;
            }
          }
        }

        if (to == from && $from.nodeAfter == null && canEdit == false) {
          const nodeAfterPlusOne = view.state.doc.nodeAt(from + 1);
          if (nodeAfterPlusOne) {
            const resolvedPos = view.state.doc.resolve(from + 1);
            let editableFirstParent = false;
            //@ts-expect-error path property doesn't exist in the type definitions for the current type
            const path = resolvedPos.path;
            for (let i = path.length - 3; i > -1; i -= 3) {
              const parentNode = path[i];
              if (
                !editableFirstParent &&
                !(
                  parentNode.attrs.contenteditableNode === false ||
                  parentNode.attrs.contenteditableNode == 'false'
                )
              ) {
                editableFirstParent = true;
              }
            }
            if (
              nodeAfterPlusOne.isText &&
              editableFirstParent &&
              !(
                key == 'ArrowRight' ||
                key == 'ArrowLeft' ||
                key == 'ArrowDown' ||
                key == 'Backspace' ||
                key == 'ArrowUp'
              )
            ) {
              view.dispatch(
                view.state.tr.setSelection(TextSelection.create(view.state.doc, from + 1))
              );
              canEdit = true;
            }
          }
        }
        const contentEditableNodeAroundPos = (pos: ResolvedPos): boolean => {
          let contenteditable = false;
          let noneditable = false;
          if ($from.nodeBefore && $from.nodeAfter) {
            //@ts-expect-error path property doesn't exist in the type definitions for the current type
            const path = $from.path;
            for (let i = path.length - 3; i > -1; i -= 3) {
              const parentNode = path[i];
              if (
                !noneditable &&
                !contenteditable &&
                !(
                  parentNode.attrs.contenteditableNode === false ||
                  parentNode.attrs.contenteditableNode == 'false'
                )
              ) {
                contenteditable = true;
              }
              if (
                !noneditable &&
                !contenteditable &&
                (parentNode.attrs.contenteditableNode === false ||
                  parentNode.attrs.contenteditableNode == 'false')
              ) {
                noneditable = true;
              }
            }
          } else if (!$from.nodeBefore && $from.nodeAfter && pos.pos - 1 > 0) {
            const posMinusOne = view.state.doc.resolve(pos.pos - 1);

            //@ts-expect-error path property doesn't exist in the type definitions for the current type
            const path = posMinusOne.path;
            for (let i = path.length - 3; i > -1; i -= 3) {
              const parentNode = path[i];
              if (
                !contenteditable &&
                !(
                  parentNode.attrs.contenteditableNode === false ||
                  parentNode.attrs.contenteditableNode == 'false'
                )
              ) {
                contenteditable = true;
              }
              if (
                !noneditable &&
                !contenteditable &&
                (parentNode.attrs.contenteditableNode === false ||
                  parentNode.attrs.contenteditableNode == 'false')
              ) {
                noneditable = true;
              }
            }
          } else if (
            $from.nodeBefore &&
            !$from.nodeAfter &&
            pos.pos + 1 < view.state.doc.content.size
          ) {
            const posPlusOne = view.state.doc.resolve(pos.pos + 1);

            //@ts-expect-error path property doesn't exist in the type definitions for the current type
            const path = posPlusOne.path;
            for (let i = path.length - 3; i > -1; i -= 3) {
              const parentNode = path[i];
              if (
                !contenteditable &&
                !(
                  parentNode.attrs.contenteditableNode === false ||
                  parentNode.attrs.contenteditableNode == 'false'
                )
              ) {
                contenteditable = true;
              }
              if (
                !noneditable &&
                !contenteditable &&
                (parentNode.attrs.contenteditableNode === false ||
                  parentNode.attrs.contenteditableNode == 'false')
              ) {
                noneditable = true;
              }
            }
          }
          return contenteditable;
        };
        if (from != to) {
          const nonEditableParent = (path: unknown[]): boolean => {
            let noneditable = false;
            const counter = path.length - 3;
            for (let i = counter; i > -1; i -= 3) {
              const node = path[i] as Node;
              if (
                node.attrs.contenteditableNode === false ||
                node.attrs.contenteditableNode === 'false'
              ) {
                noneditable = true;
              }
            }
            return noneditable;
          };
          //@ts-expect-error path property doesn't exist in the type definitions for the current type
          const nonEditableNodeAtFrom = nonEditableParent($from.path);
          //@ts-expect-error path property doesn't exist in the type definitions for the current type
          const nonEditableNodeAtTo = nonEditableParent($to.path);
          if (nonEditableNodeAtFrom || nonEditableNodeAtTo) {
            canEdit = false;
          }
        }
        if (
          !canEdit &&
          to !== from &&
          contentEditableNodeAroundPos($from) &&
          contentEditableNodeAroundPos($to)
        ) {
          //view.dispatch(view.state.tr.setSelection(new TextSelection(view.state.doc.resolve(from - 1), view.state.doc.resolve(to + 1))))
          canEdit = true;
        }

        if (section) {
          const nodes = [];
          view.state.doc.nodesBetween(
            view.state.selection.from,
            view.state.selection.to,
            (node) => {
              if (node.type.name == 'heading' || node.type.name == 'form_field') {
                nodes.push(node);
              }
            }
          );

          if (nodes.length == 2) {
            return true;
          }
        }
        if (!canEdit || this.isPreviewMode) {
          if (key == 'ArrowRight' || key == 'ArrowLeft' || key == 'ArrowDown' || key == 'ArrowUp') {
            return false;
          } else {
            return true;
          }
        }

        this.handleCitedTablesAndFiguresViews(view);
      } catch (e) {
        console.error(e);
      }
      if (event.key == 'Backspace') {
        this.ydocService.ydoc.getMap('change').set('change', 'change');
      }
      return false;
    };
  }

  private handleCitedTablesAndFiguresViews(view: EditorView): void {
    const { from, to } = view.state.selection;

    view.state.doc.nodesBetween(from, to, (node) => {
      if (node.type.name === 'example' || node.type.name === 'figure_component') {
        setTimeout(() => {
          this.serviceShare.updateCitableElementsViews();
        }, 10);
      }
    });
  }
}
