import { Injectable } from '@angular/core';
import { ProsemirrorEditorsService } from '../../services/prosemirror-editor/prosemirror-editors.service';
import { YdocService } from '../../services/ydoc.service';
import { ValidationResult } from '../validation-section.models';
import { CitationConfig, CitationMarks, CitationType } from './models';
import { EditorView } from 'prosemirror-view';
import { Node, Mark } from 'prosemirror-model';

/**
 * Service responsible for validating document citations.
 * Handles validation of figures, tables, supplementary files, end notes, and references.
 */
@Injectable({
  providedIn: 'root',
})
export class CitedItemsValidationService {
  /**
   * Maps citation types to their ProseMirror mark configurations.
   * Each configuration defines the mark type and attribute name used in the editor.
   * Mark configurations can be found in src/app/editor/utils/Schema/marks
   */
  private readonly citationConfigs: Record<CitationType, CitationConfig> = {
    figures: { markType: 'citation', attributeName: 'citated_elements' },
    tables: { markType: 'table_citation', attributeName: 'citated_elements' },
    supplementaryFiles: {
      markType: 'supplementary_file_citation',
      attributeName: 'citated_elements',
    },
    endNotes: { markType: 'end_note_citation', attributeName: 'citated_elements' },
    references: { markType: 'reference_citation', attributeName: 'citedRefsIds' },
  };

  constructor(
    private readonly prosemirrorEditorsService: ProsemirrorEditorsService,
    private readonly ydocService: YdocService
  ) {}

  /** Validates all citations and returns validation results by type */
  validateAllCitations(): Record<CitationType, ValidationResult[]> {
    const citations = this.collectAllCitations();

    return {
      figures: this.validateFigures(citations.figures),
      tables: this.validateTables(citations.tables),
      supplementaryFiles: this.validateSupplementaryFiles(citations.supplementaryFiles),
      endNotes: this.validateEndNotes(citations.endNotes),
      references: this.validateReferences(citations.references),
    };
  }

  /**
   * Creates validation results for uncited items
   * @param itemsMap Map of items that should be cited
   * @param citedItems List of actually cited item IDs
   * @param createErrorMessage Function to generate error message
   */
  private createValidationResults(
    itemsMap: Record<string, unknown>,
    citedItems: string[],
    createErrorMessage: (key: string) => string
  ): ValidationResult[] {
    const allItemKeys = Object.keys(itemsMap);
    const uncitedItemKeys = allItemKeys.filter((key) => !citedItems.includes(key));

    return uncitedItemKeys.map((key) => ({
      fulfilled: false,
      errorMessage: createErrorMessage(key),
    }));
  }

  /** Validates figure citations */
  private validateFigures(citedFigures: string[]): ValidationResult[] {
    const figuresMap = this.ydocService.figuresMap?.get('ArticleFigures') || {};
    const figuresNumbers: string[] =
      this.ydocService.figuresMap?.get('ArticleFiguresNumbers') || [];

    const createFigureError = (key: string): string => {
      const figureIndex = figuresNumbers.indexOf(key);
      const figureNumber = figureIndex + 1;
      return `Figure № ${figureNumber} is not cited.`;
    };

    return this.createValidationResults(figuresMap, citedFigures, createFigureError);
  }

  /** Validates table citations */
  private validateTables(citedTables: string[]): ValidationResult[] {
    const tablesMap = this.ydocService.tablesMap?.get('ArticleTables') || {};
    const tablesNumbers: string[] = this.ydocService.tablesMap?.get('ArticleTablesNumbers') || [];

    const createTableError = (key: string): string => {
      const tableIndex = tablesNumbers.indexOf(key);
      const tableNumber = tableIndex + 1;
      return `Table № ${tableNumber} is not cited.`;
    };

    return this.createValidationResults(tablesMap, citedTables, createTableError);
  }

  /** Validates supplementary file citations */
  private validateSupplementaryFiles(citedFiles: string[]): ValidationResult[] {
    const supplementaryFilesMap =
      this.ydocService.supplementaryFilesMap?.get('supplementaryFiles') || {};
    const supplementaryFilesNumbers: string[] =
      this.ydocService.supplementaryFilesMap?.get('supplementaryFilesNumbers') || [];

    const createSupplementaryFileError = (key: string): string => {
      const fileIndex = supplementaryFilesNumbers.indexOf(key);
      const fileNumber = fileIndex + 1;
      return `Supplementary File № ${fileNumber} is not cited.`;
    };

    return this.createValidationResults(
      supplementaryFilesMap,
      citedFiles,
      createSupplementaryFileError
    );
  }

  /** Validates end note citations */
  private validateEndNotes(citedEndNotes: string[]): ValidationResult[] {
    const endNotesMap = this.ydocService.endNotesMap?.get('endNotes') || {};
    const endNotesNumbers: string[] = this.ydocService.endNotesMap?.get('endNotesNumbers') || [];

    const createEndNoteError = (key: string): string => {
      const noteIndex = endNotesNumbers.indexOf(key);
      const noteNumber = noteIndex + 1;
      return `EndNote № ${noteNumber} is not cited.`;
    };

    return this.createValidationResults(endNotesMap, citedEndNotes, createEndNoteError);
  }

  /** Validates reference citations */
  private validateReferences(citedReferences: string[]): ValidationResult[] {
    const refsInEndEditor = this.ydocService.referenceCitationsMap?.get('refsAddedToArticle');

    if (!refsInEndEditor) {
      return [];
    }

    const createReferenceError = (key: string): string => {
      return `Reference "${refsInEndEditor[key].citation.textContent}" is not cited.`;
    };

    return this.createValidationResults(refsInEndEditor, citedReferences, createReferenceError);
  }

  /** Collects all citations from the document's prosemirror editors */
  private collectAllCitations(): CitationMarks {
    const editorContainers = this.prosemirrorEditorsService.editorContainers;
    const citations: CitationMarks = {
      figures: [],
      tables: [],
      supplementaryFiles: [],
      endNotes: [],
      references: [],
    };

    Object.entries(editorContainers)
      .filter(([id]) => id !== 'endEditor')
      .forEach(([, container]) => {
        try {
          this.processEditorCitations(container, citations);
        } catch (error) {
          console.error('Error processing citations in editor:', error);
        }
      });

    return citations;
  }

  /** Process citations from a single editor container */
  private processEditorCitations(
    container: { editorView: EditorView },
    citations: CitationMarks
  ): void {
    container.editorView.state.doc.descendants((node: Node) => {
      if (!node.marks.length) return;

      node.marks.forEach((mark: Mark) => {
        const citationType = this.getCitationType(mark.type.name);
        if (!citationType) return;

        const config = this.citationConfigs[citationType];
        const citedElements = (mark.attrs[config.attributeName] as string[]) || [];
        citations[citationType].push(...citedElements);
      });
    });
  }

  /** Maps ProseMirror mark type name to citation type */
  private getCitationType(markType: string): CitationType | undefined {
    const matchingEntry = Object.entries(this.citationConfigs).find(
      ([, config]) => config.markType === markType
    );

    if (!matchingEntry) {
      return undefined;
    }

    const [citationType] = matchingEntry;
    return citationType as CitationType;
  }
}
