import { ElementRef, Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

import { uuidv4 } from 'lib0/random';

import { CommentsService } from '@app/editor/utils/commentsService/comments.service';
import { SharedCommentsService } from '../shared-comments/shared-comments.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { DetectFocusService } from '@app/editor/utils/detectFocusPlugin/detect-focus.service';
import { Comment } from '../../comment.models';
import { isCommentAllowed } from '@app/editor/utils/menu/menuItems';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { Collaborator } from '@app/core/models/article.models';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editor/prosemirror-editors.service';
import { Subject } from 'rxjs';
import { EditorView } from 'prosemirror-view';

@Injectable()
export class CommentsRenderingService {
  shouldScrollSelected = false;

  rendered = 0;
  nOfCommThatShouldBeRendered = 0;

  notRendered = true;

  addCommentBoxTop: number;
  addCommentBoxH: number;

  addCommentBoxIsAlreadyMoved: boolean;

  lastFocusedEditor: string;
  addCommentEditorId: string; // id of the editor where the Comment button was clicked in the menu

  lastSorted: Comment[];
  commentAllowedIn: { [key: string]: boolean } = {}; // editor id where comment can be made RN equals ''/undefined if there is no such editor RN
  selectedTextInEditors: { [key: string]: string } = {}; // selected text in every editor
  displayedCommentsPositions: { [key: string]: { displayedTop: number; height: number } } = {};

  lastAddBoxHeight = 0;

  showAddCommentBox = false;
  shouldSetNewRows = false;
  tryMoveItemsUp = false;
  initialRender = false;

  errorMessages: { [key: string]: string } = {}; // error messages for editor selections

  doneRenderingComments$: Subject<string> = new Subject();

  newCommentMarkId = uuidv4();

  editorView: EditorView;

  preventRerenderUntilCommentAdd = { bool: false, id: '' };

  constructor(
    private serviceShare: ServiceShare,
    private commentsService: CommentsService,
    private ydocService: YdocService,
    private sharedCommentsService: SharedCommentsService,
    private detectFocus: DetectFocusService,
    private editorsService: ProsemirrorEditorsService
  ) {}

  setInitialCommentsData(): void {
    try {
      this.commentAllowedIn = this.commentsService.commentAllowdIn;
      this.selectedTextInEditors = this.commentsService.selectedTextInEditors;
      this.addCommentEditorId = this.commentsService.addCommentData.sectionName!;
      this.lastFocusedEditor = this.detectFocus.sectionName;
      if (this.lastFocusedEditor) {
        this.editorView = this.editorsService.editorContainers[this.lastFocusedEditor!].editorView;
        this.showAddCommentBox = this.commentsService.addCommentData.showBox;
      }
      this.setLastSelectedCommentSubscription();
      this.setCommentsRenderingSubscription();
    } catch (e) {
      console.error(e);
    }
  }

  setLastSelectedCommentSubscription(): void {
    let timeout: NodeJS.Timeout;
    this.sharedCommentsService.subscription$.add(
      this.commentsService.lastSelectedCommentSubject.subscribe((data) => {
        debugger;
        if (data.commentId && data.commentMarkId && data.sectionId) {
          this.shouldScrollSelected = true;
        } else {
          setTimeout(() => {
            this.doneRendering();
          }, 20);
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.commentsService.buildGlobalCommentMap();
        }, 200);
      })
    );
  }

  setCommentsRenderingSubscription(): void {
    this.sharedCommentsService.subscription$.add(
      this.doneRenderingComments$.subscribe((data) => {
        if (this.rendered < this.nOfCommThatShouldBeRendered) {
          this.rendered++;
        }
        if (data == 'replay_rerender') {
          this.doneRendering('replay_rerender');
          return;
        }
        if (data == 'change_in_comments_in_ydoc') {
          this.doneRendering('change_in_comments_in_ydoc');
        }
        if (data == 'show_more_less_click') {
          this.doneRendering('show_more_less_click');
        }
        if (this.rendered == this.nOfCommThatShouldBeRendered) {
          this.doneRendering();
        }
      })
    );
  }

  setAddedCommentSubscription(commentInput: ElementRef): void {
    debugger;
    this.setContainerHeight();

    this.sharedCommentsService.subscription$.add(
      this.commentsService.addCommentSubject.subscribe((data) => {
        this.lastFocusedEditor = this.detectFocus.sectionName;
        this.editorView = this.editorsService.editorContainers[this.lastFocusedEditor]?.editorView;
        if (!this.lastFocusedEditor || !this.editorView || !this.editorView.state) return;

        if (data.type == 'commentData') {
          this.addCommentEditorId = data.sectionName;

          if (data.showBox) {
            this.addCommentBoxIsAlreadyMoved = false;
          } else {
            this.addCommentBoxIsAlreadyMoved = true;
          }

          setTimeout(() => {
            this.moveAddCommentBox(commentInput);
          }, 200);
          this.showAddCommentBox = data.showBox;
          if (!this.showAddCommentBox && commentInput && commentInput.nativeElement) {
            commentInput.nativeElement.value = '';
          }
        } else if (data.type == 'commentAllownes' && this.addCommentEditorId == data.sectionId) {
          if (this.showAddCommentBox && data.allow == false) {
            this.cancelBtnHandle(commentInput);
          }
          this.commentAllowedIn[data.sectionId] =
            data.allow && isCommentAllowed(this.editorView.state);
          this.selectedTextInEditors[data.sectionId] = data.text;
          this.errorMessages[data.sectionId] = data.errorMessage;
        } else if (this.lastFocusedEditor != this.addCommentEditorId) {
          this.cancelBtnHandle(commentInput);
        }
      })
    );
  }

  setCommentChangeSubscription(): void {
    this.sharedCommentsService.subscription$.add(
      this.commentsService.commentsChangeSubject.subscribe(() => {
        debugger;
        let commentsToAdd: Comment[] = [];
        let commentsToRemove: Comment[] = [];
        let allCommentsInEditors: Comment[] = [];
        let editedComments = false;
        allCommentsInEditors.push(...Object.values(this.commentsService.commentsObj));

        const currUser = this.ydocService.currUser;
        const collaborators = this.ydocService.collaborators
          .get('collaborators')
          .collaborators.filter((c: Collaborator) => c.id != currUser.id);
        let idsThatShouldBeHidden = collaborators
          .filter(
            (c: Collaborator) =>
              c.hide_my_comments_from_user?.includes(currUser?.auth_role) ||
              c.hide_my_comments_from_user?.includes(currUser?.id)
          )
          .map((c: Collaborator) => c.id);
        if (this.serviceShare.hasOwnerCommentsPolicy) {
          idsThatShouldBeHidden = collaborators
            .map((c: Collaborator) => c.id)
            .filter((id: string) => id != currUser?.id);
        }
        Object.values(this.commentsService.commentsObj).forEach((comment) => {
          let displayedCom = this.sharedCommentsService.allComments.find(
            (com) => com.commentAttrs.id == comment.commentAttrs.id
          );

          if (displayedCom && idsThatShouldBeHidden.includes(displayedCom.commentAttrs.userid)) {
            this.sharedCommentsService.allComments = this.sharedCommentsService.allComments.filter(
              (com) => com.commentAttrs.userid != displayedCom.commentAttrs.userid
            );
            return;
          }

          if (displayedCom) {
            if (displayedCom.commentTxt != comment.commentTxt) {
              displayedCom.commentTxt = comment.commentTxt;
              editedComments = true;
            }
            if (displayedCom.domTop != comment.domTop) {
              displayedCom.domTop = comment.domTop;
              editedComments = true;
            }
            if (displayedCom.pmDocEndPos != comment.pmDocEndPos) {
              displayedCom.pmDocEndPos = comment.pmDocEndPos;
              editedComments = true;
            }
            if (displayedCom.pmDocStartPos != comment.pmDocStartPos) {
              displayedCom.pmDocStartPos = comment.pmDocStartPos;
              editedComments = true;
            }
            if (displayedCom.section != comment.section) {
              displayedCom.section = comment.section;
              editedComments = true;
            }
            if (displayedCom.commentMarkId != comment.commentMarkId) {
              displayedCom.commentMarkId = comment.commentMarkId;
              editedComments = true;
            }
            if (displayedCom.selected != comment.selected) {
              displayedCom.selected = comment.selected;
              editedComments = true;
            }
            if (displayedCom.resolved != comment.resolved) {
              displayedCom.resolved = comment.resolved;
              editedComments = true;
            }
            if (displayedCom.commentAttrs.resolved != comment.commentAttrs.resolved) {
              displayedCom.commentAttrs.resolved = comment.commentAttrs.resolved;
              editedComments = true;
            }
            if (
              this.serviceShare.compareObjects(displayedCom.threadComments, comment.threadComments)
            ) {
              displayedCom.threadComments = comment.threadComments;
              editedComments = true;
            }
            if (editedComments) {
              displayedCom.commentAttrs = comment.commentAttrs;
            }
          } else {
            if (!idsThatShouldBeHidden.includes(comment.commentAttrs.userid)) {
              commentsToAdd.push(comment);
            }
          }
        });

        this.sharedCommentsService.allComments.forEach((comment) => {
          if (!allCommentsInEditors.find((com) => com.commentAttrs.id == comment.commentAttrs.id)) {
            commentsToRemove.push(comment);
          }
        });
        if (commentsToAdd.length > 0) {
          // commentsToAdd = commentsToAdd.filter(com => com.)
          this.sharedCommentsService.allComments.push(...commentsToAdd);
          editedComments = true;
          this.rendered = 0;
          this.nOfCommThatShouldBeRendered = commentsToAdd.length;
        }
        if (commentsToRemove.length > 0) {
          while (commentsToRemove.length > 0) {
            let commentToRemove = commentsToRemove.pop();
            let commentIndex = this.sharedCommentsService.allComments.findIndex((com) => {
              this.displayedCommentsPositions[commentToRemove.commentAttrs.id] = undefined;
              return (
                com.commentAttrs.id == commentToRemove.commentAttrs.id &&
                com.section == commentToRemove.section
              );
            });
            this.sharedCommentsService.allComments.splice(commentIndex, 1);
          }
          editedComments = true;
        }
        if (this.shouldScrollSelected) {
          editedComments = true;
        }
        if (editedComments) {
          setTimeout(() => {
            this.doneRendering();
          }, 50);
        }
        if (!editedComments && this.initialRender) {
          this.initialRender = false;
          setTimeout(() => {
            this.doneRendering();
          }, 50);
        }
        if (editedComments) {
          this.setContainerHeight();
        }
        this.sharedCommentsService.changeDetectorRef.detectChanges();
      })
    );
  }

  setContainerHeight(): void {
    let container = document.getElementsByClassName('all-comments-container')[0] as HTMLDivElement;
    let articleElement = document.getElementById('app-article-element') as HTMLDivElement;
    if (!container || !articleElement) {
      return;
    }
    let articleElementRectangle = articleElement.getBoundingClientRect();
    if (container.getBoundingClientRect().height < articleElementRectangle.height) {
      container.style.height = articleElementRectangle.height + 'px';
    }
  }

  cancelBtnHandle(commentInput: ElementRef): void {
    if (commentInput && commentInput.nativeElement) {
      commentInput.nativeElement.value = '';
    }
    this.commentsService.addCommentSubject!.next({
      type: 'commentData',
      sectionName: this.addCommentEditorId,
      showBox: false,
    });
  }

  moveAddCommentBox(commentInput: ElementRef): void {
    this.shouldSetNewRows = true;
    this.lastAddBoxHeight = 0;
    if (!this.showAddCommentBox && !this.addCommentBoxIsAlreadyMoved) {
      this.doneRendering('hide_comment_box');
      if (commentInput && commentInput.nativeElement) {
        commentInput.nativeElement.value = '';
      }
    } else if (!this.addCommentBoxIsAlreadyMoved) {
      this.addCommentBoxIsAlreadyMoved = true;
      this.newCommentMarkId = uuidv4();
      this.doneRendering('show_comment_box');
    }
  }

  doneRendering(cause?: string): void {
    let comments = (
      Array.from(document.getElementsByClassName('comment-container')) as HTMLDivElement[]
    ).sort((a, b) => {
      if (a.style.top && b.style.top) {
        return parseFloat(a.style.top) - parseFloat(b.style.top);
      }
    });
    (document.getElementsByClassName('end-article-spase')[0] as HTMLDivElement).style.minHeight =
      '500px';
    let container = document.getElementsByClassName('all-comments-container')[0] as HTMLDivElement;
    let allCommentCopy: Comment[] = JSON.parse(
      JSON.stringify(this.sharedCommentsService.allComments)
    );
    let sortedComments = allCommentCopy.sort((c1, c2) => {
      if (c1.domTop != c2.domTop) {
        return c1.domTop - c2.domTop;
      } else {
        return c1.pmDocStartPos - c2.pmDocStartPos;
      }
    });
    if ((!container || comments.length == 0) && cause != 'show_comment_box') {
      this.lastSorted = JSON.parse(JSON.stringify(sortedComments));
      return;
    }
    let selectedComment = this.commentsService.lastCommentSelected;
    if (this.notRendered) {
      this.initialRenderComments(sortedComments, comments);
    } else if (!this.notRendered && sortedComments.length > 0) {
      this.haveCommentsButAreNotRendered(
        selectedComment,
        sortedComments,
        cause,
        comments,
        container
      );
    }
    if (
      this.shouldScrollSelected &&
      selectedComment.commentId &&
      selectedComment.commentMarkId &&
      selectedComment.sectionId
    ) {
      let selectedCommentIndex = sortedComments.findIndex((com) => {
        return (
          com.commentAttrs.id == selectedComment.commentId ||
          com.threadComments.find((c) => c.commentAttrs.id == selectedComment.commentId)
        );
      });
      let selectedCommentSorted = sortedComments[selectedCommentIndex];
      let commentContainer = comments.find((element) => {
        return element.classList.contains(selectedCommentSorted?.commentAttrs.id);
      });
      if (commentContainer) {
        this.commentIsSelected(
          selectedCommentSorted,
          commentContainer,
          comments,
          selectedCommentIndex,
          sortedComments
        );
      }
    }
    if (this.showAddCommentBox) {
      this.showAddCommentBoxFunc(sortedComments, comments);
    }
    for (let i = 0; i < comments?.length; i++) {
      const com = comments[i];
      if (com) {
        const commentData = sortedComments[i];
        if (
          (com.getAttribute('resolved') == 'true' ||
            sortedComments[i].commentAttrs.resolved == 'true') &&
          !this.sharedCommentsService.showResolved &&
          !commentData.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          com.style.opacity = '0';
        } else {
          com.style.opacity = '1';
        }
      }
    }
    this.lastSorted = JSON.parse(JSON.stringify(sortedComments));

    this.sharedCommentsService.changeDetectorRef.detectChanges();
  }

  initialRenderComments(sortedComments: Comment[], comContainers: HTMLDivElement[]): void {
    this.notRendered = false;
    let lastElementPosition = 0;
    let i = 0;
    const byCreators = this.sharedCommentsService.sortingFormGroup.get(
      'byCreators'
    ) as UntypedFormArray;
    while (i < sortedComments.length) {
      let com = sortedComments[i];
      if (!this.sharedCommentsService.users.includes(com.commentAttrs.username)) {
        byCreators.push(new UntypedFormControl(false));
        this.sharedCommentsService.users.push(com.commentAttrs.username);
      }
      let id = com.commentAttrs.id;
      let domElement = comContainers[i];
      const isResolved = domElement.getAttribute('resolved');
      let h = domElement.getBoundingClientRect().height;
      if (lastElementPosition < com.domTop) {
        if (
          isResolved == 'true' &&
          !this.sharedCommentsService.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          domElement.style.opacity = '0';
          let pos = com.domTop;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
        } else {
          let pos = com.domTop;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          domElement.style.opacity = '1';
          lastElementPosition = pos + h;
        }
      } else {
        if (
          isResolved == 'true' &&
          !this.sharedCommentsService.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          domElement.style.opacity = '0';
          let pos = lastElementPosition;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
        } else {
          let pos = lastElementPosition;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          domElement.style.opacity = '1';
          lastElementPosition = pos + h;
        }
      }
      i++;
    }
  }

  loopFromTopAndOrderComments(sortedComments: Comment[], comContainers: HTMLDivElement[]): void {
    let lastElementBottom = 0;
    sortedComments.forEach((com, i) => {
      let id = com.commentAttrs.id;
      let domElement = comContainers[i];
      const isResolved = domElement.getAttribute('resolved');
      let h = domElement.getBoundingClientRect().height;
      if (
        !this.displayedCommentsPositions[id] ||
        this.displayedCommentsPositions[id].height != h ||
        com.domTop <= this.displayedCommentsPositions[id].displayedTop
      ) {
        // old and new comment either don't have the same top or comment's height is changed
        if (lastElementBottom < com.domTop) {
          if (
            isResolved == 'true' &&
            !this.sharedCommentsService.showResolved &&
            !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
          ) {
            domElement.style.opacity = '0';
            let pos = com.domTop;
            domElement.style.top = pos + 'px';
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
          } else {
            let pos = com.domTop;
            domElement.style.top = pos + 'px';
            if (!this.sharedCommentsService.searching) {
              domElement.style.opacity = '1';
            }
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
            lastElementBottom = pos + h;
          }
        } else {
          if (
            isResolved == 'true' &&
            !this.sharedCommentsService.showResolved &&
            !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
          ) {
            domElement.style.opacity = '0';
            let pos = lastElementBottom;
            domElement.style.top = pos + 'px';
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
          } else {
            let pos = lastElementBottom;
            domElement.style.top = pos + 'px';
            if (!this.sharedCommentsService.searching) {
              domElement.style.opacity = '1';
            }
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
            lastElementBottom = pos + h;
          }
        }
      } else {
        lastElementBottom =
          this.displayedCommentsPositions[id].displayedTop +
          this.displayedCommentsPositions[id].height;
      }
    });
  }

  loopFromBottomAndOrderComments(
    sortedComments: Comment[],
    comContainers: HTMLDivElement[],
    addComContainer: HTMLDivElement
  ): void {
    let lastCommentTop = addComContainer.getBoundingClientRect().height;
    let i = sortedComments.length - 1;
    while (i >= 0) {
      let com = sortedComments[i];
      let id = com.commentAttrs.id;
      let domElement = comContainers[i];
      const isResolved = domElement.getAttribute('resolved');
      let h = domElement.getBoundingClientRect().height;
      if (
        !this.displayedCommentsPositions[id] ||
        this.displayedCommentsPositions[id].height != h ||
        this.displayedCommentsPositions[id].displayedTop <= com.domTop
      ) {
        // old and new comment either don't have the same top or comment's height is changed
        if (lastCommentTop > com.domTop + h) {
          if (
            isResolved == 'true' &&
            !this.sharedCommentsService.showResolved &&
            !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
          ) {
            domElement.style.opacity = '0';
          } else {
            let pos = com.domTop;
            domElement.style.top = pos + 'px';
            if (!this.sharedCommentsService.searching) {
              domElement.style.opacity = '1';
            }
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
            lastCommentTop = pos;
          }
        } else {
          if (
            isResolved == 'true' &&
            !this.sharedCommentsService.showResolved &&
            !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
          ) {
            domElement.style.opacity = '0';
          } else {
            let pos = lastCommentTop - h;
            domElement.style.top = pos + 'px';
            if (!this.sharedCommentsService.searching) {
              domElement.style.opacity = '1';
            }
            this.displayedCommentsPositions[id] = {
              displayedTop: pos,
              height: h,
            };
            lastCommentTop = pos;
          }
        }
      } else {
        lastCommentTop = this.displayedCommentsPositions[id].displayedTop;
      }
      i--;
    }
  }

  haveCommentsButAreNotRendered(
    selectedComment: {
      commentId?: string;
      pos?: number;
      sectionId?: string;
      commentMarkId?: string;
    },
    sortedComments: Comment[],
    cause: string,
    comments: HTMLDivElement[],
    container: HTMLDivElement
  ) {
    if (
      this.shouldScrollSelected &&
      (!selectedComment.commentId || !selectedComment.commentMarkId || !selectedComment.sectionId)
    ) {
      this.shouldScrollSelected = false;
    }
    let idsOldOrder: string[] = [];

    let oldPos = this.lastSorted.reduce<{ top: number; id: string }[]>((prev, curr) => {
      idsOldOrder.push(curr.commentAttrs.id);
      return [...prev, { top: curr.domTop, id: curr.commentAttrs.id }];
    }, []);

    let idsNewOrder: string[] = [];
    let newPos = sortedComments.reduce<{ top: number; id: string }[]>((prev, curr) => {
      idsNewOrder.push(curr.commentAttrs.id);
      return [...prev, { top: curr.domTop, id: curr.commentAttrs.id }];
    }, []);

    if (this.preventRerenderUntilCommentAdd.bool) {
      let newComId = this.preventRerenderUntilCommentAdd.id;
      if (!idsNewOrder.includes(newComId)) {
        return;
      } else {
        this.preventRerenderUntilCommentAdd.bool = false;
      }
    }
    // determine what kind of change it is
    if (JSON.stringify(oldPos) != JSON.stringify(newPos) || cause || this.tryMoveItemsUp) {
      if (
        JSON.stringify(idsOldOrder) == JSON.stringify(idsNewOrder) ||
        cause ||
        this.tryMoveItemsUp
      ) {
        // comments are in same order
        if (oldPos.length > 0 && oldPos[oldPos.length - 1].top > newPos[newPos.length - 1].top) {
          // comments have decreased top should loop from top
          this.loopFromTopAndOrderComments(sortedComments, comments);
        } else if (
          oldPos.length > 0 &&
          oldPos[oldPos.length - 1].top < newPos[newPos.length - 1].top
        ) {
          // comments have increased top should loop from bottom
          this.loopFromBottomAndOrderComments(sortedComments, comments, container);
        } else if (
          cause == 'hide_comment_box' ||
          cause == 'replay_rerender' ||
          cause == 'change_in_comments_in_ydoc' ||
          cause == 'show_more_less_click'
        ) {
          this.loopFromTopAndOrderComments(sortedComments, comments);
          this.loopFromBottomAndOrderComments(sortedComments, comments, container);
        } else if (this.tryMoveItemsUp) {
          this.loopFromTopAndOrderComments(sortedComments, comments);
          this.tryMoveItemsUp = false;
        } else {
          // moved an existing comment
          this.loopFromBottomAndOrderComments(sortedComments, comments, container);
          this.loopFromTopAndOrderComments(sortedComments, comments);
        }
      } else {
        // comments are not in the same order
        if (idsOldOrder.length < idsNewOrder.length) {
          // added a comment
          let addedCommentId = idsNewOrder.find((commentId) => !idsOldOrder.includes(commentId));
          let sortedComment = sortedComments.find((com) => com.commentAttrs.id == addedCommentId);
          let commentContainer = comments.find((element) => {
            return element.classList.contains(addedCommentId);
          });
          if (commentContainer) {
            commentContainer.style.top = sortedComment.domTop + 'px';
            const resolved = commentContainer.getAttribute('resolved');
            if (
              !this.sharedCommentsService.searching ||
              resolved == 'false' ||
              (resolved == 'true' && this.sharedCommentsService.showResolved)
            ) {
              commentContainer.style.opacity = '1';
            }
            this.displayedCommentsPositions[addedCommentId] = {
              displayedTop: sortedComment.domTop,
              height: commentContainer.getBoundingClientRect().height,
            };
            this.loopFromTopAndOrderComments(sortedComments, comments);
          }
        } else if (idsNewOrder.length < idsOldOrder.length) {
          // removed a comment
          this.loopFromTopAndOrderComments(sortedComments, comments);
          this.loopFromBottomAndOrderComments(sortedComments, comments, container);
        } else if (idsNewOrder.length == idsOldOrder.length) {
          // comments are reordered
          this.initialRenderComments(sortedComments, comments);
        }
      }
    }
  }

  commentIsSelected(
    selectedCommentSorted: Comment,
    commentContainer: HTMLDivElement,
    comments: HTMLDivElement[],
    selectedCommentIndex: number,
    sortedComments: Comment[]
  ) {
    if (selectedCommentSorted.domTop < 80) {
      selectedCommentSorted.domTop = 80;
    }

    commentContainer.style.top = selectedCommentSorted.domTop + 'px';
    this.displayedCommentsPositions[selectedCommentSorted.commentAttrs.id] = {
      displayedTop: selectedCommentSorted.domTop,
      height: commentContainer.getBoundingClientRect().height,
    };

    //loop comments up in the group and move them if any
    let lastCommentTop = selectedCommentSorted.domTop;
    let i = selectedCommentIndex - 1;
    let commentsGroupTopEnd = false;
    while (i >= 0 && !commentsGroupTopEnd) {
      let com = sortedComments[i];
      let id = com.commentAttrs.id;
      let domElement = comments.find((element) => {
        return element.classList.contains(id);
      });
      const isResolved = domElement.getAttribute('resolved');
      let h = domElement.getBoundingClientRect().height;
      if (lastCommentTop > com.domTop + h) {
        if (
          isResolved == 'true' &&
          !this.sharedCommentsService.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          domElement.style.opacity = '0';
          let pos = com.domTop;
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          domElement.style.top = pos + 'px';
        } else {
          let pos = com.domTop;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          lastCommentTop = pos;
        }
      } else {
        if (
          isResolved == 'true' &&
          !this.sharedCommentsService.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          domElement.style.opacity = '0';
          let pos = lastCommentTop - h;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
        } else {
          let pos = lastCommentTop - h;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          lastCommentTop = pos;
        }
      }
      i--;
    }
    let lastElementBottom =
      selectedCommentSorted.domTop + commentContainer.getBoundingClientRect().height;
    let i1 = selectedCommentIndex + 1;
    let n = sortedComments.length;
    let commentsGroupBottomEnd = false;
    while (i1 < n && !commentsGroupBottomEnd) {
      let com = sortedComments[i1];
      let index = i1;
      let id = com.commentAttrs.id;
      let domElement = comments.find((element) => {
        return element.classList.contains(id);
      });
      const isResolved = domElement.getAttribute('resolved');
      let h = domElement.getBoundingClientRect().height;
      if (lastElementBottom < com.domTop) {
        if (
          isResolved == 'true' &&
          !this.sharedCommentsService.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          domElement.style.opacity = '0';
          let pos = com.domTop;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
        } else {
          let pos = com.domTop;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          lastElementBottom = pos + h;
        }
      } else {
        if (
          isResolved == 'true' &&
          !this.sharedCommentsService.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          domElement.style.opacity = '0';
          let pos = lastElementBottom;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
        } else {
          let pos = lastElementBottom;
          domElement.style.top = pos + 'px';
          this.displayedCommentsPositions[id] = {
            displayedTop: pos,
            height: h,
          };
          lastElementBottom = pos + h;
        }
      }
      i1++;
    }
    this.shouldScrollSelected = false;
  }

  showAddCommentBoxFunc(sortedComments: Comment[], comments: HTMLDivElement[]) {
    let addCommentBoxEl = document.getElementsByClassName('add-comment-box')[0] as HTMLDivElement;
    let articleElement = document.getElementById('app-article-element') as HTMLDivElement;
    let editorContainer = document.getElementsByClassName('editor-container')[0] as HTMLDivElement;
    let mainEditorContainer = document.getElementsByClassName(
      'main-editor-container'
    )[0] as HTMLDivElement;
    let editorRectangle = editorContainer.getBoundingClientRect();
    let articleElementRectangle = articleElement.getBoundingClientRect();
    let boxH = addCommentBoxEl.getBoundingClientRect().height;
    let newMarkPos = this.editorView.state.selection.from;
    let domCoords = this.editorView.coordsAtPos(newMarkPos);
    let boxTop = domCoords.top - articleElementRectangle.top - boxH / 2;
    if (boxTop < 0) {
      boxTop = 80;
    }
    this.addCommentBoxTop = boxTop;
    this.addCommentBoxH = boxH;
    addCommentBoxEl.style.top = boxTop + 'px';
    addCommentBoxEl.style.opacity = '1';
    let inputElement = document.getElementsByClassName('comment-input')[0] as HTMLInputElement;
    setTimeout(() => {
      inputElement.focus();
    }, 300);
    setTimeout(() => {
      let scroll = 0;
      if (mainEditorContainer.scrollTop >= 0) {
        scroll = 1;
      }
      if (editorRectangle.height - mainEditorContainer.scrollTop < 0) {
        scroll = -1;
      }
      mainEditorContainer.scrollBy({
        top: scroll,
        behavior: 'smooth',
      });
    }, 200);
    let positionsArr: { id: string; displayedTop: number; height: number }[] = [];
    Object.keys(this.displayedCommentsPositions).forEach((key) => {
      let val = this.displayedCommentsPositions[key];
      if (val) {
        positionsArr.push({ id: key, displayedTop: val.displayedTop, height: val.height });
      }
    });
    positionsArr.sort((a, b) => {
      return a.displayedTop - b.displayedTop;
    });
    let commentsInBox: {
      id: string;
      displayedTop: number;
      height: number;
      posArrIndex: number;
      dir: 'up' | 'down';
    }[] = [];
    let idOfComThatShouldBeBeforeAddBox: string;
    let idOfComThatShouldBeAfterAddBox: string;
    sortedComments.forEach((com) => {
      if (com.domTop < boxTop || (com.domTop == boxTop && com.pmDocStartPos < newMarkPos)) {
        if (
          com.resolved == 'true' &&
          !this.sharedCommentsService.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          // idOfComThatShouldBeAfterAddBox = com.commentAttrs.id;
        } else {
          idOfComThatShouldBeBeforeAddBox = com.commentAttrs.id;
        }
      }
      if (
        !idOfComThatShouldBeAfterAddBox &&
        (com.domTop > boxTop || (com.domTop == boxTop && com.pmDocStartPos > newMarkPos))
      ) {
        if (
          com.resolved == 'true' &&
          !this.sharedCommentsService.showResolved &&
          !com.threadComments.find((c) => c.commentAttrs.resolved == 'false')
        ) {
          // idOfComThatShouldBeAfterAddBox = com.commentAttrs.id;
        } else {
          idOfComThatShouldBeAfterAddBox = com.commentAttrs.id;
        }
      }
    });
    positionsArr.forEach((pos, index) => {
      if (pos.id == idOfComThatShouldBeBeforeAddBox) {
        commentsInBox[0] = { ...pos, posArrIndex: index, dir: 'up' };
      }
      if (pos.id == idOfComThatShouldBeAfterAddBox) {
        commentsInBox[1] = { ...pos, posArrIndex: index, dir: 'down' };
      }
    });
    let newCommentsPos: { displayedTop: number; height: number; id: string }[] = [];
    commentsInBox.forEach((pos) => {
      if (pos.dir == 'up') {
        let offset = boxTop - (pos.displayedTop + pos.height);
        let index = pos.posArrIndex;
        let comTop: number;
        let comBot: number;
        while (
          index >= 0 &&
          (offset < 0 ||
            pos.displayedTop < sortedComments.find((com) => com.commentAttrs.id == pos.id).domTop)
        ) {
          comTop = positionsArr[index].displayedTop;
          comBot = positionsArr[index].displayedTop + positionsArr[index].height;
          let spaceUntilUpperElement =
            index == 0
              ? 0
              : comTop - (positionsArr[index - 1].displayedTop + positionsArr[index - 1].height);
          let newComTop = comTop + offset;
          newCommentsPos[index] = {
            displayedTop: newComTop,
            id: positionsArr[index].id,
            height: positionsArr[index].height,
          };
          offset += spaceUntilUpperElement;
          index--;
        }
      } else {
        let offset = boxH + boxTop - pos.displayedTop;
        let index = pos.posArrIndex;
        let comTop: number;
        let comBot: number;
        let commN = sortedComments.length;
        while (
          index < commN &&
          (offset > 0 ||
            pos.displayedTop > sortedComments.find((com) => com.commentAttrs.id == pos.id).domTop)
        ) {
          comTop = positionsArr[index].displayedTop;
          comBot = positionsArr[index].displayedTop + positionsArr[index].height;
          let spaceUntilLowerElement =
            index == commN - 1 ? 0 : positionsArr[index + 1].displayedTop - comBot;
          let newComTop = comTop + offset;
          newCommentsPos[index] = {
            displayedTop: newComTop,
            id: positionsArr[index].id,
            height: positionsArr[index].height,
          };
          offset -= spaceUntilLowerElement;
          index++;
        }
      }
    });
    newCommentsPos.forEach((pos, i) => {
      let id = pos.id;
      this.displayedCommentsPositions[id] = {
        displayedTop: pos.displayedTop,
        height: pos.height,
      };
      let domElement = comments[i];
      domElement.style.top = this.displayedCommentsPositions[id].displayedTop + 'px';
      const resolved = domElement.getAttribute('resolved');
      if (
        !this.sharedCommentsService.searching ||
        resolved == 'false' ||
        (resolved == 'true' && this.sharedCommentsService.showResolved)
      ) {
        domElement.style.opacity = '1';
      }
    });
  }
}
