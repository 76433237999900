import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { ArticlesService } from '@app/core/services/articles.service';
import { ArticleSectionsService } from '@app/core/services/article-sections.service';
import { YdocService } from '../services/ydoc.service';
import { ProsemirrorEditorsService } from '../services/prosemirror-editor/prosemirror-editors.service';
import { ServiceShare } from '../services/service-share.service';
import { EnforcerService } from '@app/casbin/services/enforcer.service';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { AllUsersService } from '@app/core/services/all-users.service';
import {
  Article,
  ArticleLayout,
  ArticleResponse,
  Collaborator,
  User,
} from '@app/core/models/article.models';
import { HttpResponse } from '@angular/common/http';
import { ResponseWithMetadata, Response } from '@app/core/models/http.models';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import { AuthService } from '@app/core/services/auth.service';
import { JATSImportModalComponent } from '../dialogs/export-options/jatsXML/jats-import-modal/jats-import-modal.component';
import {
  ImportJatsService,
  JatsModule,
} from '../dialogs/export-options/jatsXML/importAsJatsXML.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild('container', { read: ViewContainerRef }) container?: ViewContainerRef;
  @ViewChild(MatSort) sort?: MatSort;

  displayedColumns: string[] = ['id', 'title', 'date', 'layout-type', 'author', 'buttons']; // template-type and last updated - column removed
  data: Article[];
  realData = [];

  timer: number | NodeJS.Timeout;

  subscription = new Subscription();

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  searchValue?: string;
  articleLayouts: ArticleLayout[];
  templateTypeChange: Subject<undefined> = new Subject();
  selectedTemplateType = -1;
  refreshSubject = new Subject();
  onRender = true;
  filteredAutocompleteTemplates: Observable<ArticleLayout[]>;

  colorFunction = this.ydocService.setUserColor;

  templateTypeControl = new UntypedFormControl('');

  constructor(
    public dialog: MatDialog,
    public serviceShare: ServiceShare,
    public enforcer: EnforcerService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private ydocService: YdocService,
    private articlesService: ArticlesService,
    private articleSectionsService: ArticleSectionsService,
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private chDetectionRef: ChangeDetectorRef,
    private allUsersService: AllUsersService,
    private snackBar: SnackbarService,
    private importJatsService: ImportJatsService,
    @Inject(APP_CONFIG) readonly config: AppConfig
  ) {
    this.prosemirrorEditorsService.spinSpinner();
  }

  escapeHtmlTags(articleName: string): string {
    return articleName.replace(this.serviceShare.escapeHtmlTags, '');
  }

  ngAfterViewChecked(): void {
    this.chDetectionRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.prosemirrorEditorsService.containerElement = this.container;
    this.articlesService.replayObservable = null;
    const articlesDataFromResolver = this.route.snapshot.data['product'];
    this.getLayouts();
    // If the user changes the sort order, reset back to the first page.
    this.sort!.sortChange.subscribe(() => {
      this.paginator!.pageIndex = 0;
    });

    this.serviceShare.resetServicesData(true);

    this.templateTypeChange.subscribe(() => {
      this.paginator!.pageIndex = 0;
    });

    this.filteredAutocompleteTemplates = this.templateTypeControl.valueChanges.pipe(
      map((value) =>
        value.length > 0
          ? this.articleLayouts.filter((type) =>
              type.name.toLowerCase().includes(value.toLowerCase())
            )
          : this.articleLayouts
      )
    );

    if (this.serviceShare.ProsemirrorEditorsService.spinning) {
      this.serviceShare.ProsemirrorEditorsService.stopSpinner();
    }
    this.sort?.sort({ disableClear: false, id: 'id', start: 'desc' });

    merge(this.sort!.sortChange, this.paginator!.page, this.templateTypeChange, this.refreshSubject)
      .pipe(
        startWith({}),
        switchMap(() => {
          const params = {
            page: (this.paginator?.pageIndex ?? 0) + 1, // If paginator or pageIndex is undefined, use 0
            pageSize: 7,
          };
          if (this.sort!.active == 'id') {
            params['sort'] = this.sort?.direction == 'desc' ? '-id' : 'id';
          }
          if (this.sort!.active == 'date') {
            params['sort'] = this.sort?.direction == 'desc' ? '-created_at' : 'created_at';
          }
          if (this.searchValue && this.searchValue != '') {
            params['filter[name]'] = this.searchValue;
          }
          if (this.selectedTemplateType != -1) {
            params['filter[layout_id]'] = this.selectedTemplateType;
          }
          this.isLoadingResults = true;
          if (this.onRender) {
            this.onRender = false;
            return of(articlesDataFromResolver);
          }
          return this.articlesService
            .getAllArticles(params)
            .pipe(catchError(() => new Observable(undefined)));
          //}
        }),
        map((data: ResponseWithMetadata<Article[]>) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            throw new Error('No data');
          }

          return data;
        })
      )
      .subscribe({
        next: (data) => {
          const dataToDisplay = data.data;
          const pagination = data.meta.pagination;
          const itemsCount = pagination.total;
          this.data = dataToDisplay;
          this.articlesService.articles = this.data;
          this.resultsLength = itemsCount;
          if (this.prosemirrorEditorsService.spinning) {
            this.prosemirrorEditorsService.stopSpinner();
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
    if (this.serviceShare.shouldOpenNewArticleDialog) {
      this.startManuscript();
      this.serviceShare.shouldOpenNewArticleDialog = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  search(input: HTMLInputElement): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.searchValue = input.value;
      this.templateTypeChange.next();
      this.timer = undefined;
    }, 300);
  }

  filterByTemplateType(input: HTMLInputElement, event?): void {
    if (
      input.value.length > 0 &&
      (event.target.className == 'mat-option-text' ||
        event.target.tagName == 'MAT-OPTION' ||
        event.key == 'Enter')
    ) {
      this.selectedTemplateType = this.articleLayouts.find((type) => type.name == input.value)?.id;
      if (this.selectedTemplateType) {
        this.templateTypeChange.next();
      }
    } else if (
      event.target.tagName !== 'MAT-ICON' &&
      !(event.target.classname && event.target.className.includes('mat-form-field-infix')) &&
      input.value == '' &&
      !event.key
    ) {
      (document.getElementsByClassName('width-select')[0] as HTMLElement).style.width = '125px';
    }
  }

  removeTypeInputText(): void {
    this.templateTypeControl.setValue('');

    if (this.selectedTemplateType !== -1) {
      this.selectedTemplateType = -1;
      this.templateTypeChange.next();
    }
  }

  focusHandler(): void {
    (document.getElementsByClassName('width-select')[0] as HTMLElement).style.width = '240px';
  }

  startManuscript(): void {
    this.serviceShare.createNewArticle();
  }

  edit(article: Article): void {
    this.serviceShare.resetServicesData(true);
    this.articlesService.replayObservable = this.articlesService
      .getArticleByUuid(article.uuid)
      .pipe(shareReplay(1));

    this.articlesService.replayObservable.subscribe((res: ArticleResponse) => {
      this.ydocService.setArticleData(res.data);
      this.router.navigate([article.uuid]);
    });
  }

  delete(article: Article): void {
    this.authService.currentUserSubject
      .pipe(
        switchMap((user) => {
          const collaborator = article.collaborators.find((c) => c.user_id == user.id);
          if (collaborator && collaborator.is_owner) {
            return this.articlesService.deleteArticleById(article.id);
          } else {
            return of(false);
          }
        })
      )
      .subscribe((deleteResponse: HttpResponse<unknown>) => {
        if (deleteResponse && deleteResponse.status == 204) {
          this.refreshSubject.next(deleteResponse);
        } else {
          this.snackBar.error('Error occured deleting resource!', 'Ok');
        }
      });
  }

  // TODO - remove when we have working solution
  // for dynamic enforcement based on the article ID
  canDelete(article: Article, user: User): boolean {
    return article.collaborators.find((c) => c.user_id == user.id)?.is_owner;
  }

  filterContributors(contributors: Collaborator[]): Collaborator[] {
    return contributors.filter((c) => !this.allUsersService.isHidden(c.auth_role));
  }

  private getLayouts(): void {
    this.articleSectionsService.getAllLayouts().subscribe({
      next: (articleLayouts: ResponseWithMetadata<ArticleLayout[]>) => {
        this.articleLayouts = articleLayouts.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  openImportJatsDialog(): void {
    this.subscription.add(
      this.dialog
        .open(JATSImportModalComponent, {
          width: '665px',
          data: { from: JatsModule.dashboard },
          disableClose: false,
        })
        .afterClosed()
        .subscribe((doc: Document) => {
          this.serviceShare.createNewArticleWithDefaultLayout(doc, this.importJatsService);
        })
    );
  }

  importDocx(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files[0] as File;

    const formData = new FormData();
    formData.append('article_id', this.ydocService.roomName);
    formData.append('file', file);

    this.serviceShare.httpClient
      .post(`${this.config.apiUrl}/cdn/v1/upload`, formData)
      .pipe(
        mergeMap((res: { [key: string]: unknown }) => {
          return this.serviceShare.httpClient.post(`${this.config.apiUrl}/articles/import/docx`, {
            docx: res.base_url,
            docx_title: res.original_name,
          });
        })
      )
      .subscribe({
        next: (res: { task_id: string }) => {
          console.log('Created Task:', res.task_id);
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
}
