import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditMode } from './models';
import { YdocService } from '../ydoc.service';
import { ProsemirrorEditorsService } from '../prosemirror-editor/prosemirror-editors.service';
import { DetectFocusService } from '@app/editor/utils/detectFocusPlugin/detect-focus.service';
import { TrackChangesService } from '../track-changes/track-changes.service';
import { EditModeActions, EditModeSelectors } from '@app/store/edit-mode';

@Injectable()
export class EditModeService implements OnDestroy {
  readonly mode$: Observable<EditMode>;
  readonly isEditMode$: Observable<boolean>;
  readonly isSuggestMode$: Observable<boolean>;
  readonly isPreviewMode$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();
  constructor(
    private readonly store: Store,
    private readonly prosemirrorEditorsService: ProsemirrorEditorsService,
    private readonly ydocService: YdocService,
    private readonly detectFocusService: DetectFocusService,
    private readonly trackChangesService: TrackChangesService
  ) {
    this.mode$ = this.store.select(EditModeSelectors.selectCurrentMode);
    this.isEditMode$ = this.store.select(EditModeSelectors.selectIsEditMode);
    this.isSuggestMode$ = this.store.select(EditModeSelectors.selectIsSuggestMode);
    this.isPreviewMode$ = this.store.select(EditModeSelectors.selectIsPreviewMode);

    this.initializeSubscriptions();
  }

  setMode(mode: EditMode | undefined): void {
    // If no mode specified, toggle between suggest and edit
    if (mode === undefined) {
      this.store.dispatch(EditModeActions.toggleEditMode());
      return;
    }

    this.store.dispatch(EditModeActions.setEditMode({ mode }));
    this.handleModeChange(mode);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleModeChange(mode: EditMode): void {
    // Update preview mode state
    this.prosemirrorEditorsService.previewArticleMode.mode = mode === EditMode.preview;

    // Update track changes state if needed
    if (mode === EditMode.suggest) {
      this.trackChangesService.changeInEditors();
    }

    // Update the editor focus
    this.detectFocusService.setSelectionDecorationOnLastSelectedEditor();

    // Update track changes metadata
    const metadata = this.ydocService.trackChangesMetadata?.get('trackChangesMetadata');
    if (metadata) {
      const newMetadata = {
        ...metadata,
        trackTransactions: mode === EditMode.suggest,
        lastUpdateFromUser: this.ydocService.ydoc?.guid,
      };
      this.ydocService.trackChangesMetadata?.set('trackChangesMetadata', newMetadata);
      this.prosemirrorEditorsService.OnOffTrackingChangesShowTrackingSubject.next(newMetadata);
    }
  }

  private initializeSubscriptions(): void {
    // Subscribe to ydoc state changes
    this.ydocService.ydocStateObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ event, data }) => {
        if (event === 'docIsBuild') {
          this.handleDocumentBuild(data);
        }
      });

    // Handle metadata updates
    if (this.ydocService.trackChangesMetadata) {
      this.ydocService.trackChangesMetadata.observe(() => {
        const metadata = this.ydocService.trackChangesMetadata?.get('trackChangesMetadata');
        if (this.shouldUpdateToSuggestMode(metadata)) {
          this.setMode(EditMode.suggest);
        }
      });
    }
  }

  private handleDocumentBuild(data: { versionData: unknown }): void {
    const userSettings = this.ydocService?.currUser?.settings?.init?.editMode;
    if (userSettings) {
      this.setMode(userSettings === 'suggest' ? EditMode.suggest : EditMode.edit);
    }

    if (data.versionData) {
      this.setMode(EditMode.preview);
    }

    const trackChangesMetadata = this.ydocService.trackChangesMetadata?.get('trackChangesMetadata');
    if (trackChangesMetadata?.trackTransactions) {
      this.setMode(EditMode.suggest);
    }
  }

  private shouldUpdateToSuggestMode(metadata: any): boolean {
    return !!(
      metadata?.trackTransactions && metadata.lastUpdateFromUser !== this.ydocService.ydoc?.guid
    );
  }
}
