import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { APP_CONFIG, AppConfig } from '@core/services/app-config';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Article, ArticleResponse, Layout } from '../models/article.models';
import { ResponseWithMetadata } from '../models/http.models';
import { ArticlePJSInfo } from '../models/pjs.models';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService {
  replayObservable: Observable<ArticleResponse> | null;
  articles: Article[];

  private _latestRetrievedArticle$ = new BehaviorSubject<ArticlePJSInfo>(null);

  constructor(
    private http: HttpClient,
    private serviceShare: ServiceShare,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.serviceShare.shareSelf('ArticlesService', this);
  }

  get latestRetrievedArticle$(): Observable<ArticlePJSInfo> {
    return this._latestRetrievedArticle$.pipe(filter((a) => !!a));
  }

  clearLatestRetrievedArticleInfo(): void {
    this._latestRetrievedArticle$.next(null);
  }

  getAllArticles(params: {
    page: number;
    pageSize: number;
    sort?: string;
  }): Observable<ResponseWithMetadata<Article[]>> {
    return this.http.get<ResponseWithMetadata<Article[]>>(`${this.config.apiUrl}/articles/items`, {
      params,
    });
  }

  getArticleByUuid(uuid: string): Observable<ResponseWithMetadata<ArticlePJSInfo>> {
    return this.http
      .get<
        ResponseWithMetadata<ArticlePJSInfo>
      >(`${this.config.apiUrl}/articles/items/uuid/${uuid}`)
      .pipe(tap((res) => this._latestRetrievedArticle$.next(res.data)));
  }

  generatePJSInfo(uuid: string): Observable<ResponseWithMetadata<ArticlePJSInfo>> {
    return this.http.patch<ResponseWithMetadata<ArticlePJSInfo>>(
      `${this.config.apiUrl}/articles/items/uuid/${uuid}`,
      null
    );
  }

  getArticleDomainPolicies(uuid: string): Observable<string[][]> {
    return this.http
      .get<string[][]>(`${this.config.apiUrl}/articles/items/uuid/${uuid}/policies`)
      .pipe(
        tap((r) => {
          this.serviceShare.hasOwnerCommentsPolicy = JSON.stringify(r).includes(
            'comments(*, isCommentOwner())'
          );
          this.serviceShare.YdocService.checkHiddenCommentsAndUsers();
          return r;
        })
      );
  }

  putArticleById(
    articleId: number,
    name: string,
    oldArticleData: Article
  ): Observable<ArticleResponse> {
    if (oldArticleData.name != name) {
      return this.http.put<ArticleResponse>(`${this.config.apiUrl}/articles/items/${articleId}`, {
        name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        updated_at: new Date().toISOString(),
      });
    } else {
      return EMPTY;
    }
  }

  updateArticleUpdatedAt(oldArticleData: Article): Observable<ArticleResponse> {
    oldArticleData.updated_at = new Date().toISOString();
    return this.http.put<ArticleResponse>(
      `${this.config.apiUrl}/articles/items/${oldArticleData.id}`,
      oldArticleData
    );
  }

  deleteArticleById(articleId: number): Observable<HttpResponse<unknown>> {
    return this.http.delete<unknown>(`${this.config.apiUrl}/articles/items/${articleId}`, {
      observe: 'response',
    });
  }

  createArticle(
    name: string,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    layout_id: number
  ): Observable<ArticleResponse> {
    this.clearLatestRetrievedArticleInfo();

    return this.http.post<ArticleResponse>(`${this.config.apiUrl}/articles/items`, {
      name,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      layout_id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ydoc_version: this.serviceShare.ydocVersion,
    });
  }

  getDefaultLayoutArticle(): Observable<{ data: Layout }> {
    this.clearLatestRetrievedArticleInfo();

    return this.http.get<{ data: Layout }>(`${this.config.apiUrl}/layouts/default_for_import`);
  }
}
