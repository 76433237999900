import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditMode } from '@app/editor/services/edit-mode/models';
import { EditModeState } from './models';

export const selectEditModeState = createFeatureSelector<EditModeState>('editMode');

export const selectCurrentMode = createSelector(selectEditModeState, (state) => state.currentMode);

export const selectIsEditMode = createSelector(selectCurrentMode, (mode) => mode === EditMode.edit);

export const selectIsSuggestMode = createSelector(
  selectCurrentMode,
  (mode) => mode === EditMode.suggest
);

export const selectIsPreviewMode = createSelector(
  selectCurrentMode,
  (mode) => mode === EditMode.preview
);
