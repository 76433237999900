import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ChangeData } from '../change.models';
import { TrackChangesService } from '@app/editor/services/track-changes/track-changes.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { SharedChangesService } from '../shared-changes/shared-changes.service';
import { ChangesNavigationService } from '../changes-navigation/changes-navigation.service';

@Injectable()
export class ChangesRenderingService {
  doneRenderingChangesSubject$: Subject<string> = new Subject();

  rendered: number;
  numberOfChangesThatShouldBeRendered: number;
  shouldScrollSelected: boolean;
  initialRender = false;
  notRendered = true;
  shouldTryMoveItemsUp: boolean;
  displayedChangesPositions: { [key: string]: { displayedTop: number; height: number } } = {};

  lastSorted: ChangeData[];

  changeDetector: ChangeDetectorRef;

  constructor(
    private changesService: TrackChangesService,
    private serviceShare: ServiceShare,
    private sharedChangesService: SharedChangesService
  ) {}

  get changesNavigationService(): ChangesNavigationService {
    return this.sharedChangesService.changesNavigationService;
  }

  subscribeForChanges(changeDetector: ChangeDetectorRef): void {
    this.changeDetector = changeDetector;

    this.sharedChangesService.subscription$.add(
      this.doneRenderingChangesSubject$.subscribe(() => {
        if (this.rendered < this.numberOfChangesThatShouldBeRendered) {
          this.rendered++;
        }
        if (this.rendered == this.numberOfChangesThatShouldBeRendered) {
          try {
            setTimeout(() => {
              this.doneRendering();
            }, 300);
          } catch (err) {
            console.error(err);
          }
        }
      })
    );

    let timeout: NodeJS.Timeout;
    this.sharedChangesService.subscription$.add(
      this.changesService.lastSelectedChange$.subscribe((data) => {
        if (data.changeMarkId && data.section && data.pmDocStartPos) {
          this.shouldScrollSelected = true;
        } else {
          this.shouldTryMoveItemsUp = true;
          try {
            setTimeout(() => {
              this.doneRendering();
            }, 200);
          } catch (err) {
            console.error(err);
          }
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.changesService.getChangesInAllEditors();
        }, 200);
      })
    );
  }

  initialRendering(): void {
    this.sharedChangesService.subscription$.add(
      this.changesService.changesChangeSubject$.subscribe((cause?: string) => {
        const changesToAdd: ChangeData[] = [];
        const changesToRemove: ChangeData[] = [];
        const allChangesInEditors: ChangeData[] = [];
        let editedChange = false;
        allChangesInEditors.push(...Object.values(this.changesService.changesObj));
        const usersIdsThatShouldBeHidden =
          this.serviceShare.ProsemirrorEditorsService.userInfo.data['hide_user_from_me'] || [];
        Object.values(this.changesService.changesObj).forEach((incomingChange) => {
          const displayedChange = this.sharedChangesService.allChanges.find(
            (change) => change.changeAttrs.id == incomingChange.changeAttrs.id
          );
          if (
            displayedChange &&
            usersIdsThatShouldBeHidden.includes(displayedChange.changeAttrs.user)
          ) {
            this.sharedChangesService.allChanges = this.sharedChangesService.allChanges.filter(
              (change) => change.changeAttrs.user != displayedChange.changeAttrs.user
            );
            return;
          }

          if (displayedChange) {
            if (displayedChange.changeTxt != incomingChange.changeTxt) {
              displayedChange.changeTxt = incomingChange.changeTxt;
              editedChange = true;
            }
            if (displayedChange.domTop != incomingChange.domTop) {
              displayedChange.domTop = incomingChange.domTop;
              editedChange = true;
            }
            if (displayedChange.pmDocEndPos != incomingChange.pmDocEndPos) {
              displayedChange.pmDocEndPos = incomingChange.pmDocEndPos;
              editedChange = true;
            }
            if (displayedChange.pmDocStartPos != incomingChange.pmDocStartPos) {
              displayedChange.pmDocStartPos = incomingChange.pmDocStartPos;
              editedChange = true;
            }
            if (displayedChange.section != incomingChange.section) {
              displayedChange.section = incomingChange.section;
              editedChange = true;
            }
            if (displayedChange.changeMarkId != incomingChange.changeMarkId) {
              displayedChange.changeMarkId = incomingChange.changeMarkId;
              editedChange = true;
            }
            if (displayedChange.selected != incomingChange.selected) {
              displayedChange.selected = incomingChange.selected;
              editedChange = true;
            }
            if (editedChange) {
              displayedChange.changeAttrs = incomingChange.changeAttrs;
            }
          } else {
            if (!usersIdsThatShouldBeHidden.includes(incomingChange.changeAttrs.user)) {
              changesToAdd.push(incomingChange);
            }
          }
        });

        this.sharedChangesService.allChanges.forEach((change) => {
          if (
            !allChangesInEditors.find((ch) => {
              return ch.changeAttrs.id == change.changeAttrs.id;
            })
          ) {
            changesToRemove.push(change);
          }
        });
        if (changesToAdd.length > 0) {
          this.sharedChangesService.allChanges.push(...changesToAdd);
          this.changeDetector.detectChanges();
          editedChange = true;
          this.rendered = 0;
          this.numberOfChangesThatShouldBeRendered = changesToAdd.length;
        }
        if (changesToRemove.length > 0) {
          while (changesToRemove.length > 0) {
            const changeToRemove = changesToRemove.pop();
            const changeIndex = this.sharedChangesService.allChanges.findIndex((ch) => {
              this.displayedChangesPositions[changeToRemove.changeAttrs.id] = undefined;
              return (
                ch.changeAttrs.id == changeToRemove.changeAttrs.id &&
                ch.section == changeToRemove.section
              );
            });
            this.sharedChangesService.allChanges.splice(changeIndex, 1);
          }
          editedChange = true;
        }
        const renderFunc = (): void => {
          try {
            setTimeout(() => {
              this.doneRendering();
            }, 200);
          } catch (err) {
            console.error(err);
          }
        };
        if (this.shouldScrollSelected) {
          editedChange = true;
        }
        if (editedChange) {
          renderFunc();
        }

        if (!editedChange && this.initialRender) {
          this.initialRender = false;
          renderFunc();
        }
        if (editedChange) {
          this.setContainerHeight();
        }
        if (cause == 'end search') {
          renderFunc();
        }
      })
    );
  }

  setContainerHeight(): void {
    const container = document.getElementsByClassName('all-changes-container')[0] as HTMLDivElement;
    const articleElement = document.getElementById('app-article-element') as HTMLDivElement;
    if (!container || !articleElement) {
      return;
    }
    const articleElementRectangle = articleElement.getBoundingClientRect();
    if (container.getBoundingClientRect().height < articleElementRectangle.height) {
      container.style.height = articleElementRectangle.height + 'px';
    }
  }

  initialRenderChanges(sortedChanges: ChangeData[], chContainers: HTMLDivElement[]): void {
    this.notRendered = false;
    let lastElementPosition = 0;
    let i = 0;
    while (i < sortedChanges.length) {
      const change = sortedChanges[i];
      const id = change.changeMarkId;
      const domElement = chContainers.find((element) => {
        return element.getAttribute('changeid') == id;
      });
      const h = domElement.getBoundingClientRect().height;
      if (lastElementPosition < change.domTop) {
        const pos = change.domTop;
        domElement.style.top = pos + 'px';
        domElement.style.opacity = '1';
        this.displayedChangesPositions[id] = { displayedTop: pos, height: h };
        lastElementPosition = pos + h;
      } else {
        const pos = lastElementPosition;
        domElement.style.top = pos + 'px';
        domElement.style.opacity = '1';
        this.displayedChangesPositions[id] = { displayedTop: pos, height: h };
        lastElementPosition = pos + h;
      }
      i++;
    }
  }

  loopFromTopAndOrderChanges(sortedChanges: ChangeData[], chContainers: HTMLDivElement[]): void {
    let lastElementBottom = 0;
    sortedChanges.forEach((change) => {
      const id = change.changeMarkId;
      const domElement = chContainers.find((element) => {
        return element.getAttribute('changeid') == id;
      });
      const h = domElement.getBoundingClientRect().height;
      if (
        !this.displayedChangesPositions[id] ||
        this.displayedChangesPositions[id].height != h ||
        change.domTop <= this.displayedChangesPositions[id].displayedTop
      ) {
        if (lastElementBottom < change.domTop) {
          const pos = change.domTop;
          domElement.style.top = pos + 'px';
          this.displayedChangesPositions[id] = { displayedTop: pos, height: h };
          lastElementBottom = pos + h;
        } else {
          const pos = lastElementBottom;
          domElement.style.top = pos + 'px';
          this.displayedChangesPositions[id] = { displayedTop: pos, height: h };
          lastElementBottom = pos + h;
        }
      } else {
        lastElementBottom =
          this.displayedChangesPositions[id].displayedTop +
          this.displayedChangesPositions[id].height;
      }
    });
  }

  loopFromBottomAndOrderChanges(
    sortedChanges: ChangeData[],
    chContainers: HTMLDivElement[],
    addChContainer: HTMLDivElement
  ): void {
    let lastChangeTop = addChContainer.getBoundingClientRect().height;
    let i = sortedChanges.length - 1;
    while (i >= 0) {
      const change = sortedChanges[i];
      const id = change.changeMarkId;
      const domElement = chContainers.find((element) => {
        return element.getAttribute('changeid') == id;
      });
      const h = domElement.getBoundingClientRect().height;
      if (
        !this.displayedChangesPositions[id] ||
        this.displayedChangesPositions[id].height != h ||
        this.displayedChangesPositions[id].displayedTop <= change.domTop
      ) {
        if (lastChangeTop > change.domTop + h) {
          const pos = change.domTop;
          domElement.style.top = pos + 'px';
          this.displayedChangesPositions[id] = { displayedTop: pos, height: h };
          lastChangeTop = pos;
        } else {
          const pos = lastChangeTop - h;
          domElement.style.top = pos + 'px';
          this.displayedChangesPositions[id] = { displayedTop: pos, height: h };
          lastChangeTop = pos;
        }
      } else {
        lastChangeTop = this.displayedChangesPositions[id].displayedTop;
      }
      i--;
    }
  }

  getChanges(): ChangeData[] {
    const changes = JSON.parse(
      JSON.stringify(this.sharedChangesService.allChanges)
    ) as ChangeData[];

    if (this.changesNavigationService.searching) {
      const searchedValue = this.changesNavigationService.searchForm.value;

      this.changesNavigationService.searchResults = changes.filter(
        (data) =>
          data.changeTxt.toLocaleLowerCase().includes(searchedValue) ||
          data.changeAttrs.username.toLocaleLowerCase().includes(searchedValue)
      );

      return this.changesNavigationService.searchResults;
    } else {
      return changes;
    }
  }

  doneRendering(cause?: string): void {
    const changes = Array.from(
      document.getElementsByClassName('change-container')
    ) as HTMLDivElement[];
    const container = document.getElementsByClassName('all-changes-container')[0] as HTMLDivElement;
    const allChangesCopy: ChangeData[] = this.getChanges();
    const sortedChanges = allChangesCopy.sort((c1, c2) => {
      if (c1.domTop != c2.domTop) {
        return c1.domTop - c2.domTop;
      } else {
        return c1.pmDocStartPos - c2.pmDocStartPos;
      }
    });
    if (!this.changesNavigationService.searching) {
      this.changesNavigationService.searchResults = sortedChanges;
    }
    if (!container || changes.length == 0) {
      this.lastSorted = JSON.parse(JSON.stringify(sortedChanges));
      return;
    }
    const selectedChange = this.changesService.lastChangeSelected;
    if (this.notRendered) {
      this.initialRenderChanges(sortedChanges, changes);
    } else if (!this.notRendered && sortedChanges.length > 0) {
      if (
        this.shouldScrollSelected &&
        selectedChange.changeMarkId &&
        selectedChange.pmDocStartPos &&
        selectedChange.section
      ) {
        const index = sortedChanges.findIndex(
          (change) => change.changeMarkId == selectedChange.changeMarkId
        );

        if (index >= -1) {
          this.changesNavigationService.searchIndex = index;
          this.changesNavigationService.isSelected = true;
        }
      } else {
        if (
          !selectedChange.changeMarkId ||
          !selectedChange.pmDocStartPos ||
          !selectedChange.section
        ) {
          this.changesNavigationService.searchIndex = 0;
          this.changesNavigationService.isSelected = false;
        }
        this.shouldScrollSelected = false;
      }
      const idsOldOrder: string[] = [];
      const oldPos = this.lastSorted.reduce<{ top: number; id: string }[]>((prev, curr) => {
        idsOldOrder.push(curr.changeMarkId);
        return [...prev, { top: curr.domTop, id: curr.changeMarkId }];
      }, []);
      const idsNewOrder: string[] = [];
      const newPos = sortedChanges.reduce<{ top: number; id: string }[]>((prev, curr) => {
        idsNewOrder.push(curr.changeMarkId);
        return [...prev, { top: curr.domTop, id: curr.changeMarkId }];
      }, []);
      // determine what kind of change it is
      if (JSON.stringify(oldPos) != JSON.stringify(newPos) || cause || this.shouldTryMoveItemsUp) {
        if (
          JSON.stringify(idsOldOrder) == JSON.stringify(idsNewOrder) ||
          cause ||
          this.shouldTryMoveItemsUp
        ) {
          // changes are in same order
          if (oldPos[oldPos.length - 1]?.top > newPos[newPos.length - 1].top) {
            // changes have decreased top should loop from top
            this.loopFromTopAndOrderChanges(sortedChanges, changes);
          } else if (oldPos[oldPos.length - 1]?.top < newPos[newPos.length - 1].top) {
            // changes have increased top should loop from bottom
            this.loopFromBottomAndOrderChanges(sortedChanges, changes, container);
          } else if (cause == 'change_in_comments_in_ydoc') {
            this.loopFromTopAndOrderChanges(sortedChanges, changes);
            this.loopFromBottomAndOrderChanges(sortedChanges, changes, container);
          } else if (this.shouldTryMoveItemsUp) {
            this.loopFromTopAndOrderChanges(sortedChanges, changes);
            this.shouldTryMoveItemsUp = false;
          } else {
            // moved an existing change
            this.loopFromBottomAndOrderChanges(sortedChanges, changes, container);
            this.loopFromTopAndOrderChanges(sortedChanges, changes);
          }
        } else {
          // changes are not in the same order
          if (idsOldOrder.length < idsNewOrder.length) {
            // added a change
            const addedChangeId = idsNewOrder.find((changeId) => !idsOldOrder.includes(changeId));
            const sortedChange = sortedChanges.find(
              (change) => change.changeMarkId == addedChangeId
            );
            const changeContainer = changes.find((element) => {
              return element.getAttribute('changeid') == addedChangeId;
            });

            changeContainer.style.top = sortedChange.domTop + 'px';
            changeContainer.style.opacity = '1';

            this.displayedChangesPositions[addedChangeId] = {
              displayedTop: sortedChange.domTop,
              height: changeContainer.getBoundingClientRect().height,
            };
            this.loopFromTopAndOrderChanges(sortedChanges, changes);
          } else if (idsNewOrder.length < idsOldOrder.length) {
            // removed a change
            this.loopFromTopAndOrderChanges(sortedChanges, changes);
            this.loopFromBottomAndOrderChanges(sortedChanges, changes, container);
          } else if (idsNewOrder.length == idsOldOrder.length) {
            // changes are reordered
            this.initialRenderChanges(sortedChanges, changes);
          }
        }
      }
    }
    if (
      this.shouldScrollSelected &&
      selectedChange.changeMarkId &&
      selectedChange.pmDocStartPos &&
      selectedChange.section
    ) {
      const selectedChangeIndex = sortedChanges.findIndex((change) => {
        return change.changeMarkId == selectedChange.changeMarkId;
      });
      const selectedChangeSorted = sortedChanges[selectedChangeIndex];
      const changeContainer = changes.find((element) => {
        return element.getAttribute('changeid') == selectedChange.changeMarkId;
      });
      if (changeContainer && selectedChangeSorted) {
        changeContainer.style.top = selectedChangeSorted.domTop + 'px';
        this.displayedChangesPositions[selectedChange.changeMarkId] = {
          displayedTop: selectedChangeSorted.domTop,
          height: changeContainer.getBoundingClientRect().height,
        };

        //loop changes up in the group and move them if any
        let lastChangeTop = selectedChangeSorted.domTop;
        let i = selectedChangeIndex - 1;
        const changesGroupTopEnd = false;
        while (i >= 0 && !changesGroupTopEnd) {
          const change = sortedChanges[i];
          const id = change.changeMarkId;
          const domElement = changes.find((element) => {
            return element.getAttribute('changeid') == id;
          });
          const h = domElement.getBoundingClientRect().height;
          if (lastChangeTop > change.domTop + h) {
            const pos = change.domTop;
            domElement.style.top = pos + 'px';
            this.displayedChangesPositions[id] = { displayedTop: pos, height: h };
            lastChangeTop = pos;
          } else {
            const pos = lastChangeTop - h;
            domElement.style.top = pos + 'px';
            this.displayedChangesPositions[id] = { displayedTop: pos, height: h };
            lastChangeTop = pos;
          }
          i--;
        }
        let lastElementBottom =
          selectedChangeSorted.domTop + changeContainer.getBoundingClientRect().height;
        let i1 = selectedChangeIndex + 1;
        const n = sortedChanges.length;
        const changesGroupBottomEnd = false;
        while (i1 < n && !changesGroupBottomEnd) {
          const change = sortedChanges[i1];
          const id = change.changeMarkId;
          const domElement = changes.find((element) => {
            return element.getAttribute('changeid') == id;
          });
          const h = domElement.getBoundingClientRect().height;
          if (lastElementBottom < change.domTop) {
            const pos = change.domTop;
            domElement.style.top = pos + 'px';
            this.displayedChangesPositions[id] = { displayedTop: pos, height: h };
            lastElementBottom = pos + h;
          } else {
            const pos = lastElementBottom;
            domElement.style.top = pos + 'px';
            this.displayedChangesPositions[id] = { displayedTop: pos, height: h };
            lastElementBottom = pos + h;
          }
          i1++;
        }
        this.shouldScrollSelected = false;
      }
    }

    changes.forEach((el) => {
      const change = sortedChanges.find((ch) => ch.changeMarkId == el.getAttribute('changeid'));
      if (change && el.style.opacity == '0') {
        el.style.opacity = '1';
      } else if (!change) {
        el.style.opacity = '0';
      }
    });
    this.lastSorted = JSON.parse(JSON.stringify(sortedChanges));
    this.changeDetector.detectChanges();
  }
}
