import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Mark, Node } from 'prosemirror-model';
import { AllSelection, EditorState, Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { articlePosOffset, commentMarkNames } from '../../utils/commentsService/comments.service';
import {
  ChangeAttrs,
  ChangeData,
  SelectedChange,
  TrackChangesMarkNames,
} from '@app/editor/changes-section/change.models';

const changesMarksNames: string[] = [
  TrackChangesMarkNames.insertion,
  TrackChangesMarkNames.deletion,
];

@Injectable({
  providedIn: 'root',
})
export class TrackChangesService {
  trackChangesPlugin: Plugin;
  trackChangesPluginKey: PluginKey;

  changesObj: { [key: string]: ChangeData } = {};
  changesChangeSubject$: Subject<string> = new Subject();

  lastSelectedChange$: Subject<SelectedChange> = new Subject();

  lastChangeSelected: SelectedChange = {};

  lastSelectedChanges: {
    [key: string]: SelectedChange;
  } = {};

  getChangesTimeout: NodeJS.Timeout;
  updateTimeout: NodeJS.Timeout;
  updateTimestamp: number;

  constructor(private serviceShare: ServiceShare) {
    const self = this;
    serviceShare.shareSelf('TrackChangesService', this);

    this.subscribeForLastSelectedChange();

    const trackChangesPluginKey = new PluginKey('trackChangesPlugin');
    this.trackChangesPluginKey = trackChangesPluginKey;

    this.trackChangesPlugin = new Plugin({
      key: trackChangesPluginKey,
      state: {
        init: (_: any) => {
          return {
            sectionName: _.sectionName,
            createdDecorations: DecorationSet.empty,
            allMatches: undefined,
            editorType: _.editorType ? _.editorType : undefined,
          };
        },
        apply(tr, prev, oldState, newState) {
          const { from, to } = newState.selection;

          const pluginState = { ...prev };

          let selectedAChange = false;

          const changeInSelection = (actualMark: Mark, pos: number): void => {
            if (self.sameAsLastSelectedChange(pos, prev.sectionName, actualMark.attrs.id)) {
              return;
            }
            self.setLastSelectedChange(pos, prev.sectionName, actualMark.attrs.id);
            self.lastSelectedChanges[actualMark.attrs.id] = {
              changeMarkId: actualMark.attrs.id,
              section: prev.sectionName,
              pmDocStartPos: pos,
            };
          };
          const sectionContainer =
            serviceShare.ProsemirrorEditorsService.editorContainers[prev.sectionName];
          const view = sectionContainer ? sectionContainer.editorView : undefined;

          if (!(newState.selection instanceof AllSelection) && view && view.hasFocus()) {
            const node = newState.doc.nodeAt(newState.selection.from);

            let actualMark: Mark;
            let hasOtherMark: boolean;
            let position: number;

            const findMark = (node: Node, pos: number): void => {
              if (node) {
                if (
                  node &&
                  node.marks &&
                  node.marks.find((mark) => commentMarkNames.includes(mark.type.name))
                ) {
                  hasOtherMark = true;
                }

                if (
                  node.marks &&
                  node.marks.length > 0 &&
                  node.marks.find(
                    (mark) =>
                      mark.type.name == TrackChangesMarkNames.insertion ||
                      mark.type.name == TrackChangesMarkNames.deletion
                  )
                ) {
                  actualMark = node.marks.find(
                    (mark) =>
                      mark.type.name == TrackChangesMarkNames.insertion ||
                      mark.type.name == TrackChangesMarkNames.deletion
                  );
                  position = pos;
                }
              }
            };

            findMark(node, from);

            if (!actualMark) {
              newState.doc.nodesBetween(from, to, (node, pos) => {
                findMark(node, pos);
              });
            }

            const selection = view.state.selection;
            const nodeAfterSelection = selection.$to.nodeAfter;
            const nodeBeforeSelection = selection.$from.nodeBefore;

            if (nodeAfterSelection && !actualMark) {
              const pos = selection.to;
              actualMark = nodeBeforeSelection?.marks.find(
                (mark) =>
                  mark.type.name == TrackChangesMarkNames.insertion ||
                  mark.type.name == TrackChangesMarkNames.deletion
              );

              if (actualMark) {
                position = pos;
              }
            }

            if (nodeBeforeSelection && !actualMark) {
              const pos = selection.from - nodeBeforeSelection.nodeSize;
              actualMark = nodeBeforeSelection?.marks.find(
                (mark) =>
                  mark.type.name == TrackChangesMarkNames.insertion ||
                  mark.type.name == TrackChangesMarkNames.deletion
              );

              if (actualMark) {
                position = pos;
              }
            }

            if (!hasOtherMark && nodeBeforeSelection && nodeAfterSelection) {
              hasOtherMark = !!nodeBeforeSelection?.marks.find(
                (mark) => mark.type.name == 'comment'
              );

              if (!hasOtherMark) {
                hasOtherMark = !!nodeAfterSelection?.marks.find(
                  (mark) => mark.type.name == 'comment'
                );
              }
            }
            if (actualMark && !hasOtherMark) {
              changeInSelection(actualMark, position);
              selectedAChange = true;
            }
          }

          if (
            !selectedAChange &&
            !(newState.selection instanceof AllSelection) &&
            view &&
            view.hasFocus() &&
            self.lastChangeSelected.changeMarkId
          ) {
            self.setLastSelectedChange(undefined, undefined, undefined);
          }
          if (!(newState.selection instanceof AllSelection)) {
            self.changeInEditors();
          }

          return pluginState;
        },
      },
      props: {
        decorations: (state) => {
          const pluginState = trackChangesPluginKey.getState(state);
          const focusedEditor = this.serviceShare.DetectFocusService.sectionName;
          const currentEditor = pluginState.sectionName;
          const { from } = state.selection;

          if (currentEditor != focusedEditor) return DecorationSet.empty;

          const markInfo = self.addInlineDecoration(state, from);
          if (!markInfo) return DecorationSet.empty;

          if (markInfo.markName == TrackChangesMarkNames.insertion) {
            return DecorationSet.create(state.doc, [
              Decoration.inline(markInfo.from, markInfo.to, {
                class: 'active-insertion',
              }),
            ]);
          } else if (markInfo.markName == TrackChangesMarkNames.deletion) {
            return DecorationSet.create(state.doc, [
              Decoration.inline(markInfo.from, markInfo.to, {
                class: 'active-deletion',
              }),
            ]);
          }

          return pluginState.createdDecorations;
        },
      },
    });
  }

  updateAllChanges(): void {
    this.getChangesInAllEditors();
  }

  getChangesInAllEditors(): void {
    clearTimeout(this.getChangesTimeout);
    this.getChangesTimeout = setTimeout(() => {
      this.changesObj = {};
      const edCont = this.serviceShare.ProsemirrorEditorsService.editorContainers;
      Object.keys(edCont).forEach((sectionId) => {
        const view = edCont[sectionId].editorView;
        this.getChanges(view, sectionId);
      });
      setTimeout(() => {
        this.changesChangeSubject$.next('changes pos calc for all sections');
      }, 100);
    }, 100);
  }

  getChanges(view: EditorView, sectionId: string): void {
    const doc = view.state.doc;
    const docSize: number = doc.content.size;
    doc.nodesBetween(0, docSize - 1, (node, pos) => {
      const actualMark = node.marks.find((mark) => changesMarksNames.includes(mark.type.name));

      if (actualMark) {
        // should get the top position , the node document position , the section id of this view
        const articleElement = document.getElementById('app-article-element') as HTMLDivElement;
        const articleElementRectangle = articleElement?.getBoundingClientRect();
        const domCoords = view.coordsAtPos(pos);
        let markIsLastSelected = false;

        const selectedChange = this.lastSelectedChanges[actualMark.attrs.id];
        if (selectedChange) {
          if (
            !this.serviceShare.ProsemirrorEditorsService.editorContainers[selectedChange.section]
          ) {
            this.lastSelectedChanges[actualMark.attrs.id] = undefined;
          } else if (
            selectedChange.pmDocStartPos == pos &&
            selectedChange.changeMarkId == actualMark.attrs.id &&
            selectedChange.section == sectionId
          ) {
            markIsLastSelected = true;
          }
        }
        let lastSelected: true | undefined;
        if (
          this.lastChangeSelected.changeMarkId == actualMark.attrs.id &&
          this.lastChangeSelected.section == sectionId &&
          this.lastChangeSelected.pmDocStartPos == pos
        ) {
          lastSelected = true;
        }
        if (lastSelected) {
        }
        if (
          markIsLastSelected ||
          lastSelected ||
          (!(markIsLastSelected || lastSelected) && !this.changesObj[actualMark.attrs.id])
        ) {
          this.changesObj[actualMark.attrs.id] = {
            changeMarkId: actualMark.attrs.id,
            pmDocStartPos: pos,
            pmDocEndPos: pos + node.nodeSize,
            section: sectionId,
            domTop: domCoords?.top - articleElementRectangle?.top - articlePosOffset,
            changeTxt: this.getAllChangeOccurrences(actualMark.attrs.id, view),
            changeAttrs: actualMark.attrs as ChangeAttrs,
            type: actualMark.type.name,
            selected: markIsLastSelected,
          };
        }
      }
    });
  }

  getAllChangeOccurrences(id: string, view: EditorView): string {
    const nodeSize = view.state.doc.content.size;
    let textContent = '';

    view.state.doc.nodesBetween(0, nodeSize, (node: Node) => {
      const actualMark = node?.marks.find((mark) => changesMarksNames.includes(mark.type.name));
      if (actualMark && actualMark.attrs.id == id) {
        textContent += node.textContent;
      }
    });

    return textContent;
  }

  changeInEditors(): void {
    const now = Date.now();
    if (!this.updateTimestamp) {
      this.updateTimestamp = Date.now();
      this.updateAllChanges();
    }
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }

    if (now - this.updateTimestamp > 500) {
      this.updateAllChanges();
    }
    this.updateTimeout = setTimeout(() => {
      this.updateAllChanges();
    }, 500);
  }

  setLastSelectedChange(pmDocStartPos?: number, section?: string, changeMarkId?: string): void {
    this.lastSelectedChange$.next({
      pmDocStartPos,
      section,
      changeMarkId,
    });
  }

  sameAsLastSelectedChange(pos?: number, sectionId?: string, changeMarkIdPrim?: string): boolean {
    if (
      this.lastChangeSelected.changeMarkId != changeMarkIdPrim ||
      this.lastChangeSelected.section != sectionId ||
      this.lastChangeSelected.pmDocStartPos != pos
    ) {
      return false;
    } else {
      return true;
    }
  }

  addInlineDecoration(
    state: EditorState,
    pos: number
  ): {
    from: number;
    to: number;
    markName: string;
  } {
    const node = state.doc.nodeAt(pos);
    if (!node) return;

    const mark = node.marks?.find(
      (mark) =>
        mark.type.name == TrackChangesMarkNames.insertion ||
        mark.type.name == TrackChangesMarkNames.deletion
    );
    if (!mark || node.marks?.find((m) => m.type.name == 'comment')) return;

    let from: number;
    let to: number;

    const nodeSize = state.doc.content.size;
    state.doc.nodesBetween(0, nodeSize, (node, pos) => {
      const mark2 = node?.marks.find((mark) => mark.type.name == mark.type.name);

      if (mark2 && mark2.attrs.id == mark.attrs.id && !from) {
        from = pos;
      }
      if (mark2 && mark2.attrs.id == mark.attrs.id) {
        to = pos + node.nodeSize;
      }
    });
    return { from, to: to || from + node.nodeSize, markName: mark.type.name };
  }

  subscribeForLastSelectedChange(): void {
    this.lastSelectedChange$.subscribe((data) => {
      this.lastChangeSelected.pmDocStartPos = data.pmDocStartPos;
      this.lastChangeSelected.section = data.section;
      this.lastChangeSelected.changeMarkId = data.changeMarkId;
    });
  }

  getTrackChangesPlugin(): Plugin {
    return this.trackChangesPlugin;
  }

  resetTrackChangesService(): void {
    this.changesObj = {};
  }
}
