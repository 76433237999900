import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DragStateService {
  private readonly draggingSubject = new BehaviorSubject<boolean>(false);

  constructor() {}

  public get isDragging(): boolean {
    return this.draggingSubject.value;
  }

  dragStarted = (): void => {
    this.draggingSubject.next(true);
  };

  dragEnded = (): void => {
    this.draggingSubject.next(false);
  };
}
