import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EditorLoadingService {
  private editorsLoaded$ = new BehaviorSubject<boolean>(false);

  get allEditorsLoaded$(): Observable<boolean> {
    return this.editorsLoaded$.pipe(filter((loaded) => loaded));
  }

  setEditorsLoaded(loaded: boolean): void {
    this.editorsLoaded$.next(loaded);
  }
}
