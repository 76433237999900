import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ArticleModeService } from '@app/editor/state-info/article-mode-service/article-mode-service';
import { YdocService } from '@app/editor/services/ydoc.service';

@Component({
  selector: 'awt-article-role',
  templateUrl: './article-role.component.html',
  styleUrls: ['./article-role.component.scss'],
})
export class ArticleRoleComponent implements OnInit {
  userRole$: Observable<string>;
  icon$ = this.articleModeService.icon$;
  details$ = this.articleModeService.details$;
  role$ = this.articleModeService.role$;

  constructor(
    private ydocService: YdocService,
    private articleModeService: ArticleModeService
  ) {}

  ngOnInit(): void {
    this.userRole$ = this.ydocService.currentUserAccess$;
  }
}
