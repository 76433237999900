import { ResponseWithMetadata } from '@app/core/models/http.models';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleSectionsService } from '@app/core/services/article-sections.service';
import { ArticlesService } from '@app/core/services/articles.service';
import { AuthService } from '@app/core/services/auth.service';
import { RefsApiService } from '@app/layout/pages/library/lib-service/refs-api.service';
import { Subject, Subscription, combineLatest, Observable, BehaviorSubject, of } from 'rxjs';
import { debounceTime, filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import * as Y from 'yjs';
import { AddContributorsDialogComponent } from './dialogs/add-contributors-dialog/add-contributors-dialog.component';
import { ExportOptionsComponent } from './dialogs/export-options/export-options.component';
import { ProsemirrorEditorsService } from './services/prosemirror-editor/prosemirror-editors.service';
import { ServiceShare } from './services/service-share.service';
import { YdocService } from './services/ydoc.service';
import { CommentsService } from './utils/commentsService/comments.service';
import { TrackChangesService } from './services/track-changes/track-changes.service';
import { TaxonService } from './taxons/taxon.service';
import { changeVersionSubject } from '../y-prosemirror-src/plugins/sync-plugin.js';
import { JATSImportModalComponent } from './dialogs/export-options/jatsXML/jats-import-modal/jats-import-modal.component';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { CONSTANTS } from '@app/core/services/constants';
import { VersionChange, VersionData } from '@app/core/models/version.models';
import { UserInArticle, UserInfo } from './comments-section/comment.models';
import { PermissionsTuple } from '@app/core/models/permission.models';
import { Journal } from '@app/core/models/journal.models';
import { StateInfoService } from './state-info/state-info-service/state-info.service';
import { StateName } from './state-info/state-info.models';
import { CitableElementsService } from './services/citable-elements.service';
import { CasbinGlobalObjectsService } from '@app/casbin/services/casbin-global-objects.service';
import { JatsModule } from './dialogs/export-options/jatsXML/importAsJatsXML.service';
import { ArticleModeService } from './state-info/article-mode-service/article-mode-service';
import { ArticleUserMode } from './state-info/article-role/article-role.models';
import { EditModeService } from './services/edit-mode/edit-mode.service';
import { EditMode } from './services/edit-mode/models';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EditModeService],
})
export class EditorComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('trackChangesOnOffBtn', { read: ElementRef })
  trackChangesOnOffBtn?: ElementRef;

  @ViewChild(MatDrawer) sidebarDrawer?: MatDrawer;

  @ViewChild('metaDataTreeDrawer') metaDataTreeDrawer?: MatDrawer;

  previewMode;
  shouldBuild: boolean = false;
  active = 'editor';
  articleTemplate: string;
  versionData: VersionData;
  userAccess: string;
  canEdit = false;
  canSuggest = false;
  canSeeVersions = false;
  canSeeSectionsTree = false;
  commentsNumberChange$: Subject<number> = new Subject();
  changesNumberChange$: Subject<number> = new Subject();
  noComments = true;
  noChanges = true;
  canCreateTag = false;
  sidebar = '';
  usersInArticle: UserInArticle[] = [];

  isImportButtonVisible$: Observable<boolean>;

  canShowTaxonButtons$: BehaviorSubject<boolean>;
  areActionButtonsVisible$: Observable<boolean>;
  isCommentAllowed$: Observable<boolean>;

  currentMode$ = this.editModeService.mode$;
  isEditMode$ = this.editModeService.isEditMode$;
  isSuggestMode$ = this.editModeService.isSuggestMode$;
  isPreviewMode$ = this.editModeService.isPreviewMode$;

  private ydoc?: Y.Doc;
  private roomName?: string | null;
  private canSeeComments = false;
  private subscription = new Subscription();
  private unsubscribe$ = new Subject<void>();

  constructor(
    public taxonService: TaxonService,
    public serviceShare: ServiceShare,
    public editModeService: EditModeService,
    private ydocService: YdocService,
    private route: ActivatedRoute,
    private commentService: CommentsService,
    private prosemirrorEditorService: ProsemirrorEditorsService,
    private trackChanges: TrackChangesService,
    private dialog: MatDialog,
    private authService: AuthService,
    private articleSectionsService: ArticleSectionsService,
    private articlesService: ArticlesService,
    private refsAPI: RefsApiService,
    private changeDetection: ChangeDetectorRef,
    private router: Router,
    private stateInfoService: StateInfoService,
    private casbinGlobalService: CasbinGlobalObjectsService,
    // Don't remove that - used to trigger DI for ServiceShare
    private citableElementsService: CitableElementsService,
    private articleModeService: ArticleModeService,
    @Inject(APP_CONFIG) readonly appConfig: AppConfig
  ) {
    this.watchButtonVisibility();
    const enforcer = this.serviceShare.EnforcerService;
    enforcer.policyUpdate$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((action) => action === enforcer.policyUpdateAction)
      )
      .subscribe();

    this.serviceShare.titleControl.disable();
    this.subscription.add(
      this.taxonService.canTagSelection$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((canCreateTag) => {
          this.canCreateTag = canCreateTag;
        })
    );
    this.prosemirrorEditorService.spinSpinner();
    this.previewMode = this.prosemirrorEditorService.previewArticleMode;

    this.subscription.add(
      this.prosemirrorEditorService.usersInArticleStatusSubject
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((status: Map<number, { userInfo: UserInfo }>) => {
          const userInfo = [];

          status.forEach((aw, clientId) => {
            if (aw.userInfo && this.ydoc?.clientID && clientId != this.ydoc.clientID) {
              userInfo.push({ userInfo: aw.userInfo, clientId });
            }
          });

          this.usersInArticle = userInfo;
          this.ydocService.usersInArticle = userInfo;
        })
    );

    this.subscription.add(
      this.serviceShare.TrackChangesService.lastSelectedChange$
        .pipe(takeUntil(this.unsubscribe$), debounceTime(200))
        .subscribe((data) => {
          this.changeDetection.detectChanges();

          if (!data.changeMarkId || !data.pmDocStartPos || !data.section || this.previewMode.mode)
            return;
          const { from, to } =
            this.prosemirrorEditorService.editorContainers[data.section].editorView.state.selection;
          if (from !== to && data.section != this.serviceShare.DetectFocusService.sectionName)
            return;
          if (!this.sidebarDrawer?.opened) {
            this.sidebarDrawer?.toggle();
          }
          if (this.sidebar != 'changes') {
            this.sidebar = 'changes';

            setTimeout(() => {
              this.trackChanges.changesChangeSubject$.next('changes pos calc for all sections');
              setTimeout(() => {
                this.serviceShare.TrackChangesService.lastSelectedChange$.next(data);
              }, 800);
            }, 20);
          }
        })
    );

    this.subscription.add(
      this.taxonService.lastSelectedTaxonMarkSubject
        .pipe(takeUntil(this.unsubscribe$), debounceTime(200))
        .subscribe((data) => {
          this.changeDetection.detectChanges();
          if (!data.pos || !data.sectionId || !data.taxonMarkId || this.previewMode.mode) return;
          if (/*from !== to &&*/ data.sectionId != this.serviceShare.DetectFocusService.sectionName)
            return;
          if (!this.sidebarDrawer?.opened) {
            this.sidebarDrawer?.toggle();
          }
          if (this.sidebar != 'taxons') {
            this.sidebar = 'taxons';
            setTimeout(() => {
              this.taxonService.taxonsMarksObjChangeSubject.next(
                'taxons pos calc for all sections'
              );

              setTimeout(() => {
                this.taxonService.lastSelectedTaxonMarkSubject.next(data);
              }, 800);
            }, 20);
          }
        })
    );

    this.subscription.add(
      this.serviceShare.CommentsService.lastSelectedCommentSubject
        .pipe(takeUntil(this.unsubscribe$), debounceTime(200))
        .subscribe((data) => {
          this.changeDetection.detectChanges();
          if (
            !data.commentId ||
            !data.commentMarkId ||
            !data.pos ||
            !data.sectionId ||
            !this.canSeeComments
          )
            return;
          if (!this.sidebarDrawer?.opened) {
            this.sidebarDrawer?.toggle();
          }
          if (this.sidebar != 'comments') {
            this.sidebar = 'comments';
            setTimeout(() => {
              this.commentService.commentsChangeSubject.next('comments pos calc for all sections');
              setTimeout(() => {
                this.serviceShare.CommentsService.lastSelectedCommentSubject.next(data);
              }, 800);
            }, 20);
          }
        })
    );

    this.subscription.add(
      this.commentService.addCommentSubject.subscribe((data) => {
        this.changeDetection.detectChanges();
        if (data?.type == 'commentData' && this.sidebar !== 'comments' && data.showBox) {
          if (!this.sidebarDrawer?.opened) {
            this.sidebarDrawer?.toggle();
          }
          this.sidebar = 'comments';
          setTimeout(() => {
            this.commentService.addCommentSubject.next(data);
          }, 200);
        }
      })
    );

    this.subscription.add(
      this.taxonService.addTaxonSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
        this.changeDetection.detectChanges();
        if (data?.type == 'taxonData' && this.sidebar !== 'taxons' && data.showTaxonBox) {
          if (!this.sidebarDrawer?.opened) {
            this.sidebarDrawer?.toggle();
          }
          this.sidebar = 'taxons';
          setTimeout(() => {
            this.taxonService.addTaxonSubject.next(data);
          }, 200);
        }
      })
    );

    if (this.ydocService.editorIsBuild) {
      this.initArticleStructureMap();
    } else {
      this.subscription.add(
        this.ydocService.ydocStateObservable
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(({ event }) => {
            this.changeDetection.detectChanges();
            if (event == 'docIsBuild') {
              this.initArticleStructureMap();
            }
          })
      );
    }
  }

  get collaboratorIcon(): string {
    switch (this.ydocService?.currUser?.auth_role) {
      case 'reader': {
        return 'eyeGreen';
      }
      case 'commenter': {
        return 'comments';
      }
      case 'reviewer': {
        return 'comments';
      }
      case 'editor': {
        return 'edit1';
      }
      case 'writer': {
        return 'edit1';
      }
      default:
        return '';
    }
  }

  get canSubmit(): boolean {
    const isOwner = this.ydocService.currUser?.is_owner;
    const isNotStandalone = !this.appConfig.standalone;
    return isOwner && isNotStandalone;
  }

  get canReturnToNewest(): boolean {
    const canGoToLatest =
      !!this.ydocService.currUser?.allowed_article_versions.find((v) => v == CONSTANTS.LATEST) ||
      this.ydocService.currUser?.allowed_article_versions.length == 0;

    return (
      this.serviceShare.oldVersion &&
      (this.canEdit || this.canSuggest) &&
      !this.serviceShare.onlyOldVersions &&
      canGoToLatest
    );
  }

  private get isDraftState$(): Observable<boolean> {
    return this.stateInfoService
      .getDocumentStateName()
      .pipe(map((state: StateName) => state === 'Draft'));
  }

  turnOnOffPreviewMode(): void {
    this.currentMode$.pipe(take(1)).subscribe((currentMode) => {
      const newMode = currentMode === EditMode.preview ? undefined : EditMode.preview;
      this.editModeService.setMode(newMode);
    });
  }

  showTreeContainer(): void {
    this.metaDataTreeDrawer?.toggle();
  }

  ngAfterViewChecked(): void {
    // detect changes only when is needed because of performance
    if (
      this.prosemirrorEditorService.editMode ||
      this.ydocService.creatingANewArticle ||
      this.ydocService.editorIsBuild == false
    ) {
      this.changeDetection.detectChanges();
    }
  }

  clickEditorTab(): void {
    if (this.active == 'library') {
      this.active = 'editor';
    } else {
      this.active = 'editor';
      this.subscription.add(
        this.refsAPI
          .getReferences()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.changeDetection.detectChanges();
          })
      );
    }
  }

  ngOnInit(): void {
    const isStandalone = this.appConfig.standalone;
    this.ydocService.returnToNewestVersion = this.returnToNewestVersion;

    this.isImportButtonVisible$ = isStandalone ? of(true) : this.isDraftState$;

    this.watchPolicyChange();
    this.watchRoleChange();

    const articleData = this.route.snapshot.data['product'].data;

    this.subscription.add(
      combineLatest([this.route.paramMap, this.authService.currentUser$])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ([params, userInfo]) => {
            this.serviceShare.shouldSeeOnlyVersionPreview = false;

            const roomName = params.get('id');
            this.roomName = roomName;

            const [, fragment] = window.location.href.split('#');
            const urlInfo = fragment?.includes('?') ? fragment.split('?')[0] : fragment;

            if (articleData.collaborators.length) {
              const currUser = JSON.parse(
                JSON.stringify(
                  articleData.collaborators.find((c) => c.user_id == userInfo.id) ||
                    this.ydocService.currUser
                )
              );
              this.ydocService.currUser = currUser;
            }

            const onlyAllowedVersions = this.ydocService.currUser?.allowed_article_versions.filter(
              (v: string) => v != CONSTANTS.LATEST && v != CONSTANTS.OWN
            );
            const hasLatest = this.ydocService.currUser?.allowed_article_versions.find(
              (v) => v == CONSTANTS.LATEST
            );
            const hasOwn = this.ydocService.currUser?.allowed_article_versions.find(
              (v) => v == CONSTANTS.OWN
            );

            const defaultLoad = (): void => {
              if (!this.canEdit && !this.canSuggest && onlyAllowedVersions?.length && !hasLatest) {
                this.serviceShare.onlyOldVersions = true;
                this.prosemirrorEditorService.editorContainers = {};
                this.prosemirrorEditorService.xmlFragments = {};
                const version = +onlyAllowedVersions[onlyAllowedVersions.length - 1];
                this.getVersionModeQuery(fragment);
                this.router.navigate([roomName], { fragment: `${version}` });
                this.ydocService.init(
                  `${roomName}/${version}`,
                  { data: userInfo },
                  articleData,
                  version
                );
                this.serviceShare.oldVersion = true;
              } else {
                this.prosemirrorEditorService.editorContainers = {};
                this.prosemirrorEditorService.xmlFragments = {};
                this.serviceShare.onlyOldVersions = false;
                this.ydocService.init(roomName!, { data: userInfo }, articleData);
                this.serviceShare.oldVersion = false;
              }
            };

            if (urlInfo) {
              if (urlInfo.includes('-')) {
                const commentId = urlInfo;
                if (commentId && commentId.length > 0) {
                  this.commentService.shouldScrollComment = true;
                  this.commentService.markIdOfScrollComment = commentId;
                }
              } else {
                const version = Number(urlInfo);
                if (!isNaN(version)) {
                  console.log('Selected version - ', version);
                  if (onlyAllowedVersions?.length) {
                    if (!onlyAllowedVersions.map((n: string) => +n).includes(version) && !hasOwn) {
                      this.serviceShare.onlyOldVersions = true;
                      this.prosemirrorEditorService.editorContainers = {};
                      this.prosemirrorEditorService.xmlFragments = {};
                      const version = +onlyAllowedVersions[onlyAllowedVersions.length - 1];
                      this.getVersionModeQuery(fragment);
                      this.router.navigate([roomName], { fragment: `${version}` });
                      this.ydocService.init(
                        `${roomName}/${version}`,
                        { data: userInfo },
                        articleData,
                        version
                      );
                      this.serviceShare.oldVersion = true;
                    } else {
                      this.prosemirrorEditorService.editorContainers = {};
                      this.prosemirrorEditorService.xmlFragments = {};
                      this.getVersionModeQuery(fragment);
                      this.ydocService.init(
                        `${roomName}/${version}`,
                        { data: userInfo },
                        articleData,
                        version
                      );
                      this.serviceShare.oldVersion = true;
                    }
                  } else {
                    this.prosemirrorEditorService.editorContainers = {};
                    this.prosemirrorEditorService.xmlFragments = {};
                    this.getVersionModeQuery(fragment);
                    this.ydocService.init(
                      `${roomName}/${version}`,
                      { data: userInfo },
                      articleData,
                      version
                    );
                    this.serviceShare.oldVersion = true;
                  }
                  if (!this.canEdit && !this.canSuggest && onlyAllowedVersions?.length) {
                    this.serviceShare.onlyOldVersions = true;
                  }
                } else {
                  defaultLoad();
                }
                return;
              }
            }
            defaultLoad();
          },
          error: (err) => {
            console.error(err);
          },
        })
    );

    this.subscription.add(
      this.ydocService.ydocStateObservable
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(({ event, data }) => {
          this.changeDetection.detectChanges();
          if (event == 'docIsBuild') {
            if (this.ydocService?.currUser?.settings?.init?.editMode) {
              this.turnOnOffTrackChanges(
                this.ydocService.currUser.settings.init?.editMode == 'suggest'
              );
            }

            this.ydoc = data.ydoc;
            this.versionData = data.versionData;
            if (data.versionData) {
              this.versionData.userData = data.userInfo;
              this.serviceShare.oldVersion = true;
            }

            this.ydocService.trackChangesMetadata?.observe(() => {
              const trackChangesMetadata =
                this.ydocService.trackChangesMetadata?.get('trackChangesMetadata');
              if (trackChangesMetadata.lastUpdateFromUser !== this.ydoc?.guid) {
                this.changeDetection.detectChanges();
              }
            });
            this.shouldBuild = true;
            this.subscription.add(
              this.prosemirrorEditorService
                .init(data)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                  if (this.commentService.shouldScrollComment) {
                    if (this.commentService.scrollToCommentMarkAndSelect() && this.canSeeComments) {
                      this.toggleSidebar('comments');
                    }
                  }
                  if (
                    this.serviceShare.oldVersion &&
                    this.sidebar != 'versions' &&
                    !this.serviceShare.shouldSeeOnlyVersionPreview
                  ) {
                    setTimeout(() => {
                      this.toggleSidebar('versions');
                      this.changeDetection.detectChanges();
                    }, 50);
                  }
                  if (data.versionData) {
                    this.prosemirrorEditorService.previewArticleMode.mode = true;
                  }
                  this.ydocService.docIsBuild = true;

                  this.ydocService.shouldReconnect = true;
                  if (this.ydocService.creatingANewArticle) {
                    this.ydocService.articleVersions.push([
                      {
                        date: new Date().getTime(),
                        snapshot: Y.encodeSnapshot(Y.snapshot(this.ydoc)),
                        clientID: this.ydoc.clientID,
                        users: [
                          {
                            name: data.userInfo.data.name,
                            userColor: data.userInfo.color.userColor,
                            id: data.userInfo.data.id,
                            email: data.userInfo.data.email,
                          },
                        ],
                      },
                    ]);
                    this.ydocService.creatingANewArticle = false;
                  }
                  setTimeout(() => {
                    changeVersionSubject.next('sync');
                  }, 500);
                })
            );
            if (!this.ydocService.articleData) {
              this.subscription.add(
                this.articlesService
                  .getArticleByUuid(this.roomName!)
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe((data) => {
                    this.ydocService.setArticleData(data.data);
                    this.serviceShare.titleControl.setValue(
                      this.ydocService.articleData.name.replace(
                        this.serviceShare.escapeHtmlTags,
                        ''
                      )
                    );
                  })
              );
            } else {
              this.serviceShare.titleControl.setValue(
                this.ydocService.articleData.name.replace(this.serviceShare.escapeHtmlTags, '')
              );
            }
            this.articleTemplate = this.ydocService.articleData.layout.name;
          }
        })
    );
  }

  ngAfterViewInit(): void {
    this.watchCommentsNumberChange();
    this.watchTrackChangesNumberChange();
    this.addListenerForActiveTab();
    this.changeDetection.detectChanges();
  }

  turnOnOffTrackChanges(trackChanges?: boolean): void {
    if (trackChanges === undefined) {
      // Toggle between modes
      this.editModeService.setMode(undefined);
    } else {
      // Set specific mode
      this.editModeService.setMode(trackChanges ? EditMode.suggest : EditMode.edit);
    }
  }

  toggleSidebar(section: string): void {
    if (!this.sidebarDrawer?.opened || this.sidebar == section) {
      this.sidebarDrawer?.toggle();
    }
    this.sidebar = section;

    // If it's closed - clear the sidebar value
    if (!this.sidebarDrawer?.opened) {
      this.sidebar = '';
    }

    this.serviceShare.CommentsService.shouldCalc = section == 'comments';
  }

  openDialog(): void {
    this.dialog.open(AddContributorsDialogComponent, {
      width: '665px',
      panelClass: 'contributors-dialog',
      data: {},
      disableClose: false,
    });
  }

  navigateToPjs(): void {
    this.prosemirrorEditorService.spinSpinner();
    const journalId = this.ydocService.articleData['journal_id'];

    this.articleSectionsService.getJournalById(journalId).subscribe({
      next: (journalData: ResponseWithMetadata<Journal>) => {
        const journalUrl = journalData.data['journal_url'];
        const articleUuid = this.ydocService.articleData.uuid;
        const redirectUrl = `${journalUrl}redirect_from_arpha_document?id=${articleUuid}`;

        window.location.href = redirectUrl;
      },
      error: (err) => {
        console.error('Error fetching journal data:', err);
        this.prosemirrorEditorService.stopSpinner();
      },
    });
  }

  export(): void {
    this.subscription.add(
      this.dialog
        .open(ExportOptionsComponent, {
          width: '532px',
          data: {},
          disableClose: false,
        })
        .afterClosed()
        .subscribe()
    );
  }

  openImportDialog(): void {
    this.subscription.add(
      this.dialog
        .open(JATSImportModalComponent, {
          width: '665px',
          data: { from: JatsModule.article },
          disableClose: false,
        })
        .afterClosed()
        .subscribe()
    );
  }

  submit(): void {
    if (this.sidebar != 'validation') {
      setTimeout(() => {
        this.toggleSidebar('validation');
        this.changeDetection.detectChanges();
      }, 50);
    }

    setTimeout(() => {
      this.serviceShare.triggerValidation();

      const validationSubscription = this.serviceShare.validationResults$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((results) => {
          if (results != 0) {
            validationSubscription.unsubscribe();
            return;
          }

          const journalId = this.ydocService.articleData['journal_id'];
          this.prosemirrorEditorService.spinSpinner();

          this.serviceShare.httpClient
            .post(
              `${this.appConfig.apiUrl}/article-storage/article/${this.ydocService.articleData.uuid}/create-version`,
              {}
            )
            .pipe(
              takeUntil(this.unsubscribe$),
              mergeMap(() => {
                return this.articleSectionsService.getJournalById(journalId);
              })
            )
            .subscribe({
              next: (journalData: ResponseWithMetadata<Journal>) => {
                const journalUrl = journalData.data['journal_url'];
                const articleUuid = this.ydocService.articleData.uuid;
                const redirectUrl = `${journalUrl}submit_arpha_document?id=${articleUuid}`;

                validationSubscription.unsubscribe();
                window.location.href = redirectUrl;
              },
              error: (err) => {
                console.error('Error fetching journal data:', err);
                this.prosemirrorEditorService.stopSpinner();
                validationSubscription.unsubscribe();
              },
            });
        });
    }, 100);
  }

  returnToNewestVersion(): void {
    if (this.serviceShare.oldVersion) {
      this.serviceShare.YdocService.versionSubject.next(VersionChange.reconnect);
      changeVersionSubject.next('returnToNewest');
      this.serviceShare.router.navigate([this.roomName.split('/')[0]]);
      this.serviceShare.YdocService.destroyVersionDoc();
      this.serviceShare.YdocService.shouldReconnect = true;
      this.serviceShare.YdocService.lastSelectedVersion = undefined;
      this.serviceShare.ProsemirrorEditorsService.editorContainers = {};
      this.serviceShare.oldVersion = false;
      this.versionData = undefined;
    }
  }

  canBySeenByUser(userId: string): boolean {
    const currentUser = this.ydocService.currUser;
    let idsThatShouldBeHidden = [];

    if (currentUser) {
      const collaborators = this.ydocService
        .getCollaborators()
        .filter((c) => c.id != currentUser?.id);
      idsThatShouldBeHidden = collaborators
        .filter(
          (c) =>
            c.hide_me_from_user?.includes(currentUser?.auth_role) ||
            c.hide_me_from_user?.includes(currentUser?.id)
        )
        .map((c) => c.id);
    }

    return !idsThatShouldBeHidden.includes(userId);
  }

  ngOnDestroy(): void {
    if (this.ydocService.currUser) {
      this.ydocService.currUser.allowed_article_versions = [];
    }
    this.serviceShare.EnforcerService.articlePolicies = [];
    this.serviceShare.titleControl = new UntypedFormControl();
    this.subscription.unsubscribe();
    this.serviceShare.oldVersion = false;
    this.versionData = undefined;
    this.serviceShare.resetServicesData(this.ydocService.shouldReconnect);
    if (this.ydocService.shouldReconnect) {
      this.ydocService.lastSelectedVersion = undefined;
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initArticleStructureMap(): void {
    this.ydocService.trackChangesMetadata!.observe(() => {
      const hideShowData = this.ydocService.trackChangesMetadata!.get('trackChangesMetadata');
      if (hideShowData.lastUpdateFromUser !== this.ydocService.ydoc?.guid) {
      }
    });
  }

  private getVersionModeQuery(fragment: string): boolean {
    const previewQuery = fragment?.includes('?preview=') ? fragment.split('=')[1] : false;
    this.serviceShare.shouldSeeOnlyVersionPreview = previewQuery == 'true';
    return this.serviceShare.shouldSeeOnlyVersionPreview;
  }

  private watchCommentsNumberChange(): void {
    this.subscription.add(
      this.commentService.commentsChangeSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const currentUser = this.ydocService.currUser;
        let idsThatShouldBeHidden = [];

        if (currentUser) {
          const collaborators = this.ydocService
            .getCollaborators()
            .filter((c) => c.id != currentUser?.id);
          idsThatShouldBeHidden = collaborators
            .filter(
              (c) =>
                c.hide_my_comments_from_user?.includes(currentUser?.auth_role) ||
                c.hide_my_comments_from_user?.includes(currentUser?.id)
            )
            .map((c) => c.id);
        }

        const commentUsersIds = Object.values(this.commentService.commentsObj)
          .filter((com) => com.commentAttrs.resolved == 'false')
          // eslint-disable-next-line spellcheck/spell-checker
          .map((c) => c.commentAttrs.userid);
        const commentsNumber = commentUsersIds.filter(
          (id) => !idsThatShouldBeHidden.includes(id)
        ).length;
        this.commentsNumberChange$.next(commentsNumber);
        this.noComments = commentsNumber == 0;
        this.changeDetection.detectChanges();
      })
    );
  }

  private watchTrackChangesNumberChange(): void {
    this.subscription.add(
      this.trackChanges.changesChangeSubject$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const currentUser = this.ydocService.currUser;
        let idsThatShouldBeHidden = [];

        if (currentUser) {
          const collaborators = this.ydocService
            .getCollaborators()
            .filter((c) => c.id != currentUser?.id);
          idsThatShouldBeHidden = collaborators.map((c) => c.id);
        }

        const changeUsersIds = Object.values(this.trackChanges.changesObj)
          // eslint-disable-next-line spellcheck/spell-checker
          .map((change) => change.changeAttrs.user);
        const changesNumber = changeUsersIds.filter(
          (id) => !idsThatShouldBeHidden.includes(id)
        ).length;
        this.changesNumberChange$.next(changesNumber);
        this.noChanges = changesNumber == 0;
        this.changeDetection.detectChanges();
      })
    );
  }

  private getPermissions(): Observable<unknown> {
    return combineLatest([
      this.serviceShare.EnforcerService.enforceAsync('editMode(*)', 'edit'),
      this.serviceShare.EnforcerService.enforceAsync('editMode(*)', 'suggest'),
      this.serviceShare.EnforcerService.enforceAsync('versions(*)', 'view'),
      this.serviceShare.EnforcerService.enforceAsync('contributors(*)', 'view'),
      this.serviceShare.EnforcerService.enforceAsync('sections(*)', 'view'),
      this.serviceShare.EnforcerService.enforceAsync('comments(*, *)', 'view'),
    ]);
  }

  private watchRoleChange(): void {
    this.subscription.add(
      this.ydocService.currentUserAccess$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((userAccess: string) => {
          this.userAccess = userAccess;
          this.changeDetection.detectChanges();
        })
    );
  }

  private watchPolicyChange(): void {
    this.subscription.add(
      this.getPermissions()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: PermissionsTuple) => this.setPermissions(res))
    );

    this.subscription.add(
      this.serviceShare.EnforcerService.policyUpdate$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data) => {
          if (data == this.serviceShare.EnforcerService.policyUpdateAction) {
            this.sidebar = '';
            this.getPermissions()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: PermissionsTuple) => this.setPermissions(res));
          }
        })
    );
  }

  private setPermissions([
    editMode,
    suggestMode,
    versions,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    contributors,
    sections,
    comments,
  ]: PermissionsTuple): void {
    this.canSeeComments = comments;
    this.canSuggest = suggestMode;
    this.serviceShare.canUseTrackChanges = suggestMode;
    this.canEdit = editMode;
    this.canSeeVersions = versions;
    this.canSeeSectionsTree = sections;

    if (!editMode && !suggestMode) {
      this.serviceShare.canUseTrackChanges = false;
      this.prosemirrorEditorService.previewArticleMode.mode = true;
    } else if (!editMode) {
      this.prosemirrorEditorService.previewArticleMode.mode = true;
    } else {
      this.prosemirrorEditorService.previewArticleMode.mode = false;
    }

    this.changeDetection.detectChanges();
  }

  private addListenerForActiveTab(): void {
    document.onvisibilitychange = () => {
      if (document.visibilityState == 'visible') {
        if (this.ydocService.articleData) {
          const policiesSubject = this.serviceShare.EnforcerService.policiesChangeSubject
            .asObservable()
            .pipe(take(1));
          this.subscription.add(
            combineLatest([
              this.serviceShare.ArticlesService.getArticleDomainPolicies(
                this.ydocService.articleData.uuid
              ),
              policiesSubject,
            ]).subscribe({
              next: ([res, policies]) => {
                if (this.serviceShare.compareObjects(res, policies)) {
                  this.serviceShare.EnforcerService.policiesChangeSubject.next(res);
                }
              },
              error: (err) => {
                console.error(err);
              },
            })
          );
        }
      }
    };
  }

  private watchButtonVisibility(): void {
    this.canShowTaxonButtons$ = this.taxonService.canShowTaxonButtons$;
    this.areActionButtonsVisible$ = this.articleModeService.mode$.pipe(
      map((mode) => mode !== ArticleUserMode.readOnly && !this.serviceShare.oldVersion)
    );
    this.isCommentAllowed$ = this.articleModeService.mode$.pipe(
      map((mode) => mode !== ArticleUserMode.readOnly && !this.serviceShare.oldVersion)
    );
  }
}
