import { Injectable, Injector } from '@angular/core';

import { ChangeData } from '../change.models';
import { ChangesRenderingService } from '../changes-rendering/changes-rendering.service';
import { ChangesNavigationService } from '../changes-navigation/changes-navigation.service';
import { Subscription } from 'rxjs';

@Injectable()
export class SharedChangesService {
  subscription$ = new Subscription();

  allChanges: ChangeData[] = [];

  private _changesRenderingService: ChangesRenderingService;
  private _changesNavigationService: ChangesNavigationService;

  constructor(private injector: Injector) {}

  get changesRenderingService(): ChangesRenderingService {
    if (!this._changesRenderingService) {
      this._changesRenderingService = this.injector.get(ChangesRenderingService);
    }
    return this._changesRenderingService;
  }

  get changesNavigationService(): ChangesNavigationService {
    if (!this._changesNavigationService) {
      this._changesNavigationService = this.injector.get(ChangesNavigationService);
    }
    return this._changesNavigationService;
  }

  destroy(): void {
    this.subscription$.unsubscribe();
  }
}
