import { Inject, Injectable } from '@angular/core';
import { EditorView } from 'prosemirror-view';
import { DOMSerializer, Node } from 'prosemirror-model';
import { YdocService } from '../../ydoc.service';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { schema } from '@app/editor/utils/Schema';
import { Store } from '@ngrx/store';
import { selectCurrentMode } from '@app/store/edit-mode/edit-mode.selectors';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { TrackChangesService } from '../../track-changes/track-changes.service';
import { EditMode } from '../../edit-mode/models';

@Injectable({
  providedIn: 'root',
})
export class EditorPropsService {
  private fullSchemaDOMSerializer = DOMSerializer.fromSchema(schema);

  private isPreviewMode: boolean;
  private isEditMode: boolean;

  constructor(
    private ydocService: YdocService,
    private httpClient: HttpClient,
    private store: Store,
    private trackChangesService: TrackChangesService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.store
      .select(selectCurrentMode)
      .pipe(
        distinctUntilChanged(),
        tap((mode) => {
          this.isPreviewMode = mode === EditMode.preview;
          this.isEditMode = mode === EditMode.edit;
        })
      )
      .subscribe();
  }

  public handleBlur(): (view: EditorView) => void {
    return (view: EditorView): Promise<void> => {
      if (!this.isEditMode) {
        return;
      }

      const { doc } = view.state;

      doc.forEach((node: Node) => {
        this.exportMetadata(node);
      });
    };
  }

  public handleDoubleClick() {
    return (view: EditorView, pos: number): boolean => {
      const hideShowPluginKey = this.trackChangesService.trackChangesPluginKey;
      const node = view.state.doc.nodeAt(pos);
      const marks = node?.marks;
      const hasTrackChangesMark = node?.marks.some((mark) => {
        return (
          mark!.type.name == 'insertion' ||
          mark!.type.name == 'deletion' ||
          mark!.type.name == 'insFromPopup' ||
          mark!.type.name == 'delFromPopup' ||
          mark!.type.name == 'format_change'
        );
      });

      if (hasTrackChangesMark && !this.isPreviewMode) {
        const cursorCoordinates = view.coordsAtPos(pos);
        const tr1 = view.state.tr.setMeta(hideShowPluginKey, {
          marks,
          focus: view.hasFocus(),
          coords: cursorCoordinates,
        });
        view.dispatch(tr1.setMeta('addToLastHistoryGroup', true));
        return true;
      }
      return false;
    };
  }

  private exportMetadata(node: Node): void {
    if (node.type.name === 'form_field' && node.attrs['exportMeta']) {
      const content = (this.fullSchemaDOMSerializer.serializeNode(node) as HTMLElement).innerHTML
        .replace(/<([a-zA-Z0-9]+)([^>]*)\/?>/g, '<$1>')
        .trim();

      const body = {
        metadata: { [node.attrs['exportMeta']]: content },
      };

      if (
        body.metadata[node.attrs['exportMeta']] !==
        this.ydocService.articleData.metadata?.[node.attrs['exportMeta']]
      ) {
        this.httpClient
          .patch(
            `${this.config.apiGatewayService}/api/articles/items/${this.ydocService.articleData.id}`,
            body
          )
          .subscribe({
            next: (res: unknown) => {
              console.log(res);
            },
            error: (err: unknown) => {
              console.error(err);
            },
          });
      }
    }
  }
}
