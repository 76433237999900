import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import * as EditModeActions from './edit-mode.actions';
import { selectEditModeState } from './edit-mode.selectors';
import { EditMode } from '@app/editor/services/edit-mode/models';

@Injectable()
export class EditModeEffects {
  validateMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditModeActions.validateEditMode),
      withLatestFrom(this.store.select(selectEditModeState)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([_, state]) => {
        // Validation logic goes here
        const permissions = state.permissions;
        const currentMode = state.currentMode;

        if (currentMode === EditMode.edit && !permissions.canEdit) {
          return EditModeActions.setEditMode({ mode: EditMode.preview });
        }

        if (currentMode === EditMode.suggest && !permissions.canSuggest) {
          return EditModeActions.setEditMode({
            mode: permissions.canEdit ? EditMode.edit : EditMode.preview,
          });
        }

        return EditModeActions.setEditMode({ mode: currentMode });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store
  ) {}
}
