import { ChangeDetectorRef, ElementRef, Injectable, Injector } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

import { CommentsNavigationService } from '../comments-navigation/comments-navigation.service';
import { CommentsRenderingService } from '../comments-rendering/comments-rendering.service';
import { Comment, YdocCommentThread } from '../../comment.models';

@Injectable()
export class SharedCommentsService {
  subscription$ = new Subscription();

  allComments: Comment[] = [];

  searching: boolean = false;
  users: string[] = [];
  sortingFormGroup: UntypedFormGroup;

  changeDetectorRef: ChangeDetectorRef;

  searchResults: { inydoc: YdocCommentThread; pmmark: Comment }[];

  private _commentsRenderingService: CommentsRenderingService;
  private _commentsNavigationService: CommentsNavigationService;

  constructor(private injector: Injector) {}

  get commentsRenderingService(): CommentsRenderingService {
    if (!this._commentsRenderingService) {
      this._commentsRenderingService = this.injector.get(CommentsRenderingService);
    }
    return this._commentsRenderingService;
  }

  get commentsNavigationService(): CommentsNavigationService {
    if (!this._commentsNavigationService) {
      this._commentsNavigationService = this.injector.get(CommentsNavigationService);
    }
    return this._commentsNavigationService;
  }

  get arrayControls(): UntypedFormControl[] {
    return (this.sortingFormGroup.get('byCreators') as UntypedFormArray)
      .controls as UntypedFormControl[];
  }

  get showResolved(): boolean {
    return this.sortingFormGroup.get('showResolved').value;
  }

  setFormGroup(): void {
    return this.commentsNavigationService.setFormGroup();
  }
  setInitialCommentsData(): void {
    return this.commentsRenderingService.setInitialCommentsData();
  }

  setChangeDetector(changeDetectorRef: ChangeDetectorRef): void {
    this.changeDetectorRef = changeDetectorRef;
  }

  setSortingListener(commentsSearchInput: ElementRef): void {
    return this.commentsNavigationService.setSortingListener(commentsSearchInput);
  }

  setAddedCommentSubscription(commentInput: ElementRef): void {
    return this.commentsRenderingService.setAddedCommentSubscription(commentInput);
  }

  setCommentChangeSubscription(): void {
    return this.commentsRenderingService.setCommentChangeSubscription();
  }

  doneRendering(): void {
    return this.commentsRenderingService.doneRendering();
  }

  selectPreviousComment(): void {
    return this.commentsNavigationService.selectPreviousComment();
  }

  selectNextComment(): void {
    this.commentsNavigationService.selectNextComment();
  }

  endSearch(): void {
    return this.commentsNavigationService.endSearch();
  }

  cancelBtnHandle(commentInput: ElementRef): void {
    return this.commentsRenderingService.cancelBtnHandle(commentInput);
  }

  destroy(): void {
    this.subscription$.unsubscribe();
  }
}
