import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { TreeService } from '@app/editor/meta-data-tree/tree-service/tree.service';
import {
  TaxonTreatment,
  ExternalLinks,
  ParsedExcelRow,
  MaterialData,
} from '../taxon-treatment.models';

@Injectable({
  providedIn: 'root',
})
export class ParseTaxonsFromExcelService {
  // Define the order of taxa classification columns, starting from the most specific rank
  private readonly taxaColumnsOrder = [
    'form',
    'variety',
    'subspecies',
    'species',
    'subgenus',
    'genus',
    'subtribe',
    'tribe',
    'subfamily',
    'family',
    'superfamily',
    'infraorder',
    'suborder',
    'order',
    'superorder',
    'subclass',
    'class',
    'superclass',
    'subphylum',
    'phylum',
    'subkingdom',
    'kingdom',
  ];

  constructor(private treeService: TreeService) {}

  parseExcel(arrayBuffer: ArrayBuffer): string {
    const data = new Uint8Array(arrayBuffer);
    const arr: string[] = [];
    for (let i = 0; i < data.length; ++i) {
      arr.push(String.fromCharCode(data[i]));
    }
    return arr.join('');
  }

  excelSerialDateToJSDate(serial: number): string {
    const jsDate = new Date(Date.UTC(1899, 11, 30));
    jsDate.setDate(jsDate.getDate() + serial);

    const day = jsDate.getDate();
    const month = jsDate.getMonth() + 1;
    const year = jsDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  convertWorkbookToJson(workbook: XLSX.WorkBook): Record<string, TaxonTreatment> {
    const taxonTreatmentsObj: Record<string, TaxonTreatment> = {};

    workbook.SheetNames.forEach((sheetName) => {
      const sheetData: ParsedExcelRow[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      sheetData.forEach((data) => {
        const localID = data['Taxon_Local_ID'] as string;
        delete data['Taxon_Local_ID'];

        let newData: ParsedExcelRow = data;

        if (sheetName === 'Taxa') {
          newData = this.convertKeysToLowerCase(data);
        }

        if (!taxonTreatmentsObj[localID]) {
          taxonTreatmentsObj[localID] = {
            taxa: {},
            materials: [],
            externalLinks: [],
          };
        }

        this.populateTaxonTreatments(taxonTreatmentsObj, localID, newData, sheetName);
      });
    });

    return taxonTreatmentsObj;
  }

  convertKeysToLowerCase(obj: ParsedExcelRow): ParsedExcelRow {
    const lowerCaseObj: ParsedExcelRow = {};

    Object.keys(obj).forEach((key) => {
      lowerCaseObj[key.toLowerCase()] = obj[key];
    });

    return lowerCaseObj;
  }

  populateTaxonTreatments(
    taxonTreatmentsObj: Record<string, TaxonTreatment>,
    localID: string,
    newData: ParsedExcelRow,
    sheetName: string
  ): void {
    switch (sheetName) {
      case 'Taxa':
        this.handleTaxaData(taxonTreatmentsObj, localID, newData);
        break;
      case 'Materials':
        this.handleMaterialData(taxonTreatmentsObj, localID, newData as MaterialData);
        break;
      case 'ExternalLinks':
        this.handleExternalLinksData(taxonTreatmentsObj, localID, newData);
        break;
    }
  }

  handleTaxaData(
    taxonTreatmentsObj: Record<string, TaxonTreatment>,
    localID: string,
    newData: ParsedExcelRow
  ): void {
    taxonTreatmentsObj[localID].taxa = newData;

    // If 'authorship' field exists, rename it to 'authorandyear' to align with the formio schema.
    if (newData['authorship']) {
      newData['authorandyear'] = newData['authorship'];
      delete newData['authorship'];
    }

    let rankFound = false;

    // Loop through columns from the most specific rank. Once we find the rank,
    // the next column with data becomes the classification.
    for (const column of this.taxaColumnsOrder) {
      const lowerColumn = column.toLowerCase();
      if (newData[lowerColumn] && !rankFound) {
        taxonTreatmentsObj[localID].taxa.rank = column;
        rankFound = true;
      } else if (newData[lowerColumn] && rankFound) {
        taxonTreatmentsObj[localID].taxa.classification = newData[lowerColumn] as string;
        break;
      }
    }

    const { taxonTitle, label } = this.treeService.generateTaxonTitle(
      taxonTreatmentsObj[localID].taxa
    );
    taxonTreatmentsObj[localID].taxa.taxonTitle = taxonTitle;
    taxonTreatmentsObj[localID].taxa.label = label;
  }

  handleMaterialData(
    taxonTreatmentsObj: Record<string, TaxonTreatment>,
    localID: string,
    newData: MaterialData
  ): void {
    // Convert the eventDate from Excel's serial format to a JS date format.
    if (newData.eventDate) {
      newData.eventDate = this.excelSerialDateToJSDate(Number(newData.eventDate));
    }

    // Create a new object excluding the __rowNum__ property, as it's not needed.
    const processedData: MaterialData = {};
    for (const key of Object.keys(newData)) {
      if (key !== '__rowNum__' && key !== 'typeStatus') {
        processedData[key] = `${newData[key]};&nbsp;`;
      } else if (key === 'typeStatus') {
        processedData[key] = newData[key];
      }
    }

    taxonTreatmentsObj[localID].materials.push(processedData);
  }

  handleExternalLinksData(
    taxonTreatmentsObj: Record<string, TaxonTreatment>,
    localID: string,
    newData: ParsedExcelRow
  ): void {
    const processedData: ExternalLinks = {
      link: '',
      label: '',
      select: '',
    };

    for (const key of Object.keys(newData)) {
      switch (key) {
        case 'Link':
          processedData.link = newData[key] as string;
          break;
        case 'Link type':
          processedData.select = newData[key] as string;
          break;
        default:
          if (key !== '__rowNum__') {
            processedData[key.toLowerCase() as keyof ExternalLinks] = newData[key] as string;
          }
          break;
      }
    }

    taxonTreatmentsObj[localID].externalLinks.push(processedData);
  }
}
