import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionObjectAction } from '@app/core/models/auth.model';
import {
  ArticleUserMode,
  ArticleUserModeAbbreviationMapping,
} from '../article-role/article-role.models';
import { EnforcerService } from '@app/casbin/services/enforcer.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ArticleModeService {
  public icon$: Observable<string>;
  public details$: Observable<string>;
  public role$: Observable<string>;
  public mode$: Observable<ArticleUserMode>;

  private iconSubject = new BehaviorSubject<string>(null);
  private detailsSubject = new BehaviorSubject<string>(null);
  private roleSubject = new BehaviorSubject<string>(null);
  private modeSubject = new BehaviorSubject<ArticleUserMode>(null);

  // Private properties
  private editModePermissions: PermissionObjectAction<'editMode'>[] = [];
  private commentPermissions: PermissionObjectAction<'comments'>[] = [];

  private readonly errorMessage = 'No proper article role was retrieved!';
  private readonly modeMap: ArticleUserModeAbbreviationMapping = {
    [ArticleUserMode.canCommentOnly]: {
      short: 'CO',
      details: 'Comment only mode: You can only add comments to this article.',
      icon: 'comments',
    },
    [ArticleUserMode.editor]: {
      short: 'ED',
      details: 'Editor mode: You have full access to modify this article.',
      icon: 'edit1',
    },
    [ArticleUserMode.readOnly]: {
      short: 'RO',
      details: 'Read only mode: You can only view this article.',
      icon: 'visibility',
    },
    [ArticleUserMode.suggester]: {
      short: 'SG',
      details: 'Suggestion mode: You can make suggestions about the article.',
      icon: 'drive_file_rename_outline',
    },
  };

  constructor(
    private enforcerService: EnforcerService,
    private ydocService: YdocService
  ) {
    this.icon$ = this.iconSubject.asObservable();
    this.details$ = this.detailsSubject.asObservable();
    this.role$ = this.roleSubject.asObservable();
    this.mode$ = this.modeSubject.asObservable();
    this.initializePermissionWatcher();
  }

  get mode(): ArticleUserMode {
    if (this.isEditor) {
      return ArticleUserMode.editor;
    } else if (this.isSuggester) {
      return ArticleUserMode.suggester;
    } else if (this.canCommentOnly) {
      return ArticleUserMode.canCommentOnly;
    } else if (!this.isReadOnly) {
      this.riseError();
      return null;
    }

    return ArticleUserMode.readOnly;
  }

  private get isEditor(): boolean {
    return this.editModePermissions.includes('edit');
  }

  private get isSuggester(): boolean {
    return (
      !this.editModePermissions.includes('edit') && this.editModePermissions.includes('suggest')
    );
  }

  private get canCommentOnly(): boolean {
    const { editModePermissions, commentPermissions } = this;
    const canPreview = editModePermissions.includes('preview');
    const canPreviewOnly = editModePermissions.length === 1 && canPreview;
    const canComment = commentPermissions.includes('create');
    return canPreviewOnly && canComment;
  }

  private get isReadOnly(): boolean {
    return (
      !this.commentPermissions.includes('create') &&
      this.editModePermissions.length === 1 &&
      this.editModePermissions[0] === 'preview'
    );
  }

  private initializePermissionWatcher(): void {
    const { enforcerService } = this;
    enforcerService.policyUpdate$
      .pipe(
        filter((action) => {
          const isArticleInitialized = !!this.ydocService?.articleData?.uuid;
          return action === enforcerService.policyUpdateAction && isArticleInitialized;
        })
      )
      .subscribe(() => this.assignPermissions());

    this.ydocService.currentUserAccess$.pipe().subscribe(() => {
      this.enforcerService.triggerUpdatePolicy();
    });
  }

  private assignPermissions(): void {
    const articleUuid = this.ydocService.articleData.uuid;
    this.editModePermissions = this.enforcerService.getAllowedActionsPerArticle(
      'editMode',
      articleUuid
    );
    this.commentPermissions = this.enforcerService.getAllowedActionsPerArticle(
      'comments',
      articleUuid
    );

    const currentMode = this.mode;

    this.iconSubject.next(this.modeMap[currentMode]?.icon);
    this.detailsSubject.next(this.modeMap[currentMode]?.details);
    this.roleSubject.next(this.modeMap[currentMode]?.short);
    this.modeSubject.next(currentMode);
  }

  private riseError(): void {
    console.error(this.errorMessage);
  }
}
