/* eslint-disable @typescript-eslint/naming-convention */
export interface ValidationResult {
  fulfilled: boolean;
  errorMessage: string;
  sectionId?: string;
}

export interface PositionFunctions {
  // Returns true if section is first in container
  isFirst: () => boolean;

  // Returns true if section is last in container
  isLast: () => boolean;

  // Returns true if section appears after named section
  isAfter: (secToBeAfterName: string) => boolean;

  // Total count of sections with same name
  sectionCount: number;
}

export enum ValidationExecutionResult {
  cancel = 'cancel',
  done = 1,
  inProgress = 0,
}

export type RuleType =
  | 'ToBeBetweenMinMax'
  | 'ToHaveMinMaxEqualSections'
  | 'EqualSectionPositions'
  | 'FormControls'
  | 'CitedFigures'
  | 'ValidateComplexSections'
  | 'ValidateCitedRefs'
  | 'ValidateCitedTables'
  | 'ValidateCitedSupplementaryFiles'
  | 'ValidateEndNotes'
  | 'ValidateResolvedChanges'
  | 'ValidateResolvedComments'
  | 'ValidateCitedItems';

// Base interface for all rules
export interface BaseRule {
  rule: RuleType;
  description?: string;
  key?: string;
}

export interface CharacterCountRule extends BaseRule {
  rule: 'ToBeBetweenMinMax';
  config: CharacterCountConfig;
}

// Section min/max validation rule
export interface SectionMinMaxRule extends BaseRule {
  rule: 'ToHaveMinMaxEqualSections';
  config: SectionMinMaxConfig;
}

// Section position validation rule
export interface SectionPositionRule extends BaseRule {
  rule: 'EqualSectionPositions';
  config: SectionPositionConfig;
}

export interface FormControlsRule extends BaseRule {
  rule: 'FormControls';
}

export interface CitatedFiguresRule extends BaseRule {
  rule: 'CitedFigures';
}

export interface ComplexSectionsRule extends BaseRule {
  rule: 'ValidateComplexSections';
}

export interface CitedRefsRule extends BaseRule {
  rule: 'ValidateCitedRefs';
}

export interface CitedTablesRule extends BaseRule {
  rule: 'ValidateCitedTables';
}

export interface SupplementaryFilesRule extends BaseRule {
  rule: 'ValidateCitedSupplementaryFiles';
}

export interface EndNotesRule extends BaseRule {
  rule: 'ValidateEndNotes';
}

export interface ResolvedChangesRule extends BaseRule {
  rule: 'ValidateResolvedChanges';
}

export interface ResolvedCommentsRule extends BaseRule {
  rule: 'ValidateResolvedComments';
}

// Union type of all possible rules
export type ValidationRule =
  | { rule: 'ToBeBetweenMinMax'; config: CharacterCountConfig }
  | { rule: 'ToHaveMinMaxEqualSections'; config: SectionMinMaxConfig }
  | { rule: 'EqualSectionPositions'; config: SectionPositionConfig }
  | { rule: 'FormControls' }
  | { rule: 'ValidateCitedItems' }
  | { rule: 'ValidateComplexSections' }
  | { rule: 'ValidateResolvedChanges' }
  | { rule: 'ValidateResolvedComments' };

// Expression structure for section validations
export interface ValidationExpression {
  rule: string;
  errorMessage: string;
}

// Validation result interface (already defined in your code)
export interface ValidationResult {
  fulfilled: boolean;
  errorMessage: string;
  sectionId?: string;
}

export type ParsedExpressions = ValidationExpression[];

// Section data from backend
export interface SectionData {
  name: string;

  pivot_id: string;
  // Add other properties that come from backend
}

export interface SectionResponse {
  data: SectionData[];
}

// YDoc Map interfaces
interface FigureData {
  figurePlace: 'endEditor' | string;
  // Add other figure properties
}

interface TableData {
  tablePlace: 'endEditor' | string;
  // Add other table properties
}

interface ReferenceData {
  citation: {
    textContent: string;
  };
}

export interface ReferenceMaps {
  referencesInEditor: { [key: string]: ReferenceData };
  citedRefsInArticle: { [key: string]: boolean };
}

export interface FigureMaps {
  ArticleFigures: { [key: string]: FigureData };
  ArticleFiguresNumbers: string[];
}

export interface TableMaps {
  ArticleTables: { [key: string]: TableData };
  ArticleTablesNumbers: string[];
}

export interface EndNoteMaps {
  endNotes: { [key: string]: unknown }; // Define proper type if known
  endNotesNumbers: string[];
  endNotesCitations: { [key: string]: boolean };
}

export interface SupplementaryFileMaps {
  supplementaryFilesNumbers: string[];
}

export interface FormValidationContext {
  sectionId: string;
  title: {
    label: string;
  };
  mode?: string;
  type?: string;
  children?: FormValidationContext[];
  subsectionValidations?: {
    [pivotId: string]: {
      min: number;
      max: number;
    };
  };
}

export interface HtmlConvertibleObject {
  [key: string]: HtmlConvertible;
}

export type HtmlConvertible = string | number | HtmlConvertibleObject;

export interface ValidationConditions {
  validateResolvedChanges: boolean;
  validateResolvedComments: boolean;
}

export interface ValidationConfigItem {
  label: string;
  data: ValidationResult[];
}

export interface SectionMinMaxConfig {
  names: string;
  expressions: string;
  min?: number;
  max?: number;
}

export interface SectionPositionConfig {
  names: string;
  expressions: string;
}

export interface CharacterCountConfig {
  min: number;
  max: number;
}
