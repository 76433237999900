import { ActionReducerMap } from '@ngrx/store';
import { editModeReducer } from './edit-mode/edit-mode.reducer';
import { EditModeState } from './edit-mode/models';

export interface AppState {
  editMode: EditModeState;
}

export const reducers: ActionReducerMap<AppState> = {
  editMode: editModeReducer,
};
