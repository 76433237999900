/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ArticleSection } from '@app/editor/utils/interfaces/articleSection';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import { TaxonImportStateService } from '@app/editor/services/taxon-treatments/taxon-import-state/taxon-import-state.service';
import { TaxonImportService } from '@app/editor/services/taxon-treatments/taxon-import/taxon-import.service';
import { ParseTaxonsFromExcelService } from '@app/editor/services/taxon-treatments/parse-taxons-from-excel/parse-taxons-from-excel.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-taxon-treatments-section',
  templateUrl: './taxon-treatments-section.component.html',
  styleUrls: ['./taxon-treatments-section.component.scss'],
})
export class TaxonTreatmentsSectionComponent implements OnInit, OnDestroy {
  readonly isProcessing$ = this.stateService.isProcessing$;
  readonly totalTaxons$ = this.stateService.totalTaxons$;
  readonly processedTaxons$ = this.stateService.processedTaxons$;
  readonly progress$ = this.stateService.progress$;
  readonly isRendering$ = this.stateService.isRendering$;

  @Input() onSubmit!: (data: unknown) => Promise<unknown>;
  @Output() onSubmitChange = new EventEmitter<(data: unknown) => Promise<unknown>>();
  @Input() fGroup!: FormGroup;

  @Input() section!: ArticleSection;
  @Output() sectionChange = new EventEmitter<ArticleSection>();

  @Input() triggerCustomSecSubmit: Subject<unknown>;
  @Output() triggerCustomSecSubmitChange = new EventEmitter<Subject<unknown>>();

  @Output() async triggerSubmit(): Promise<void> {
    await this.onSubmit({});
  }

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly stateService: TaxonImportStateService,
    private readonly parseTaxonsFromExcelService: ParseTaxonsFromExcelService,
    private readonly taxonImportService: TaxonImportService,
    public readonly dialogRef: MatDialogRef<TaxonTreatmentsSectionComponent>,
    public readonly dialog: MatDialog,
    private readonly snackBar: SnackbarService
  ) {}

  ngOnInit(): void {
    this.setupSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stateService.finishImport();
  }

  onFileSelected(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (!files?.length) {
      return;
    }

    this.stateService.setProcessingState(true);

    const file = files[0];
    if (!this.isValidExcelFile(file)) {
      this.invalidFileType();
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const arrayBuffer = fileReader.result as ArrayBuffer;
      const binaryString = this.parseTaxonsFromExcelService.parseExcel(arrayBuffer);
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const taxonTreatmentsData = this.parseTaxonsFromExcelService.convertWorkbookToJson(workbook);

      if (!taxonTreatmentsData) {
        this.invalidFile();
      }

      this.taxonImportService
        .retrieveSectionTemplate(this.section.sectionID)
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (sectionTemplate) => {
          await this.taxonImportService.addTaxonTreatments(
            taxonTreatmentsData,
            this.section.sectionID,
            sectionTemplate
          );
        });
    };

    fileReader.readAsArrayBuffer(files[0]);
  }

  invalidFileType(): void {
    this.snackBar.error('Invalid file type! Please import an Excel file', 'Ok', {
      duration: 3000,
    });
    this.stateService.setProcessingState(false);
  }

  invalidFile(): void {
    this.snackBar.error('Invalid file! Please import file with correct data.');
    this.stateService.setProcessingState(false);
  }

  private isValidExcelFile(file: File): boolean {
    return (
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  }

  private setupSubscriptions(): void {
    this.stateService.importFinished$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
      this.snackBar.success('File imported successfully!');
    });
    this.triggerCustomSecSubmit.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.triggerSubmit();
    });
  }
}
