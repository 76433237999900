import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { ySyncPluginKey } from '../../../y-prosemirror-src/plugins/keys.js';
import {
  EditorContainer,
  ProsemirrorEditorsService,
} from '../../services/prosemirror-editor/prosemirror-editors.service';
import { YdocService } from '../../services/ydoc.service';
import { ServiceShare } from '../../services/service-share.service';

@Component({
  selector: 'app-end-notes-prosemirror-view',
  templateUrl: './end-notes-prosemirror-view.component.html',
  styleUrls: ['./end-notes-prosemirror-view.component.scss'],
})
export class EndNotesProsemirrorViewComponent implements AfterViewInit {
  @ViewChild('ProsemirrorEditor', { read: ElementRef }) ProsemirrorEditor?: ElementRef;
  endEditorContainer?: EditorContainer;

  @Input() versionData:
    | {
        snapshot: any;
        prevSnapshot: any;
        userData: any;
      }
    | undefined;

  constructor(
    private prosemirrEditorsService: ProsemirrorEditorsService,
    private ydocService: YdocService,
    private sharedService: ServiceShare
  ) {}

  ngAfterViewInit(): void {
    try {
      if (this.ydocService.editorIsBuild) {
        this.renderEndEditor();
      } else {
        this.ydocService.ydocStateObservable.subscribe(({ event }) => {
          if (event == 'docIsBuild') {
            this.renderEndEditor();
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  renderEndEditor(): void {
    const editor = (this.endEditorContainer = this.prosemirrEditorsService.renderDocumentEndEditor(
      this.ProsemirrorEditor?.nativeElement,
      'endNotesEditor',
      this.versionData
    ));

    if (this.versionData) {
      setTimeout(() => {
        editor.editorView.dispatch(
          editor.editorView.state.tr.setMeta(ySyncPluginKey, { ...this.versionData })
        );
      }, 1000);
    }
  }
}
