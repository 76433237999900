import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { YdocService } from '@app/editor/services/ydoc.service';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from '@app/core/services/snackbar/snackbar.service';
import { ValidationMetadata } from './models';

@Injectable()
export class ValidationMetadataService {
  constructor(
    private http: HttpClient,
    private ydocService: YdocService,
    private snackBar: SnackbarService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  private get apiUrl(): string {
    const articleId = this.ydocService.articleData.id;
    return `${this.config.apiGatewayService}/api/articles/items/${articleId}`;
  }

  public updateMetadata(charactersCount: number): Observable<void> {
    const payload: ValidationMetadata = {
      metadata: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        characters_count: charactersCount,
      },
    };

    return this.http.patch<void>(this.apiUrl, payload).pipe(
      catchError((error) => {
        console.error('Error updating validation metadata:', error);
        this.snackBar.error('Error updating validation metadata!');
        return EMPTY;
      })
    );
  }
}
